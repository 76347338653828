import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class DropdownSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      selected: "",
      id:''
    }
  }

  componentDidMount = () => {
    this.setState({
      selected: this.props.selected
    });
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  onClick = (event) => {
    event.preventDefault()
    let value = event.currentTarget.getAttribute("dropDownValue")
    let info = event.currentTarget.getAttribute("info")
    if(this.props.id){
      this.setState({ selected: info }, () => { this.props.onChange(value, this.props.id) })
    } else {
      this.setState({ selected: info }, () => { this.props.onChange(value) })
    }
    
  }

  render() {
    return (
      <Dropdown className={this.props.customClass} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        {this.props.gender &&
          <DropdownToggle style={{ border: this.props.borderColor}} caret>
            {this.props.selected}
          </DropdownToggle>
        }
        {this.props.gender === undefined &&
          <DropdownToggle caret>
            {this.props.selected}
          </DropdownToggle>
        }
        <DropdownMenu >
          {
            this.props.items.map(item => {
              return (
                <DropdownItem onClick={this.onClick} dropDownValue={item.value} info={item.text}>{item.text}</DropdownItem>
              )
            })
          }
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default DropdownSelect