import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
class MobileCarCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      full_name:''
    }
  }

  getShortName = (full_name) =>{
    return  full_name
  }

  redirectToDetail = () =>{
    this.setState({redirect: true})
  }

  render() {
    return (
      <div className="mobile-car-card" onClick={()=>this.redirectToDetail()}>
        <input type="hidden" value={this.props.vin} />
        {this.state.redirect &&
          <Redirect to={'/car-detail/'+this.props.name+"/"+this.props.model+"/"+this.props.version}></Redirect>
        }
        <div className="row mx-0">
          <div className="col-12 px-0 w-100">
            <div className="image-wrapper">
              <img src={this.props.image} alt=""/>
            </div>
            <p className="body-b car-name">{this.getShortName(this.props.version)}</p>
            <div className="car-info">
              <i class="fas fa-dollar-sign mr-xxs"></i>
              <p className="body-r mb-0 price">{this.props.price}</p>
              <p className="body-r mb-0">{this.props.offer}</p>
            </div>
            <div className="car-info">
              <i class="fas fa-road mr-xxs"></i>
              <p className="body-r mb-0">{this.props.kilometers}</p>
            </div>
            {/* <div className="car-info -line">
              <p className="body-r mb-0">{this.props.units} disponibles</p>
            </div>           */}
          </div>
        </div>
      </div>
    );
  }
}

export default MobileCarCard;