import React, { Component } from 'react';

class CarDetailNav extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <div className="container">
        <div className="detail-car-nav">
          <div className="listoptions d-none d-md-flex">
            <a href="#home" className="detailoption">Inicio</a>
            <a href="#caracteristics" className="detailoption">Características</a>
            <a href="#colors" className="detailoption">Colores</a>
            <a href="#galery" className="detailoption">Galería</a>
            <a href="#calculator" className="detailoption"><button className="button -primary">Cotizar</button></a>
          </div>
        </div>
      </div>
    );
  }
}

export default CarDetailNav;
