import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Auth from './auth/Auth'
import HttpsRedirect from 'react-https-redirect';


const auth = new Auth();
let state = {};
window.setState = (changes) => {

  state = Object.assign({}, state, changes);

  ReactDOM.render(
    <HttpsRedirect>

      <App {...state} />
    </HttpsRedirect>,
    document.getElementById('root')
  );

}


let username = "";
let image = "";

let initialState = {
  name: username,
  user_image: image,
  auth,
}
window.setState(initialState);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
