import React, { Component } from 'react';
import CarDetailHero from '../Components/CarDetailHero/CarDetailHero';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent'
import CarDetailNav from '../Components/CarDetailNav/CarDetailNav'
import Eng from '../../src/Image/svg/eng.svg'
import Gas from '../../src/Image/svg/gas.svg'
import Tra from '../../src/Image/svg/tra.svg'
import Cap from '../../src/Image/svg/cap.svg'
import Sec from '../../src/Image/svg/sec.svg'
import Pow from '../../src/Image/svg/pow.svg'
import CarDetailColors from '../Components/CarDetailColors/CarDetailColors';
import CarDetailVersion from '../Components/CarDetailVersion/CarDetailVersion';
import Footer from '../Components/Footer/Footer';

import Car from '../Image/car-detail/car.png'
import CarDetailGallery from '../Components/CarDetailGallery/CarDetailGallery';
import NumberFormat from 'react-number-format';

import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

class CarDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      car: {},
      version:'',
      initial_payment:'',
      cost: '',
      months: '',
      monthly_payment:0,
      hook:15,
      min_payments:3500,
      color_options:[],
      current_color:''
    }
  }

  componentDidMount = () => {
    let params = this.props.location[0].match.params

    let url = `${API_URL}/api/v1/cars/get_single?brand=${params.name}&model=${params.model}&version=${params.version}`

    axios.get(url).then(res => {
      this.setState(
        { 
          car: res.data[0],
          cost: res.data[0].versions.price,
          version: res.data[0].versions.version
      }, 
      () =>{
        sessionStorage.setItem("car",  JSON.stringify(this.state.car))
        sessionStorage.setItem("version", this.state.version)
        sessionStorage.setItem("brand", this.state.car.brand)
        sessionStorage.setItem("model", this.state.car.model)
       }
      )
      this.minPayment(res.data[0].versions.price)
      this.getColors(res.data[0])
    })
  }
  handlerCost = (event) => {
    this.setState({cost: event.target.value}, () => {if(this.state.cost!=''&&this.state.months!=''&&this.state.initial_payment!='') 
    this.handlerPayment()
    sessionStorage.setItem("cost", this.state.cost)
  })
  }
  handlerMonths = (event) => {
    this.setState({months: event.target.value},() => {if(this.state.cost!=''&&this.state.months!=''&&this.state.initial_payment!='') 
    this.handlerPayment()
    sessionStorage.setItem("months", this.state.months)
  })
  }
  handlerInitial = (event) => {
    this.setState({initial_payment: event.floatValue}, () => {if(this.state.cost!=''&&this.state.months!=''&&this.state.initial_payment!='') this.handlerPayment()
    sessionStorage.setItem("initial_payment", this.state.initial_payment)
  })
  }
  minPayment(price){
    let url = `${API_URL}/api/v1/cars/monthly_payments?cost=${price}&months=36&initial_payment=${price*0.15}`
        axios.get(url)
        .then(res => {
          this.setState({min_payments: res.data})
        },error => {
              return Promise.reject(error)
            })
  }

  _setState = (state, filters = false) => {

    this.setState(state, ()=>{})
  }

  handlerPayment () {
    let url = `${API_URL}/api/v1/cars/monthly_payments?cost=${this.state.cost}&months=${this.state.months}&initial_payment=${this.state.initial_payment}`
        axios.get(url)
        .then(res => {
          this.setState({monthly_payment: res.data})
        },error => {
              return Promise.reject(error)
            })
  }

  getColors = (current) =>{
    let color_options = []
    current.versions.colors.forEach(element => {
      color_options.push(element.label)
    })
    this.setState({color_options})
  }

  handlerColor = (event) => {
    this.setState({current_color: event.target.value}, () => {sessionStorage.setItem("color", this.state.current_color)})
  }


  render() {
    return (
      <div className="container-fluid px-0" id="home">
        <NavBarComponent {...this.props}></NavBarComponent>
        <div className="row mx-0 mt-top">
          <div className="col-12 px-0">
            <CarDetailHero
              carName={`${this.state.car.brand} ${this.state.car.model} ${this.state.car.model_year}`}
              carPrice={`${this.state.car.versions ? this.state.car.versions.price : 'No disponible'}`}
              carMonthlyPayments={(String(this.state.min_payments))}
              hook={(String(this.state.hook))+'%'}
              picture={this.state.car.versions ? (this.state.car.versions.pictures ? this.state.car.versions.pictures[0] :this.state.car.versions.colors[0].photo) : ''}
              vin={this.state.car.vin}
            />
          </div>
        </div>
        {/* <div className="row mx-0 bg-white">
          <div className="col-12 px-0">
            <CarDetailNav
            />
          </div>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="h2-b text-center mt-xl">Cotizar</p>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-12 col-md-6 col-lg-3">
              <div className="form-group mb-0 mt-m">
                <select name="" id="">
                  <option value="" selected disabled>Seleccionar</option>
                  <option value="">L $200,000</option>
                  <option value="">LX $222,000</option>
                  <option value="">EX $245,000</option>
                  <option value="">ST $280,000</option>
                </select>
                <label htmlFor="">Versión</label>
              </div>
            </div> */}
            {/* <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group mb-0 mt-m">
                <select name=""
                id="" 
                onChange={this.handlerColor}>
                {this.state.color_options.length > 0 && (
                    <option value="" selected disabled>Seleccionar</option>
                  )}
                  {!(this.state.color_options.length > 0) && (
                    <option value="" selected disabled>Seleccionar una versión</option>
                  )}
                  {this.state.color_options.map((color, i) =>{                    
                      return (<option value={color}>{color}</option>) 
                    })}
                  <option value="">Rojo</option>
                  <option value="">Azul</option>
                  <option value="">Negro</option>
                  <option value="">Blanco</option>
                </select>
                <label htmlFor="">Color</label>
              </div>
            </div> */}
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group mb-0 mt-m">
              <NumberFormat
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      placeholder="$1000.50"
                      onValueChange={this.handlerInitial} />
                {/* <input type="text" placeholder="Ej. 50,000" onChange={this.handlerInitial} /> */}
                <label htmlFor="">Enganche</label>
                <div className="required" id="calculator">Mín ${(this.state.cost * 0.15).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group mb-0 mt-m">
                <select name="months"
                    id=""
                    value={this.state.months}
                    onChange={this.handlerMonths}
                    >
                  <option value="" selected disabled>Seleccionar</option>
                  <option value="12">12 meses</option>
                  <option value="18">18 meses</option>
                  <option value="24">24 meses</option>
                  <option value="36">36 meses</option>
                </select>
                <label htmlFor="">Meses a pagar</label>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4">
              <div className="cm-card -primary mt-m">
                <p className="h3-r text-center">Mensualidades de:</p>
                <p className="h2-b mb-0 text-center">$ {this.state.monthly_payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
              </div>

              <a href="/send-form" className="detailoption"><button className="button -primary w-100 my-m">Lo quiero</button></a>
            </div>
          </div>
        </div>

        <div className="container" id="caracteristics">
          <div className="row mt-xl">
            <div className="col-12">
              <p className="h2-b text-center mb-m">Características</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="cm-card -primary d-flex flex-column align-items-center">
                <img className="w-50px" src={Eng} alt="icono de motor" />
                <p className="body-b text-center mt-xxs mb-0">Motor</p>
                <p className="body-r text-center mb-0">{`${this.state.car.versions ? this.state.car.versions.motor.liters : 'No disponible'} lts`}</p>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="cm-card -primary d-flex flex-column align-items-center">
                <img className="w-50px" src={Gas} alt="icono de motor" />
                <p className="body-b text-center mt-xxs mb-0">Rendimiento</p>
                <p className="body-r text-center mb-0">15km / litro</p>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3">
              <div className="cm-card -primary d-flex flex-column align-items-center">
                <img src={Tra} alt="icono de motor"/>
                <p className="body-b text-center mt-xxs mb-0">Transmisión</p>
                <p className="body-r text-center mb-0">M/A</p>
              </div>
            </div> */}
            <div className="col-12 col-lg-3">
              <div className="cm-card -primary d-flex flex-column align-items-center">
                <img className="w-50px" src={Cap} alt="icono de motor" />
                <p className="body-b text-center mt-xxs mb-0">Capacidad</p>
                <p className="body-r text-center mb-0"> {`${this.state.car.versions ? this.state.car.versions.passengers: 'No disponible'} pasajeros`} </p>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3">
              <div className="cm-card -primary d-flex flex-column align-items-center">
                <img src={Sec} alt="icono de motor"/>
                <p className="body-b text-center mt-xxs mb-0">Seguridad</p>
                <p className="body-r text-center mb-0">6 bolsas de aire</p>
              </div>
            </div> */}
            <div className="col-12 col-lg-3">
              <div className="cm-card -primary d-flex flex-column align-items-center">
                <img className="w-50px" src={Pow} alt="icono de motor" />
                <p className="body-b text-center mt-xxs mb-0">Potencia</p>
                <p className="body-r text-center mb-0">{`${this.state.car.versions ? this.state.car.versions.motor.power: 'No disponible'} hp`}</p>
              </div>
            </div>
          </div>

          {/* <div className="row mt-m d-flex justify-content-center">
            <div className="col-12 col-md-4">
              <button className="button -primary w-100"><i class="fas fa-download"></i> Descargar ficha técnica</button>
            </div>
          </div> */}

          {/* <div className="row mt-xxxl" id="colors" >
            <div className="col-12">
              <p className="h2-b text-center mb-m">Colores</p>
            </div>
          </div> */}
        </div>

        <div className="container mt-xl" id="galery">
          <div className="row">
            <div className="col-12">
              <p className="h2-b text-center mb-m">Galería</p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
              <CarDetailGallery
              pictures={this.state.car.versions ? this.state.car.versions.pictures : ''}
              vin={this.state.car.vin}
              />
            </div>
          </div>
        </div>

        {/* <CarDetailColors
          colors={this.state.car.versions ? this.state.car.versions.colors : []}
          setState={this._setState}
        /> */}

        {/* <div className="bg-white">
          <div className="container">
            <div className="row mt-xl">
              <div className="col-12">
                <p className="h2-b text-center mt-xl mb-m">Versiones</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3">
                <CarDetailVersion
                  className="mb-s"
                  version="L"
                  price="$200,000"
                  image={Car}
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <CarDetailVersion
                  className="mb-s"
                  version="LX"
                  price="$222,000"
                  image={Car}
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <CarDetailVersion
                  className="mb-s"
                  version="EX"
                  price="$245,000"
                  image={Car}
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <CarDetailVersion
                  className="mb-s"
                  version="ST"
                  price="$280,000"
                  image={Car}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="row mx-0 mt-xl">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default CarDetail;