import React, { Component } from 'react';
import Car from '../../Image/car-detail/car.png'

class CarDetailColors extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      selected:0
     }
  }

  selectColor = (color) => {
    this.props.colors[this.state.selected].selected = ''
    this.props.colors[color].selected = '-selected'
    this.state.selected = color
    this.setState(this.props.colors)
  }
  
  componentDidUpdate = () =>{
    if(this.props.colors){
      let select = false
      this.props.colors.forEach(element => {
        if(!element.selected){
          element.selected=''
        } else {
          select = true
        }
      });
      if(!select){
        this.props.colors[0].selected = '-selected'
        this.state.selected = 0
      }
    }
    // this.setState(this.props)
  }

  render() {
    return (
      <div className="car-detail-colors">
        <div className="carexample">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 h-100 d-flex align-items-center justify-content-center">
                {this.props.colors.length > 0 && (
                  
                  <img className="colorcarexample" src={this.props.colors[this.state.selected].photo} alt=""/>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="choosecolor">
                <p className="h3-b text-center">Selecciona un color</p>
                {this.props.colors.length > 0 && (
                  <p className="body-r text-center">{this.props.colors[this.state.selected].label}</p>
                )}
                <div className="colors">
                  {this.props.colors.map((res, i) =>{                    
                    return (<button className={`coloroption -${res.color}`} onClick={() => this.selectColor(i)}>
                      <i class={`fas fa-check c-red icon ${res.selected}`} 
                    ></i></button>) 
                  })
                  }

                  {/* <button className="coloroption -red"><i class="fas fa-check c-white icon -selected"></i></button> */}
                  
                  {/* <button className="coloroption -gray"><i class="fas fa-check c-white icon"></i></button>
                  <button className="coloroption -black"><i class="fas fa-check c-white icon"></i></button>
                  <button className="coloroption -white"><i class="fas fa-check icon"></i></button>
                  <button className="coloroption -orange"><i class="fas fa-check c-white icon"></i></button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CarDetailColors;