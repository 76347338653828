import React, { Component } from 'react';
import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import Footer from '../../Components/Footer/Footer';
import AddPayMethod from '../../Components/SellerComponents/AddPayMethod/AddPayMethod';
import PayMethod from '../../Components/SellerComponents/PayMethod/PayMethod';
import jwtDecode from 'jwt-decode';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL
class SellerPayMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  componentDidMount = () =>{
    let url = `${API_URL}/api/v1/users/get_cards?group_id=${localStorage.getItem("group_id")}`

    axios.get(url)
      .then(res => {
        this.setState({
          cards: res.data
        })
        // this.paginate(res.data)
      }, error => {
        return Promise.reject(error)
      })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    return (
      <div className="seller-profile">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="container-fluid mt-top min-height d-flex px-0">
          {this.state.redirect &&
            <Redirect to={'/seller-admin'}></Redirect>
          }
            <SideNav></SideNav>

            <div className="container">
              <div className="row">
                <div className="col-12">
                    <p className="h1-b mt-xl">Métodos de pago</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <p className="h2-r mt-l mb-s">Método de pago favorito</p>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                          <select name="" id="">
                            <option value="" default disabled>Seleccionar</option>
                            {this.state.cards && 
                              this.state.cards.map((card =>{
                                return(
                                  <option value={card}>{card.bank_name +' '+ card.card_number} </option>
                                )
                              }))
                            }
                            {/* <option value="">Tarjeta A</option>
                            <option value="">Tarjeta B</option>
                            <option value="">Tarjeta C</option> */}
                          </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <p className="h2-r mt-l mb-s">Mis métodos de pago</p>
                  <div className="row mb-l">
                      {this.state.cards &&
                        this.state.cards.map((card =>{
                          return(
                            <PayMethod
                              cardColor='-first'
                              cardName={card.bank_name+' '+card.card_number}
                            />
                            )
                        }))
                      }
                  </div>
                  <AddPayMethod
                    statePayMethod= 'adding'
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row mx-0">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default SellerPayMethods;