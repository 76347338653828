import React, { Component } from 'react';

import NavBarComponent from '../Components/NavBarComponent/NavBarComponent';
import Footer from '../Components/Footer/Footer';

class Privacy extends Component {
    render() {
        return (
            <div className="privacy-advert">
                <div className="container-fluid px-0">
                    <div className="row mx-0">
                        <NavBarComponent {...this.props}></NavBarComponent>
                    </div>

                    <div className="container mt-top py-l">
                        <p className="text-center">
                            <b>
                                AVISO DE PRIVACIDAD <br />
                                “CONTINENTAL SEMINUEVOS, S.A. DE C.V.”
                            </b>
                        </p>
                        <p className="text-center">
                            <b>
                                Domicilio: Av. Lic. Benito Juárez García, No. 1556, interior 1, Col. San Francisco Coaxusco, Metepec, Estado de México, C.P.  52158.
                            </b>
                        </p>

                        <p>
                            <br />
                            En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares 
                            (en lo sucesivo la "Ley”) y el Reglamento de la Ley Federal de Protección de Datos Personales en 
                            Posesión de los Particulares (en lo sucesivo el “Reglamento”), le informamos los términos y condiciones 
                            del Aviso de Privacidad de <b>“CONTINENTAL SEMINUEVOS, S.A. DE C.V.”</b>, sus subsidiarias, filiales, controladas, 
                            controladoras y unidades de negocio (en lo sucesivo "CONTINENTAL SEMINUEVOS") con domicilio en: Av. Lic. 
                            Benito Juárez García, No. 1556, interior 1, Col. San Francisco Coaxusco, Metepec, Estado de México, C.P.  
                            52158, Teléfono: 01 (722) 279 80 00. 
                        </p>
                        <p>
                            En CONTINENTAL SEMINUEVOS creemos que es importante proteger la privacidad de nuestros Visitantes, Invitados, 
                            Prospectos de Clientes, Clientes, Solicitantes de Empleo, Candidatos de Recursos Humanos y Empleados para ser 
                            transparentes con nuestras prácticas. CONTINENTAL SEMINUEVOS sabe que usted se preocupa por su información personal y 
                            desea conocer cómo se obtiene, usa y comparte y por ello, queremos ayudarlo a hacer elecciones informadas respecto de 
                            la información que usted nos proporciona. Este Aviso de Privacidad explica y resume la forma en que CONTINENTAL 
                            SEMINUEVOS obtiene, usa, protege y transfiere su información, incluyendo datos que nos proporciona o que podríamos 
                            obtener de usted cuando interactúa con nosotros. Cabe señalar que podremos estar actualizando o modificando este Aviso 
                            de Privacidad de tiempo en tiempo, así que le pedimos de favor estarlo revisando periódicamente. 
                        </p>
                        <p>
                            Este Aviso de Privacidad aplica cuando CONTINENTAL SEMINUEVOS obtiene información de nuestros Visitantes, Invitados, 
                            Prospectos de Clientes, Clientes, Solicitantes de Empleo, Candidatos de Recursos Humanos y Empleados; incluyendo 
                            la información obtenida a través de las Páginas WEB, mensajes directos (no públicos) y las aplicaciones controladas 
                            por CONTINENTAL SEMINUEVOS que pueden ser accedidas y/o usadas en cualquier tipo de dispositivo. La referencia a 
                            "Datos Personales", “Datos Financieros o Patrimoniales” y “Datos Personales Sensibles” incluye información que aisladamente 
                            o en combinación con otra información, puede ser usada para identificarlo, contactarlo, localizarlo, reclutarlo laboralmente, 
                            auxiliarlo en casos de emergencia y/o para otras finalidades (por ejemplo, su nombre, su dirección, su número telefónico 
                            (celular y fijo), correo electrónico, fecha de nacimiento, Registro Federal de Contribuyentes, clase y tipo de licencia 
                            para conducir, estado de salud y nacionalidad). Al proporcionarnos sus Datos Personales o al usar nuestra Página WEB, 
                            usted consiente en que CONTINENTAL SEMINUEVOS almacene y use dicha información de acuerdo a la Ley, su Reglamento y a 
                            este Aviso de Privacidad. 
                        </p>
                        <p>
                            Éste Aviso de Privacidad tiene como fin primordial dar cumplimiento a lo establecido por la Ley y su Reglamento, así como 
                            el uso que CONTINENTAL SEMINUEVOS da a los datos que usted nos proporciona o cuando los conocemos por virtud de nuestra 
                            relación. En su caso, qué datos son transferidos por CONTINENTAL SEMINUEVOS y las condiciones que usamos para proteger esos 
                            datos en caso de realizar dichas transferencias. El proceso a través del cual usted podrá acceder a sus datos que comparte 
                            con CONTINENTAL SEMINUEVOS y corregirla, restringirla o removerla de nuestros registros. 
                        </p>

                        <p>
                            <b><u>
                                <br />
                                FINALIDADES DEL TRATAMIENTO DE LOS DATOS 
                            </u></b>
                        </p>
                        <p>
                            CONTINENTAL SEMINUEVOS podrá usar su información para fines de investigación interna mientras desarrollamos nuestros 
                            productos y servicios, así como para fines mercadológicos, publicitarios, de prospección comercial, de identificación, 
                            promoción, venta, controles estadísticos, controles administrativos, información a clientes y consumidores, 
                            reclutamiento y selección de personal.
                        </p>
                        <p>
                            Asimismo, CONTINENTAL SEMINUEVOS usará su información para brindarle una mejor atención a usted y a los vehículos que 
                            posea de nuestras marcas, seguimiento a los casos, comentarios, consultas, quejas y reclamaciones reportados en el 
                            centro de atención a clientes, citas de prueba de manejo, carta de bienvenida. 
                        </p>
                        <p>
                            Si usted hace un requerimiento de información, por ejemplo, nosotros podríamos necesitar enviarle su nombre, dirección 
                            o correo electrónico a nuestros distribuidores o proveedores de servicios con quienes nosotros tenemos una relación 
                            comercial, para que ellos puedan ayudarlo y responderle. Los vendedores y proveedores de CONTINENTAL SEMINUEVOS 
                            podrían procesar su información personal bajo la dirección de CONTINENTAL SEMINUEVOS y de acuerdo con nuestras 
                            políticas, por tal motivo CONTINENTAL SEMINUEVOS se asegurará a través de la firma de convenios y/o la adopción de 
                            otros documentos vinculantes, que dichos terceros mantengan medidas de seguridad, administrativas, técnicas y físicas 
                            adecuadas para resguardar sus datos personales, así como que dichos terceros únicamente utilicen sus datos personales 
                            para las finalidades para los cuales fueron recabados y de conformidad con el presente Aviso de Privacidad. 
                        </p>
                        <p>
                            CONTINENTAL SEMINUEVOS utiliza sus datos personales únicamente para los fines señalados con anterioridad y 
                            específicamente los siguientes: 
                        </p>
                        <ul>
                            <li>
                                <p><b>
                                    PARA: EL VISITANTE, INVITADO Y PROSPECTO DE CLIENTE 
                                </b></p>
                                <p>
                                    CONTINENTAL SEMINUEVOS solicita sus datos personales con la finalidad de identificar y conservar un registro 
                                    de sus Visitantes, Solicitantes, Invitados y Prospectos de Clientes para poder ofrecerles y/o venderles los 
                                    diferentes productos y/o servicios que se ofrecen a través de CONTINENTAL SEMINUEVOS; para enviarles las diferentes 
                                    ofertas, promociones, campañas publicitarias, informativas y de mercadotecnia; y/o cualquier finalidad análoga o 
                                    compatible con las anteriores, citas de prueba de manejo, seguimiento de comentarios, consultas, quejas y reclamaciones, 
                                    controles estadísticos, controles administrativos.
                                </p>
                            </li>

                            <li>
                                <p><b>
                                    PARA: EL CLIENTE 
                                </b></p>
                                <p>
                                    CONTINENTAL SEMINUEVOS solicita sus datos personales con la finalidad de identificar y conservar un registro de sus 
                                    Clientes, para brindarles un mejor servicio al atenderlos, conocer sus necesidades, para controles estadísticos, para 
                                    controles administrativos, para publicidad, para prospección comercial y/o estar en posibilidad de ofrecerles y venderles 
                                    vehículos terrestres usados; ser contactado para encuestas vía telefónica y enviar información a fin de conocer el nivel de 
                                    atisfacción del Cliente y seguimiento de comentarios, consultas, quejas y reclamaciones, en relación con la adquisición 
                                    de bienes y/o servicios que le hubiesen sido ofrecidos y/o proporcionados por CONTINENTAL SEMINUEVOS; así como para la 
                                    celebración del contrato correspondiente y la carta de bienvenida, dar seguimiento a sus peticiones, sugerencias, 
                                    reclamaciones y/o cualquier otra solicitud, para realizar acciones de actualizaciones técnicas, enviarle información para 
                                    darle a conocer promociones respecto a productos y/o servicios que se ofrecen a través de CONTINENTAL SEMINUEVOS, 
                                    incluyendo sin limitar, los servicios financieros, de arrendamiento, venta de seguros y demás que se ofrezcan en la empresa, 
                                    ya sea por ésta o un tercero, pudiendo realizar cualquier acción comercial, de mercadotecnia, estadística y de prospección 
                                    tendiente a dichas finalidades, para agendar citas de prueba de manejo; ser contactado y enviar información para dar 
                                    cumplimiento a requerimientos legales, realizar investigaciones de su comportamiento Financiero (Buro de Crédito); 
                                    requerimientos de pago; ceder o vender la cartera de créditos; y/o cualquier finalidad análoga o compatible con las 
                                    anteriores.
                                </p>
                            </li>

                            <li>
                                <p><b>
                                    PARA: EL SOLICITANTES DE EMPLEO Y CANDIDATOS DE RECURSOS HUMANOS 
                                </b></p>
                                <p>
                                    CONTINENTAL SEMINUEVOS solicita sus datos personales con la finalidad de identificar y conservar un registro de sus 
                                    Solicitantes de Empleo y Candidatos de Recursos Humanos, para tomar decisiones sobre reclutamiento y selección, verificar 
                                    capacidades y aptitudes para el desempeño del trabajo solicitado, realizar evaluaciones para la selección de la 
                                    contratación, elaborar en su caso ofertas de trabajo al candidato; realizar investigaciones laborales y verificar sus 
                                    referencias personales y laborales; conformar expedientes de candidatos a empleados y conservar para futuras selecciones 
                                    y/o contrataciones en nuestra cartera de Bolsa de Trabajo, mantener comunicación con el candidato durante el proceso de 
                                    reclutamiento y selección. CONTINENTAL SEMINUEVOS podrá hacer uso de sus datos personales para otras finalidades, siempre 
                                    y cuando dichas finalidades sean compatibles y puedan considerarse análogas a las anteriores.
                                </p>
                            </li>

                            <li>
                                <p><b>
                                    PARA: EL EMPLEADO 
                                </b></p>
                                <p>
                                    CONTINENTAL SEMINUEVOS solicita sus datos personales, datos financieros o patrimoniales y datos personales sensibles con 
                                    la finalidad del reclutamiento laboral, contratación e inducción, así como para identificar y conservar un registro de sus 
                                    Empleados; cumplir con las obligaciones derivadas de la relación laboral que con usted se cree; el cumplimiento de 
                                    obligaciones fiscales, así como para la administración de personal; análisis del perfil psicológico, sicométrico y 
                                    socioeconómico para fines de selección y contratación; formar expedientes de empleados; información y/o documentos que 
                                    reflejen su situación actual de salud para que en caso de emergencia podamos auxiliarlo; solicitar de forma periódica su 
                                    Buró de Crédito; pago y administración de nómina; pago de beneficios, salarios y prestaciones, bonos, reembolsos, pensiones, 
                                    seguros y otros; documentar y controlar la asignación de activos de cómputo y comunicaciones; auditorías internas; creación 
                                    de cuenta de correo electrónico laboral; elaboración de directorio de la empresa; asignar herramientas de trabajo; asignar 
                                    claves y contraseñas, asegurar el cumplimiento de obligaciones de confidencialidad y otras obligaciones laborales; verificar 
                                    referencias personales y laborales; contactar a sus familiares, dependientes económicos y/o beneficiarios en caso de 
                                    emergencia. CONTINENTAL SEMINUEVOS podrá hacer uso de sus datos personales para otras finalidades, siempre y cuando dichas 
                                    finalidades sean compatibles y puedan considerarse análogas a las anteriores. 
                                </p>
                            </li>
                        </ul>

                        <p>
                            <b><u>
                                <br />
                                LIMITANTES DEL USO O DIVULGACIÓN DE LOS DATOS 
                            </u></b>
                        </p>
                        <p>
                            CONTINENTAL SEMINUEVOS obtiene información acerca de usted de forma personal o a través de otros medios escritos o por 
                            cualquier otra tecnología legalmente permitida. Por ejemplo, nosotros podremos obtener información mediante solicitudes, 
                            currículos, entrevistas, estudios socioeconómicos, evaluaciones médicas y sicométricas, servicios en línea, publicidad, 
                            compras por correo electrónico o listas similares de terceras personas para fines mercadotécnicos, publicitarios, de 
                            reclutamiento o de prospección, por lo que CONTINENTAL SEMINUEVOS podría comunicarse con usted para proporcionarle 
                            información que consideramos que puede ser de su interés.
                        </p>
                        <p>
                            Nosotros podríamos usar y transferir sus Datos Personales cuando nos resulte necesario para completar una transacción 
                            con usted o para realizar algo que nos haya solicitado hacer, como obtener una cotización, enviar productos o información 
                            de vehículos. Asimismo, CONTINENTAL SEMINUEVOS podrá transferir sus Datos Personales con y entre sus empresas subsidiarias, 
                            filiales, controladas, controladoras y unidades de negocio para los fines mencionados en esta sección. 
                        </p>
                        <p>
                            Los datos obtenidos se tratan a través de medios escritos, técnicos, administrativos, electrónicos y digitales adecuados 
                            para proteger su información y la confidencialidad de los mismos. Mantenemos los datos de nuestros Visitantes, Invitados, 
                            Prospectos de Clientes, Clientes, Solicitantes de Empleo, Candidatos de Recursos Humanos y Empleados en forma confidencial 
                            y por el tiempo razonablemente necesario, tomando en cuenta las disposiciones legales aplicables y sólo la compartimos y/o 
                            transferimos con terceros directa o indirectamente, dentro y fuera del país, con los distribuidores autorizados, compañías 
                            matrices, afiliadas o subsidiarias de CONTINENTAL SEMINUEVOS, terceros no vinculados, terceros proveedores de servicio; 
                            terceros no vinculantes, nuestros socios de negocios, y/o para la cesión y/o venta de cartera; Autoridades de control 
                            vehicular estatal, federal y municipal; así como en su caso autoridades competentes y por disposición legal; asesores 
                            profesionales externos; y/u otros prestadores que ofrezcan servicios, por ejemplo, de administración de nómina y/o 
                            personal, capacitación y entrenamiento, de investigaciones laborales; administración de recursos humanos, tecnologías 
                            de la información; soporte técnico, Instituciones de Crédito para el pago de la Nómina, así como con otra(s) empresa(s) 
                            que realizan el servicio y/o monitoreo de Call Center para contactarle y/o enviarle la información requerida por usted, 
                            así como para enviarle las diferentes ofertas, promociones y publicidad de CONTINENTAL SEMINUEVOS, y evaluar la calidad 
                            del Servicio que le brindamos; y en general cualquier tercero que actúe como encargado a nombre y por cuenta de CONTINENTAL 
                            SEMINUEVOS. No obstante, lo anterior, CONTINENTAL SEMINUEVOS no cederá, venderá o transferirá sus datos personales a 
                            terceros no relacionados con CONTINENTAL SEMINUEVOS, salvo en los casos antes citados y los previstos en la Ley, sin su 
                            consentimiento previo. 
                        </p>
                        <p>
                            Nuestros Visitantes, Invitados, Prospectos de Clientes, Clientes, Solicitantes de Empleo, Candidatos de Recursos Humanos 
                            y Empleados tienen la confianza de que sus datos no serán usados para fines distintos a los establecidos en este Aviso de 
                            Privacidad y conforme a lo establecido en el contrato correspondiente que se celebre con usted, según corresponda. 
                            CONTINENTAL SEMINUEVOS se asegurará a través de la firma de convenios y/o la adopción de otros documentos vinculantes, 
                            que dichos terceros mantengan medidas de seguridad, administrativas, técnicas y físicas adecuadas para resguardar sus 
                            datos personales, así como que dichos terceros únicamente utilicen sus datos personales para las finalidades para los 
                            cuales fueron recabados y de conformidad con el presente Aviso de Privacidad. Asimismo, CONTINENTAL SEMINUEVOS podrá 
                            transferir sus datos personales a Continental Automotriz, S.A. de C.V. Autopolanco, S.A. de C.V., Automotriz Nihon, S.A. 
                            de C.V., Automodena, S.A. de C.V., Korean Motors, S.A. de C.V., Continental Coapa, S.A. de C.V., CA Soluciones Financieras, 
                            S.A. de C.V. o cualquiera de sus empresas filiales, controladas y controladoras, con la finalidad de realizar análisis 
                            diversos, envío de productos o información de vehículos, refacciones y mantenimiento, así como también a cualquiera de sus 
                            distintos proveedores, con la finalidad de realizar encuestas de satisfacción, estudios de mercado, administración de bases 
                            de datos de correos electrónicos, entre otros fines similares. Para cumplir con lo dispuesto en esta sección, CONTINENTAL 
                            SEMINUEVOS podrá contratar a uno o varios terceros como proveedores de servicios para administrar los Datos Personales que 
                            se recaban a través de cualquier medio por CONTINENTAL SEMINUEVOS, por lo que podrá incluso transferir esa información a 
                            dicho (s) tercero (s) sin fines comerciales y únicamente para cumplimiento de la prestación de los servicios contratados.
                        </p>
                        <p>
                            Si CONTINENTAL SEMINUEVOS alguna vez entrara en concurso mercantil, se liquidará o se fusionara con otra compañía, o si 
                            CONTINENTAL SEMINUEVOS decidiere comprar, vender o reorganizar una parte o todo su negocio, CONTINENTAL SEMINUEVOS pudiera 
                            requerir revelar sus Datos Personales, incluyendo compradores prospecto o actuales en relación con alguna de estas 
                            transacciones. 
                        </p>

                        <p>
                            <b><u>
                                <br />
                                OPCIONES Y MEDIOS QUE EL RESPONSABLE OFRECE A LOS TITULARES DE LOS DATOS PERSONALES PARA LIMITAR EL USO O DIVULGACIÓN 
                                DE LOS DATOS PERSONALES
                            </u></b>
                        </p>
                        <p>
                            En caso que usted desee oponerse al tratamiento de sus datos personales para recibir publicidad y ofertas o desea limitar 
                            el uso o divulgación de sus datos personales, usted deberá contactar a nuestra Área Jurídica al teléfono 722 2798000, Ext: 
                            3404,2322 o 3401, de lunes a viernes (días hábiles) de 8:30 a 14:30 y de 16:00 a 18:30 horas, o a través del correo 
                            electrónico avisodeprivacidad@continental.com.mx. Usted deberá presentar la solicitud respectiva que deberá indicar: a) 
                            su nombre completo y el domicilio o correo electrónico en el que se desea recibir toda la información relacionada con el 
                            trámite; b) deberá de estar acompañada del o los documentos oficiales con los que se acredite su identidad o la 
                            personalidad de su representante legal; c) deberá incluir una descripción clara y precisa de los datos personales respecto 
                            de los que se opone a que sean tratados en los términos del presente Aviso de Privacidad.
                        </p>

                        <p>
                            <b><u>
                                <br />
                                MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN 
                            </u></b>
                        </p>
                        <p>
                            Usted tiene derecho a revocar el consentimiento otorgado para el tratamiento de sus datos personales y a: a) acceder a 
                            sus datos personales en nuestro poder y a los detalles de su tratamiento; b) rectificarlos en caso de ser inexactos o 
                            incompletos; c) cancelarlos cuando considere que no se requieren para alguna de las finalidades señaladas en el presente 
                            Aviso de Privacidad o estén siendo utilizados para finalidades no consentidas; y/u d) oponerse al tratamiento de los 
                            mismos para fines específicos (en conjunto “Derechos ARCO”).    
                        </p>
                        <p>
                            Para revocar su consentimiento y el ejercicio de los mencionados Derechos ARCO, usted o su representante legal deberán 
                            presentar la solicitud respectiva ante nuestra Área Jurídica, área que se encarga de dar seguimiento a las solicitudes 
                            de los clientes de los Derechos ARCO de CONTINENTAL SEMINUEVOS.  Usted puede contactar a nuestra Área Jurídica al teléfono 
                            722 279 800, Ext: 3404,2322 o 3401, de lunes a viernes (días hábiles) de 8:30 a 14:30 y de 16:00 a 18:30 horas, o a 
                            través del correo electrónico avisodeprivacidad@continental.com.mx                                                                                           La solicitud que presente para el ejercicio de los Derechos ARCO deberá indicar: (1) su nombre completo y el domicilio o correo electrónico en el que se desea recibir toda la información relacionada con el trámite; (2) estar acompañada de su identificación oficial y el o los documentos oficiales con los que se acredite su identidad o la personalidad de su representante legal; (3) incluir una descripción clara y precisa de los datos respecto de los que se busca ejercer alguno de los Derechos ARCO; y (4) proporcionar cualquier otro elemento que nos permita identificar o localizar los datos personales en cuestión. Cuando se trate de “rectificación” de sus datos personales, usted deberá indicarnos también cuál es la modificación exacta que busca realizar y presentar la documentación que soporte la petición.
                        </p>
                        <p>
                            Le pedimos que considere que, por su protección y beneficio, es posible que le solicitemos documentación adicional que 
                            nos permita identificar con plenitud los datos que desee acceder, rectificar y/o cancelar, o aquellos respecto de los 
                            cuales usted desee oponerse a su tratamiento.  Asimismo, le informamos que es posible que CONTINENTAL SEMINUEVOS no pueda 
                            cancelar o bloquear sus datos personales de conformidad con lo dispuesto por la Ley y su Reglamento.
                        </p>
                        <p>
                            Una vez recibida su solicitud completa y debidamente integrada, nuestra Área Jurídica le comunicará la determinación 
                            adoptada en un plazo máximo de 10 (diez) días hábiles. Si esta resultare procedente, dentro de los 5 (cinco) días 
                            siguientes podrá hacer efectivo el derecho solicitado. En caso de que CONTINENTAL SEMINUEVOS deba hacer entrega de 
                            documentación como resultado del derecho ejercido, lo hará a través de copias que le serán enviadas por correo electrónico 
                            a la dirección que para ello haya señalado en su solicitud dentro del plazo antes mencionado.                 
                        </p>
                        <p>
                            En términos del artículo 34 de la Ley, CONTINENTAL SEMINUEVOS podrá negar el acceso a los datos personales o a realizar 
                            la rectificación, cancelación o conceder la oposición al tratamiento de los mismos, cuando se trate de alguno de los 
                            supuestos ahí establecidos.
                        </p>
                        <p>
                            Si bien el ejercicio de los Derechos ARCO y la entrega de información es gratuita, CONTINENTAL SEMINUEVOS se reserva 
                            el derecho a cobrar los gastos de envío y costos de fotocopiado de así estimarlo necesario y/o cuando resulte 
                            conveniente para las partes.
                        </p>

                        <p>
                            <b><u>
                                <br />
                                SEGURIDAD
                            </u></b>
                        </p>
                        <p>
                            CONTINENTAL SEMINUEVOS tiene implementadas medidas de seguridad administrativas, físicas, electrónicas y técnicas 
                            para proteger sus Datos Personales, Datos Financieros o Patrimoniales y Datos Personales Sensibles, contra daño, 
                            pérdida, alteración, destrucción o el uso, accesos o tratamiento no autorizado, así como para mantenerlos correctos 
                            y actualizados para los fines que fueron recabados y que son cuando menos iguales a las utilizadas para asegurar y 
                            resguardar su propia información. Una vez cumplida la finalidad para la cual fueron recabados los Datos Personales, 
                            Datos Financieros o Patrimoniales y/o Datos Personales Sensibles, los mismos serán bloqueados, con el único propósito 
                            de determinar posibles responsabilidades en relación con su tratamiento, hasta el plazo de prescripción legal o 
                            contractual de éstas. Durante dicho periodo, los Datos Personales no podrán ser objeto de tratamiento y transcurrido 
                            éste, se procederá a su cancelación en la base de datos que corresponde.
                        </p>
                        <p>
                            Si CONTINENTAL SEMINUEVOS supiera que hay una violación de seguridad a los sistemas, nosotros le notificaremos dicha 
                            situación electrónicamente para que usted pueda y tomar las acciones pertinentes. Al proveernos su Datos Personales o 
                            usar nuestra Página WEB, usted está de acuerdo en que lo podremos contactar electrónicamente en relación con seguridad, 
                            privacidad y asuntos administrativos. CONTINENTAL SEMINUEVOS podría postear una noticia en nuestra Página WEB o a 
                            través de una Aplicación si sucede una violación a la seguridad. CONTINENTAL SEMINUEVOS también podría enviarle un 
                            correo electrónico, a la dirección electrónica que usted nos haya provisto en estas circunstancias. Para recibir 
                            notificación por escrito de forma gratuita respecto de violaciones a la seguridad (o para cancelar su consentimiento 
                            de recibir esta noticia electrónica), por favor notifíquenoslo a través de un correo electrónico a 
                            avisodeprivacidad@continental.com.mx.
                        </p>

                        <p>
                            <b><u>
                                <br />
                                PÁGINA WEB DE TERCERAS PERSONAS O SITIOS PATROCINADOS.
                            </u></b>
                        </p>
                        <p>
                            Nuestra Página WEB puede contener links de terceras personas o patrocinadores, por favor tome en cuenta que CONTINENTAL 
                            SEMINUEVOS no es responsable y no puede controlar las prácticas de privacidad de otras Páginas WEB de terceras personas. 
                            Nosotros lo alentamos para que lea esos avisos de privacidad periódicamente y que revise las políticas de privacidad 
                            de otras Páginas WEB ya que pudieran diferir del nuestro.
                        </p>
                        <p>
                            Por otro lado, las Páginas WEB de terceros pueden contener links que pudieran contener el logo de CONTINENTAL SEMINUEVOS 
                            o sus marcas pero que son mantenidas por terceras personas, dichas Páginas WEB también pudieran ser identificadas por 
                            el hecho de que no contienen una URL CONTINENTAL SEMINUEVOS o marca CONTINENTAL SEMINUEVOS, por favor lea esa política 
                            de privacidad en la Página WEB del tercero, ya que pudieran diferir de este Aviso de Privacidad. 
                        </p>
                        <p>
                            Éste Aviso de Privacidad aplica solamente a los Datos Personales obtenidos por CONTINENTAL SEMINUEVOS en su Página 
                            WEB oficial, la cual es www.continentalseminuevos.com.mx en donde éste Aviso de Privacidad es posteado, así como a 
                            través de los medios mencionados en este Aviso de Privacidad.
                        </p>

                        <p>
                            <b><u>
                                <br />
                                CAMBIOS AL AVISO DE PRIVACIDAD POR PARTE DE CONTINENTAL SEMINUEVOS
                            </u></b>
                        </p>
                        <p>
                            Cualquier cambio y/o modificación total o parcial que de tiempo en tiempo se realice al presente Aviso de Privacidad 
                            se dará a conocer a nuestros Visitantes, Invitados, Prospectos de Clientes, Clientes, Solicitantes de Empleo, 
                            Candidatos de Recursos Humanos y Empleados directamente en las Concesionarias o Sucursales de CONTINENTAL SEMINUEVOS 
                            o a través de nuestra Página WEB: www.continentalseminuevos.com.mx, por lo cual le pedimos de favor estarlo revisando 
                            periódicamente. 
                        </p>

                        <p>
                            <b><u>
                                <br />
                                CONTACTA A CONTINENTAL SEMINUEVOS 
                            </u></b>
                        </p>
                        <p>
                            Si usted tiene cualquier pregunta, comentario o preocupación respecto a este Aviso de Privacidad, por favor acuda al 
                            Área de Recepción de CONTINENTAL SEMINUEVOS en la Concesionaria o Sucursal que le corresponda o envíenos un correo 
                            electrónico a avisodeprivacidad@continental.com.mx
                        </p>

                        <p>
                            <br />
                            Fecha de actualización: 09.12.2020
                        </p>
                    </div>

                    <Footer></Footer>
                </div>
            </div>
        );
    }
}

export default Privacy;