import React, { Component } from 'react';
import axios from 'axios'
import InputForm from '../InputForm/InputForm';
import { Collapse, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { ButtonPrimary } from '../../Components/Button/Button'
import { copyFileSync } from 'fs';
import jwtDecode from 'jwt-decode'
import { throwStatement } from '@babel/types';
import DropdownSelect from '../../Components/DropdownSelect/DropdownSelect'
import MultiSelect from '../MultiSelect/MultiSelect'


const API_URL = process.env.REACT_APP_API_URL
const DOCUMENTS_URL = process.env.REACT_APP_PERSON_DOC_URL;

// Upload extra documents or proof of address

class UploadCard extends Component {

  constructor(props) {
    super(props)

    this.inputRef = React.createRef();

    this.state = {
      openCollapse: false,
      address: '',
      spinner: 'd-none',
      doc_up: false,
      img_name: '',
      image_open: false,
      modal_api: false,
      modal_expired: false,
      modal_override: false, //Modal to aprove an invalid document manually
      error_msg: '',
      pdf: false,
      document: '',
      modal_up: false,
      url: '',
      personExcel: '',
      user_id: '',
      warning: false,
      rfc: '',
      document_rfc: {},
      filename: '',
      file: ''
    }
  }

  toggleCollapseID = () => {
    this.setState({
      openCollapse: !this.state.openCollapse
    })
  }

  uploadFileCollapse = (event) => {
    let file = event.target.files[0]

    if (file === undefined) {
      return
    }

    this.reader = new FileReader();
    this.reader.readAsDataURL(file);
    this.reader.onloadend = () => {

      this.setState({
        file: file,
        pdf: (file.type === 'application/pdf')
      })

      var document = new FormData();
      let url = API_URL + '/api/v1/save_files/search'
      let user_id = jwtDecode(localStorage.getItem("id_token")).sub;

      if (this.props.cardTitle === 'INFO') {
        document.append('info', file)
        document.append('pdf_info', true)
      }
      else if (this.props.cardTitle === 'IMSS') {
        document.append('imss', file)
        document.append('pdf_imss', true)
      }
      else if (this.props.cardTitle === 'SAT') {
        url = API_URL + '/api/v1/read_opc_sat/search'
        document.append('sat', file)
        document.append('pdf_sat', true)
      }
      else if (this.props.cardTitle === 'Comunicación') {
        document.append('com', file)
        document.append('pdf_com', true)
      }
      else if (this.props.cardTitle === 'Notificaciones') {
        document.append('notification', file)
        document.append('pdf_notification', true)
      }
      else if (this.props.cardTitle === 'Declaración anual') {
        document.append('anual', file)
        document.append('pdf_anual', true)
      }
      else if (this.props.cardTitle === 'Comprobante de pago') {
        document.append('voucher', file)
        document.append('pdf_voucher', true)
      }
      else if (this.props.tag === 'Comprobante') {
        url = API_URL + '/api/v1/get_address/search'
        document.append('image', file)
        document.append('pdf', false)
      }
      else if (this.props.tag === 'RFC') {
        url = API_URL + '/api/v1/read_rfc_physical/search'
        document.append('image', file)
      }
      else if (this.props.tag === 'personZip') {
        document = {}
        let reader = new FileReader();

        reader.onloadend = function () {
          var b64 = reader.result.replace(/^data:.+;base64,/, '');
          document.file = b64;
          document.user_id = jwtDecode(localStorage.getItem("id_token")).sub;
        };

        reader.readAsDataURL(file);
      }
      else if (this.props.tag === 'personExcel') {

        this.setState({
          invalidPerson: 0,
          validPerson: 0
        })

        url = API_URL + '/api/v1/excel/person'
        document.append('file', file)
        document.append('user_id', user_id)
        let categories = []

        for (let i = 0; i < this.props.categories.length; i++) {
          categories.push(this.props.categories[i]['value'])
        }
        document.append('categories', categories)
      }

      this.setState({
        document,
        url
      })

      if (this.props.address === '' || this.props.address === undefined) {
        this.sendFile()
      }
      else {
        this.setState({
          modal_up: true
        })
      }
    }
  }

  uploadFileManual = () => {
    var body = new FormData();
    body.append('file', this.state.file);
    body.append('filename', this.state.filename);
    body.append('container', 'fisicas');
    this.toggle('modal_override');

    // axios({
    //   method: 'POST',
    //   url: API_URL + '/api/v1/upload_document',
    //   data: body,
    //   config: { headers: { 'Content-Type': 'multipart/form-data' } }
    // })
    //   .then((res) => {
    //     if (res.data !== 'OK') {
    //       this.setState({
    //         modal_api: !this.state.modal_api,
    //         error_msg: 'Ocurrión un error. No se pudo guardar el archivo.'
    //       });
    //     }

    //   })
    //   .catch(error => {
    //     this.setState({
    //       modal_api: !this.state.modal_api,
    //       error_msg: 'Ocurrión un error. No se pudo guardar el archivo.'
    //     });
    //   })
  }

  sendFile = () => {
    if (this.props.tag === 'personZip') {
      this.props.setState({
        zip_doc: this.state.document,
        continue: ''
      })
    }
    else {

      this.setState({
        modal_up: false,
        spinner: ''
      })

      let url = this.state.url

      axios({
        method: 'POST',
        url,
        data: this.state.document,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      }).then((res) => {
        this.setState({
          spinner: 'd-none',
          filename: res.data.img_name
        })
        if (this.props.cardTitle === 'INFO') {
          this.props.setState({
            info_doc: res.data.info
          })
        }
        else if (this.props.cardTitle === 'IMSS') {
          this.props.setState({
            imss_doc: res.data.imss
          })
        }
        else if (this.props.cardTitle === 'SAT') {
          this.props.setState({
            sat_doc: res.data.sat
          })
        }
        else if (this.props.cardTitle === 'Comunicación') {
          this.props.setState({
            com_doc: res.data.com
          })
        }
        else if (this.props.cardTitle === 'Notificaciones') {
          this.props.setState({
            noti_doc: res.data.notification
          })
        }
        else if (this.props.cardTitle === 'Declaración anual') {
          this.props.setState({
            anual_doc: res.data.anual
          })
        }
        else if (this.props.cardTitle === 'Comprobante de pago') {
          this.props.setState({
            voucher_doc: res.data.voucher
          })
        }
        else if (this.props.tag === 'RFC') {
          this.props.setState({
            rfc: res.data.rfc,
            document_rfc: {
              type: res.data.type_img,
              name: res.data.img_name,
              rfc: res.data.rfc,
              curp: res.data.curp,
              enrollment_date: res.data.enrollment_date,
              operations_start_date: res.data.operations_start_date,
              document_id: res.data.document_id,
              status_change_date: res.data.status_change_date,
              status: res.data.status,
              issue_place: res.data.issue_place,
              issue_date: res.data.issue_date,
              id_cif: res.data.id_cif,
              fiscal_residency: res.data.fiscal_residency
            }
          })
        }
        else if (this.props.tag === "Comprobante") {

          if (res.data.error) {
            this.setState({
              modal_api: true,
              error_msg: res.data.error
            })
            this.inputRef.current.value = ''
          }
          else {
            if (res.data.warning) {
              this.setState({
                modal_override: true,
                error_msg: res.data.warning,
                warning: true
              });
            }
            let obj_address = res.data.address.address
            this.setState({
              doc_up: true,
              img_name: this.reader.result,
              pdf_name: res.data.img_name
            })
            let c_opt = []
            res.data.address.colony_options.forEach(function (option) {
              c_opt.push({
                value: option,
                text: option
              })
            })
            this.props.setState({
              address: obj_address,
              city: res.data.address.city,
              colony: res.data.address.colony,
              state: res.data.address.state,
              street: res.data.address.street,
              municipio: res.data.address.municipio,
              colony_options: c_opt,
              zip: res.data.address.zip,
              comp: true,
              comp_color: true,
              document_address: {
                type: res.data.type_img,
                name: res.data.img_name,
                company: res.data.company
              },
              warning: this.state.warning
            })
          }
        }
        else if (this.props.tag === "personExcel") {
          let obj_personExcel = res.data
          this.props.setState({
            excel_doc: this.state.document,
            personExcel: obj_personExcel,
            validPerson: Object.keys(res.data.valid).length,
            invalidPerson: Object.keys(res.data.invalid).length,
            continue: ''
          })
        }
      }).catch(error => {
        this.setState({
          modal_api: !this.state.modal_api,
          error_msg: "Hubo un error al subir el archivo, por favor intente con otro archivo. " + error,
          spinner: "d-none"
        });
        this.inputRef.current.value = "";
      })
    }
  }

  handleAddress = (event) => {
    this.props.setState({
      address: event.target.value,
      comp: true,
    })
  }

  handleColony = (event) => {
    this.props.setState({
      colony: event.target.value,
      comp: true,
    })
    this.updatedAddress(this.props.params.street, event.target.value, this.props.params.zip, this.props.params.municipio, this.props.params.city, this.props.params.state)
  }

  handleMunicipio = (event) => {
    this.props.setState({
      municipio: event.target.value,
      comp: true,
    })
    this.updatedAddress(this.props.params.street, this.props.params.colony, this.props.params.zip, event.target.value, this.props.params.city, this.props.params.state)
  }

  handleSelectColony = (event) => {
    this.props.setState({
      colony: event,
      comp: true,
    })
    this.updatedAddress(this.props.params.street, event, this.props.params.zip, this.props.params.municipio, this.props.params.city, this.props.params.state)
  }
  handleStreet = (event) => {
    this.props.setState({
      street: event.target.value,
      comp: true,
    })
    this.updatedAddress(event.target.value, this.props.params.colony, this.props.params.zip, this.props.params.municipio, this.props.params.city, this.props.params.state)
  }
  handleCity = (event) => {
    this.props.setState({
      city: event.target.value,
      comp: true,
    })
    this.updatedAddress(this.props.params.street, this.props.params.colony, this.props.params.zip, this.props.params.municipio, event.target.value, this.props.params.state)
  }
  handleState = (event) => {
    this.props.setState({
      state: event.target.value,
      comp: true,
    })
    this.updatedAddress(this.props.params.street, this.props.params.colony, this.props.params.zip, this.props.params.municipio, this.props.params.city, event.target.value)
  }
  hanldeZip = (event) => {
    this.props.setState({
      zip: event.target.value,
      comp: true,
    })
    this.updatedAddress(this.props.params.street, this.props.params.colony, event.target.value, this.props.params.municipio, this.props.params.city, this.props.params.state)
    var body = new FormData();
    body.append('zipcode', event.target.value)
    body.append('colony', this.props.params.colony)
    axios({
      method: 'POST',
      url: API_URL + '/api/v1/find/colony',
      data: body,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then((res) => {
        if (res.data.result) {
          if (res.data.result.error) return
          let c_opt = []
          res.data.result.response.asentamiento.forEach(function (option) {
            c_opt.push({
              value: option,
              text: option
            })
          })
          this.props.setState({
            colony: res.data.colony,
            colony_options: c_opt,
            city: res.data.result.response.ciudad,
            state: res.data.result.response.estado,
            municipio: res.data.result.response.municipio
          })
          this.updatedAddress(this.props.params.street, res.data.colony, this.props.params.zip, res.data.result.response.municipio, res.data.result.response.ciudad, res.data.result.response.estado)
        }
      })
  }

  updatedAddress = (street, colony, zip, municipio, city, state) => {
    let new_address = ''
    if (street) new_address = street
    if (colony) new_address = new_address + ', ' + colony
    if (zip) new_address = new_address + ', ' + zip
    if (municipio) new_address = new_address + ', ' + municipio
    if (city) new_address = new_address + ', ' + city
    if (state) new_address = new_address + ', ' + state
    this.props.setState({
      address: new_address
    })
  }
  handleRfc = (event) => {
    this.props.setState({
      rfc: event.target.value,
      comp: true,
    })
  }

  handleDocumentRfcChange = (event, param) => {
    let temp_document_rfc = this.props.document_rfc
    temp_document_rfc[param] = event.target.value
    this.props.setState({
      document_rfc: temp_document_rfc,
      comp: true,
    })
  }

  toggleModalErrorApi() {
    this.setState({
      modal_api: !this.state.modal_api
    })
  }

  toggleModalUpDateDocument() {
    this.setState({
      modal_up: !this.state.modal_up,
    })
  }

  toggleModalExpired() {
    this.setState({
      modal_expired: !this.state.modal_expired
    })
  }

  toggle = (state) => {
    var stateObj = { [state]: !this.state[state] }
    this.setState(stateObj);
  }

  deleteDoc = () => {
    this.inputRef.current.value = ''
    this.setState({
      doc_up: false,
      modal_override: false
    })

    if (this.props.tag === 'personZip') {
      this.props.setState({
        continue: 'disabled',
        zip_doc: ''
      })
    }
    else if (this.props.tag === 'personExcel') {
      this.props.setState({
        continue: 'disabled',
        status: '',
        classBlock: 'op-20',
        zip_doc: '',
        disabledZip: 'error',
        excel_doc: '',
        personExcel: '',
        invalidPerson: 0,
        validPerson: 0
      })
    }
    else {
      this.props.setState({
        address: '',
        comp: true,
        comp_color: true,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Modal centered={true} isOpen={this.state.modal_override} toggle={() => this.toggle('modal_override')}>
            <ModalHeader>Advertencia</ModalHeader>
            <ModalBody>
              Se detectó el siguiente problema con el documento: {<b>{this.state.error_msg}</b>}
              {<br></br>}
              Verifica que esta información sea correcta y aprueba o descarta el documento.
              {<br></br>}
              {!this.state.pdf &&
                <img size='xl' src={this.state.img_name} height="100%" width="100%" alt=""></img>
              }
              {this.state.pdf &&
                <object data={this.state.img_name} type="application/pdf" width="50%" height="200" />
              }

              <ModalFooter>
                <ButtonPrimary onClick={() => this.uploadFileManual()} title="Aprobar"></ButtonPrimary>
                <ButtonPrimary onClick={() => this.deleteDoc()} title="Descartar"></ButtonPrimary>
              </ModalFooter>
            </ModalBody>
          </Modal>

          <Modal centered={true} isOpen={this.state.modal_up} toggle={() => this.toggleModalUpDateDocument()} className={this.props.className}>
            <ModalHeader toggle={() => this.toggleModalUpDateDocument()}>Reemplazar</ModalHeader>
            <ModalBody>
              Ya hay información registrada, ¿Desea reemplazarla?
            </ModalBody>
            <ModalFooter>
              <ButtonPrimary onClick={() => this.toggleModalUpDateDocument()} title="Cancelar"></ButtonPrimary>
              <ButtonPrimary onClick={() => this.sendFile()} title="Reemplazar"></ButtonPrimary>
            </ModalFooter>
          </Modal>
        </div>
        <Modal centered={true} isOpen={this.state.modal_api} toggle={() => this.toggleModalErrorApi()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggleModalErrorApi()}>Error en el archivo</ModalHeader>
          <ModalBody>
            {this.state.error_msg}
          </ModalBody>
          <ModalFooter>
            <ButtonPrimary onClick={() => this.toggleModalErrorApi()} title="Continuar"></ButtonPrimary>
          </ModalFooter>
        </Modal>
        <Modal centered={true} isOpen={this.state.modal_expired} toggle={() => this.toggleModalExpired()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggleModalExpired()}>Documento Expirado</ModalHeader>
          <ModalBody>
            El documento esta expirado, elija otro archivo.
          </ModalBody>
          <ModalFooter>
            <ButtonPrimary onClick={() => this.toggleModalExpired()} title="Continuar"></ButtonPrimary>
          </ModalFooter>
        </Modal>
        <Modal centered={true} isOpen={this.state.image_open} toggle={() => this.toggle('image_open')}>
          <img size='xl' src={this.state.img_name} height="100%" width="100%" onClick={() => this.toggle('image_open')}></img>
        </Modal>
        <div className={`upload-card ${this.props.customClass}`}>
          <div className={"spinnermodal " + this.state.spinner}>
            <Spinner style={{ width: '2rem', height: '2rem' }} />{' '}
          </div>
          <div className="datawrapper">
            <p className="title mb-10">{this.props.cardTitle}</p>
          </div>
          {this.props.required &&
            <div className="chips -warning -wide mb-10">Obligatorio</div>
          }
          <p className="mb-20">{this.props.cardInstructions}</p>
          {this.props.tag !== 'personZip' && this.props.tag !== 'personExcel' &&            
                <input
                  onChange={this.uploadFileCollapse}
                  ref={this.inputRef}
                  cardTitle={this.props.cardTitle}
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  className="select-file"
                />
          }
          {this.props.tag === 'personZip' &&
                <input
                  onChange={this.uploadFileCollapse}
                  ref={this.inputRef}
                  cardTitle={this.props.cardTitle}
                  type="file"
                  accept=".zip"
                  className="select-file"
                  disabled={(this.props.disabledZip) ? "disabled" : ""}
                />
          }
          {this.props.tag === 'personExcel' &&
                <input
                  onChange={this.uploadFileCollapse}
                  ref={this.inputRef}
                  cardTitle={this.props.cardTitle}
                  type="file"
                  accept=".xls, .xlsx"
                  className="select-file"
                />
          }
          <div className="select-document-form flex-column flex-md-row">
            {this.state.doc_up &&
              <React.Fragment>
                    {!this.state.pdf &&
                      < div style={{ marginRight: "80px" }}>
                        <p><b>Abrir vista previa</b></p>
                        <img src={this.state.img_name} position="center" className="datawrapper flex-column" height="100px" onClick={this.toggle} />
                      </div>
                    }
                    {this.state.pdf &&
                      <div style={{ marginRight: "80px" }}>
                        <a href={DOCUMENTS_URL + this.state.pdf_name} target='_blank'>
                          Ver
                          </a>
                        <br></br>
                        <object data={this.state.img_name} type="application/pdf" width="50%" height="200" />
                      </div>
                    }
                  </React.Fragment>                
            }
          </div>
          <button className="button -text mt-20" onClick={() => this.toggleCollapseID()}>Llenar de forma manual <i class="fas fa-angle-down"></i></button>
          <Collapse isOpen={this.state.openCollapse}>
            {this.props.tag === 'Comprobante' &&
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" label="Dirección" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" type="text" onChange={this.handleAddress} value={this.props.address} />
                <br></br>
                <div className="chips -warning -wide mb-10">Introducir el Código Postal para llenar de forma automática el resto de los campos</div>
                <InputForm inputColor="-secondary" label="Código Postal" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" type="text" onChange={this.hanldeZip} value={this.props.params.zip} />
                <InputForm inputColor="-secondary" label="Calle" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" type="text" onChange={this.handleStreet} value={this.props.params.street} />
                <div className={`group mt-50`}>
                  <label htmlFor="">Colonia</label>
                </div>
                {(this.props.params.colony_options == undefined || this.props.params.colony_options.length == 0) &&
                  <InputForm inputColor="-secondary" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" type="text" onChange={this.handleColony} value={this.props.params.colony} />
                }
                {this.props.params.colony_options && this.props.params.colony_options.length > 0 &&
                  <DropdownSelect
                    items={this.props.params.colony_options}
                    selected={this.props.params.colony}
                    onChange={this.handleSelectColony}
                  />
                }
                <InputForm inputColor="-secondary" label="Municipio" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" type="text" onChange={this.handleMunicipio} value={this.props.params.municipio} />
                <InputForm inputColor="-secondary" label="Ciudad" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" type="text" onChange={this.handleCity} value={this.props.params.city} />
                <InputForm inputColor="-secondary" label="Estado" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" type="text" onChange={this.handleState} value={this.props.params.state} />
              </div>
            }
            {this.props.tag === 'RFC' &&
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" label="RFC" customClasses="mt-50" warning="Datos no válidos" requiredText="Opcional" type="text" onChange={this.handleRfc} value={this.props.rfc} />
              </div>
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.id_cif !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="idCIF" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'id_cif') }} type="text" value={this.props.document_rfc.id_cif} />
              </div> :
              <span />
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.enrollment_date !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="Fecha de inscripción" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'enrollment_date') }} type="text" value={this.props.document_rfc.enrollment_date} />
              </div> :
              <span />
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.operations_start_date !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="Fecha de inicio de operaciones" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'operations_start_date') }} type="text" value={this.props.document_rfc.operations_start_date} />
              </div> :
              <span />
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.document_id !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="Folio" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'document_id') }} type="text" value={this.props.document_rfc.document_id} />
              </div> :
              <span />
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.status_change_date !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="Fecha de último cambio de estado" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'status_change_date') }} type="text" value={this.props.document_rfc.status_change_date} />
              </div> :
              <span />
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.status !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="Estatus en el padrón" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'status') }} type="text" value={this.props.document_rfc.status} />
              </div> :
              <span />
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.issue_place !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="Lugar de emisión" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'issue_place') }} type="text" value={this.props.document_rfc.issue_place} />
              </div> :
              <span />
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.issue_date !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="Fecha de emisión" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'issue_date') }} type="text" value={this.props.document_rfc.issue_date} />
              </div> :
              <span />
            }
            {this.props.tag === 'RFC' && this.props.document_rfc.fiscal_residency !== undefined ?
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.clave_color} label="Domicilio Fiscal / Ubicación" customClasses="mt-50" warning="Datos no válidos" onChange={(e) => { this.handleDocumentRfcChange(e, 'fiscal_residency') }} type="text" value={this.props.document_rfc.fiscal_residency} />
              </div> :
              <span />
            }
          </Collapse>
        </div>
      </React.Fragment >
    );
  }
}

export default UploadCard;