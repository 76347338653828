import React, { Component } from 'react';
class RecommendationsProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <div className={`recommendation-progress ${this.props.className}`}>
        <div className="step">
          <div className={`stepmark ${this.props.stages[0]}`}></div>
          <div className={`line ${this.props.stages[1]}`}></div>
          <p className={`d-none d-md-block stepname ${this.props.stages[0]}`}>1. Tipo de uso</p>
        </div>
        <div className="step">
          <div className={`stepmark ${this.props.stages[1]}`}></div>
          <div className={`line ${this.props.stages[2]}`}></div>
          <p className={`d-none d-md-block stepname ${this.props.stages[1]}`}>2. Espacio</p>
        </div>
        <div className="step">
          <div className={`stepmark ${this.props.stages[2]}`}></div>
          <div className={`line ${this.props.stages[3]}`}></div>
          <p className={`d-none d-md-block stepname ${this.props.stages[2]}`}>3. Presupuesto</p>
        </div>
        <div className="step">
          <div className={`stepmark ${this.props.stages[3]}`}></div>
          <div className="line invisible"></div>
          <p className={`d-none d-md-block stepname ${this.props.stages[3]}`}>4. Modelo</p>
        </div>
      </div>
    );
  }
}

export default RecommendationsProgress;