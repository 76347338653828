import React, { Component } from 'react';
import Form from 'reactstrap/lib/Form';

class AddImage extends Component {
  delete = () => {
    console.log(this.props.source, this.props.index)
    let source = this.props.source
    if(this.props.index >= 0){
      source.splice(this.props.index, 1)
      this.props.setState({source})
    } else {
      this.props.setState({primary_image: ''})
    }
  }
  render() {
    return (
      <div className="image-add">
        <img className="w-100" src={this.props.image}  alt=""/>
        <div className="image-file">
          <a onClick={this.delete} className="button -delete -white"><i class="fas fa-trash-alt m-0"></i></a>
          <p className="file-name mb-0">{this.props.name}</p>
        </div>
      </div>
    );
  }
}

export default AddImage;