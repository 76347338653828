import React, { Component } from 'react';
import { CustomInput } from 'reactstrap';

import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import Footer from '../../Components/Footer/Footer';
import AddImage from '../../Components/SellerComponents/AddImage/AddImage';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import ModalAddCar from '../../Components/Modals/ModalAddCar';
import axios from 'axios'
import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode'


import Car from '../../Image/car-detail/car.png';
import Gallery1 from '../../Image/car-detail/Gallery-1.jpg';
import Gallery2 from '../../Image/car-detail/gallery-2.jpg';
import Gallery3 from '../../Image/car-detail/gallery-3.jpg';
import Gallery4 from '../../Image/car-detail/gallery-4.jpg';
const API_URL = process.env.REACT_APP_API_URL

class AddCar extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      first_toggle:true,
      modal_add_car: false,
      doc_up: false,
      primary_image: '',
      gallery_images: [],
      breaks: false,
      air_bags: false,
      blind_spot: false,
      EBD: false,
      brand: '',
      model: '',
      model_year: '',
      version: '',
      clasification: '',
      price: 0,
      capacity: 0,
      color: '',
      trasmission: '',
      type:'',
      power: 0,
      torque: 0,
      liters: 0,
      fuel:'',
      performance_city:'',
      performance_highway:'',
      performance_mixed:'',
      stock: 0,
      rines: '',
      apple_car:false,
      electric_seat_driver:'',
      electric_seat_passenger:'',
      rearview_mirror: '',
      publish: false,
      images_uploaded:0,
      total_images:0,
      progress: 0,
      upload:false,
      car_id:'',
      started_upload: false,
      valid:false
    }
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })
  onChangePrice = e => this.setState({ price: e.value })

  componentDidMount = () => {
    this.setState({user_id:jwtDecode(localStorage.getItem("id_token")).sub})
  }

  toggle= () => {
    let publish = this.state.publish
    this.setState({
      modal_add_car: !this.state.modal_add_car,
      publish: !publish
    })
    if(this.state.first_toggle){
      this.createCar()
      this.setState({first_toggle:false})
    }      
    
  }

  uploadImages = () => {
    let url = API_URL + '/api/v1/cars/upload_photo'
    if(!this.state.primary_image) {
      this.setState({valid:true})
      return
    }
    let p_image = this.state.primary_image
    p_image.append('car_id',this.state.car_id)
    p_image.append('user_id',this.state.user_id)
    axios({
      method: 'POST',
      url,
      data: this.state.primary_image,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(() => {
      for(let i = 0; i<this.state.gallery_images.length ; i++){
        // let g_body = new FormData()
        let g_body = this.state.gallery_images[i]
        g_body.append('car_id', this.state.car_id)
        g_body.append('user_id',this.state.user_id)
        axios({
          method: 'POST',
          url,
          data: g_body,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
      }
      this.setState({valid:true})
      if(!this.state.publish) this.setState({redirect:true})
    })
  }

  createCar = () => {
    let url = API_URL + '/api/v1/cars/create'
    var body = new FormData();
    for (var key in this.state) {
      // check if the property/key is defined in the object itself, not in parent
      if (this.state.hasOwnProperty(key)) { 
          body.append(key, this.state[key])
      }
    }
    body.append('group_id', localStorage.getItem("group_id"))
    console.log('creating car')
    axios({
      method: 'POST',
      url,
      data: body,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then((res) => {
      console.log(res.data)
      this.setState({car_id:res.data},()=>{
        this.uploadImages()
      })
    })
  }
  cancel = () => {this.setState({redirect:true})}

  _setState = (state, filters = false) => {

    this.setState(state, ()=>{})
  }

  loadPrimaryImage = (event) => {
    let name = event.target.name
    let file;
    let pdf;
    try {
      file = event.target.files[0];
    } catch {
      file = event
    }
    if (file) {
      pdf = file.type === 'application/pdf';
      this.setState({
        image_event: file,
        display_form: ''
      })

      if (file.size < 14400 && !pdf) {
        // alert('Por favor suba una imagen con dimensiones mayores a 120 x 120')
        this.props.setState({
          modal: !this.props.params.modal,
          active: this.state.comp_id
        })
      }
      else if (this.state.doc_up) {
        // alert('Por favor borre el documento antes de subir otro')

        this.props.setState({
          modal_up: !this.props.params.modal_up,
          active: this.state.comp_id
        })
      }
      else {
        this.reader = new FileReader();
        this.reader.readAsDataURL(file);
        this.reader.onloadend = () => {
          var image = new FormData()

          image.append('image', file)
          image.append('file', this.reader.result)

          this.setState({
            [name]: image,
            doc_up: true,
            pdf,
            spinner: '',
            [name+'_img']: this.reader.result

          })
        }
      }
    }
  }

  loadGalleryImage = (event) => {
    let file;
    let pdf;
    try {
      file = event.target.files[0];
    } catch {
      file = event
    }
    if (file) {
      pdf = file.type === 'application/pdf';
      this.setState({
        image_event: file,
        display_form: ''
      })

      if (file.size < 14400 && !pdf) {
        // alert('Por favor suba una imagen con dimensiones mayores a 120 x 120')
        this.props.setState({
          modal: !this.props.params.modal,
          active: this.state.comp_id
        })
      }
      else {
        this.reader = new FileReader();
        this.reader.readAsDataURL(file);
        this.reader.onloadend = () => {
          // this.state.gallery_images.push(file)
          let gallery = this.state.gallery_images

          var image = new FormData()

          image.append('image', file)
          image.append('file', this.reader.result)

          gallery.push(image)
          
          this.setState({
            gallery_images: gallery,
            spinner: '',
          })
        }
      }
    }
  }


  render() {
    return (
    <div className="add-car">
      <ModalAddCar
        toggle={this.toggle}
        modal={this.state.modal_add_car}
        name={this.state.brand}
        model={this.state.model}
        version={this.state.version}
        valid={this.state.valid}
        title="¡ Haz dado de alta un auto !"
        text={`Se ha publicado el auto “${this.state.brand} ${this.state.model} ${this.state.model_year} ${this.state.version}” puedes verlo y editarlo desde tu perfil.`}
        image={this.state.primary_image_img}
      />
      <div className="container-fluid px-0">
        <div className="row mx-0">
          <NavBarComponent {...this.props}></NavBarComponent>
        </div>

        <div className="container-fluid mt-top min-height d-flex px-0">
        {this.state.redirect &&
          <Redirect to={'/seller-admin'}></Redirect>
        }
          <SideNav></SideNav>

          <div className="container">
            <div className="row mx-0">
              <div className="col-12 px-0">
                  <p className="h1-b mt-xl text-center">¡ Da de alta un carro !</p>
              </div>
            </div>

            <div className="cm-card -primary">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <p className="h2-b">Datos generales</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Precio</label>
                    <NumberFormat
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        placeholder="$200,000"
                        name={"price"}
                        onValueChange={this.onChangePrice} />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Marca</label>
                    <input type="text"
                          name="brand"
                          onChange={this.onChange}
                          placeholder="Ej. Mazda" />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Modelo</label>
                    <input type="text"
                          name="model"
                          onChange={this.onChange}
                          placeholder="Ej. Mazda 3" />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Año</label>
                    <input type="number"
                          name="model_year"
                          onChange={this.onChange}
                          placeholder="Ej 2020" />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Versión</label>
                    <input type="text"
                          name="version"
                          onChange={this.onChange}
                          placeholder="Ej. Ex" />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Clasificación</label>
                    <select name="clasification"
                            id=""
                            onChange={this.onChange}
                            >
                      <option value="">Seleccionar</option>
                      <option value="Familiar">Familiar</option>
                      <option value="Deportivo">Deportivo</option>
                      <option value="Carga">Carga</option>
                      {/* <option value="Azul">Azul</option> */}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Número de asientos</label>
                    <input type="number"
                          name="capacity"
                          onChange={this.onChange}
                          />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Stock</label>
                    <input type="number"
                          name="stock"
                          onChange={this.onChange}
                          placeholder="Número de piezas" />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Color</label>
                    <select name="color"
                            id=""
                            onChange={this.onChange}
                            >
                      <option value="">Seleccionar</option>
                      <option value="Blanco">Blanco</option>
                      <option value="Negro">Negro</option>
                      <option value="Rojo">Rojo</option>
                      <option value="Azul">Azul</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>            

            <div className="cm-card -primary mt-m">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <p className="h2-b">Carga de imágenes</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group d-flex flex-column mb-0">
                    <label htmlFor=""><b>Cargar imagen principal</b></label>
                    <div className="required mt-xxs">
                      <i class="fas fa-info-circle"></i>Esta imagen debe tener fondo transparente  y estar en formato .png para poder usar los estilos de la plataforma.
                    </div>
                    <div class="upload-btn-wrapper mt-xxs">
                      <a class="button -primary">
                        <i class="fas fa-upload"></i>
                        Cargar archivos</a>
                      <input onChange={this.loadPrimaryImage} name="primary_image"  ref={this.inputRef} type="file" accept=".jpg, .jpeg, .png" className="select-file" />
                    </div>
                  </div>
                </div>
              </div>
              {this.state.primary_image_img &&
                <div className="row">
                  <div className="col-12">
                    {this.state.primary_image &&
                    <AddImage
                    image={this.state.primary_image.get('file')}
                    name={this.state.primary_image.get('image').name}
                    source={this.state.primary_image}
                    index={-1}
                    setState={this._setState}
                    />
                    }
                  </div>
                </div>
              }

              <div className="row mt-s">
                <div className="col-12">
                  <div className="form-group d-flex flex-column mb-0">
                    <label htmlFor=""><b>Cargar imagenes de galería</b></label>
                    <div class="upload-btn-wrapper mt-xxs">
                      <a class="button -primary">
                        <i class="fas fa-upload"></i>
                        Cargar archivos</a>
                      <input type="file" name="myfile" onChange={this.loadGalleryImage} name="primary_image"  ref={this.inputRef} accept=".jpg, .jpeg, .png" className="select-file" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 d-flex flex-row flex-wrap">
                {this.state.gallery_images.map((item, index) => {
                  return(
                    <div className="col-12 col-lg-3 mt-xs">
                        <AddImage
                        image={item.get('file')}
                        name={item.get('image').name}
                        source={this.state.gallery_images}
                        index={index}
                        setState={this._setState}
                        />
                    </div>
                  )
                })}
            </div>

            </div>

            <div className="cm-card -primary mt-m">
              <div className="row">
                <div className="col-12">
                  <p className="h2-b">Especificaciones</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Transmisión</label>
                    <select name="transmission"
                            id=""
                            onChange={this.onChange}>
                      <option value="">Seleccionar</option>
                      <option value="AT">AT</option>
                      <option value="18">Negro</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Tipo de motor</label>
                    <select name="type"
                            id=""
                            onChange={this.onChange}>
                      <option value="">Seleccionar</option>
                      <option value="CVT">CVT</option>
                      <option value="CRT">CRT</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Potencia</label>
                    <select name="colors"
                            id="">
                      <option value="">Seleccionar</option>
                      <option value="12">Blanco</option>
                      <option value="18">Negro</option>
                    </select>
                  </div>
                </div> */}
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Torque (N/M)</label>
                    <input type="number"
                          name="torque"
                          onChange={this.onChange}
                          />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Potencia (HP)</label>
                    <input type="number"
                          name="power"
                          onChange={this.onChange}
                          />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Tamaño de motor (litros)</label>
                    <input type="number"
                          name="liters"
                          onChange={this.onChange}
                          />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Tipo de combustible</label>
                    <select name="fuel"
                            id=""
                            onChange={this.onChange}>
                      <option value="">Seleccionar</option>
                      <option value="Diesel">Diesel</option>
                      <option value="Premium">Premium</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Rines</label>
                    <select name="rines"
                            id=""
                            onChange={this.onChange}>
                      <option value="">Seleccionar</option>
                      <option value="12">12"</option>
                      <option value="16">16"</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Espejo retrovisor</label>
                    <select name="rearview_mirror"
                            id=""
                            onChange={this.onChange}>
                      <option value="">Seleccionar</option>
                      <option value="Monocromatico">Monocromático</option>
                      <option value="Electrico">Eléctrico</option>
                      <option value="Manual">Manual</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Control eléctrico (conductor)</label>
                    <input type="number"
                          name="electric_seat_driver"
                          onChange={this.onChange}
                          placeholder="8 vías" />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Control eléctrico (pasajeros)</label>
                    <input type="number"
                          name="electric_seat_passenger"
                          onChange={this.onChange}
                          placeholder="6 vías" />                
                  </div>
                </div>
                <div className="col-12 col-lg-3 d-flex align-items-center">
                  <div className="form-group">
                    <label htmlFor="">Apple Car</label>
                    <CustomInput className="toggle" type="switch" name="apple_car" id="apple-car-toggle" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="col-12 col-lg-3 d-flex align-items-center">
                  <div className="form-group">
                    <label htmlFor="">Android Car</label>
                    <CustomInput className="toggle" type="switch" name="android_car" id="apple-car-toggle" onChange={this.onChange}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="cm-card -primary mt-m">
              <div className="row">
                <div className="col-12">
                  <p className="h2-b">Desempeño</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Kilometraje en Ciudad</label>
                    <input type="number"
                          name="performance_city"
                          onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Kilometraje en Carretera</label>
                    <input type="number"
                          name="performance_highway"
                          onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="">Kilometraje en promedio</label>
                    <input type="number"
                          name="performance_mixed"
                          onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="cm-card -primary mt-m">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <p className="h2-b">Seguridad</p>
                </div>
              </div>

              <div className="row mt-xs">
                <div className="col-6 col-lg-2">
                  <div className="form-group">
                    <label htmlFor="">Frenos ABS</label>
                    <CustomInput className="toggle" type="switch" name="breaks" id="breaks-toggle" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="col-6 col-lg-2">
                  <div className="form-group">
                    <label htmlFor="">Bolsas de aire</label>
                    <CustomInput className="toggle" type="switch" name="air_bags" id="air-bags-toggle" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="col-6 col-lg-2">
                  <div className="form-group">
                    <label htmlFor="">Punto ciego</label>
                    <CustomInput className="toggle" type="switch" name="blind_spot" id="blind-spot-toggle" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="col-6 col-lg-2">
                  <div className="form-group">
                    <label htmlFor="">EBD</label>
                    <CustomInput className="toggle" type="switch" name="EBD" id="evd-toggle" onChange={this.onChange}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0 my-l d-flex flex-column-reverse flex-md-row">
              <div className="col-12 col-md-4 col-lg-3 mt-xs">
                <button onClick={this.cancel} className="button -tertiary -red w-100">Cancelar</button>
              </div>
              <div className="col-12 col-md-4 col-lg-3 mt-xs">
                <button onClick={this.createCar} className="button -tertiary w-100">Guardar</button>
              </div>
              <div className="col-12 col-md-4 col-lg-3 mt-xs">
                <button onClick={this.toggle} className="button -primary w-100">Guardar y publicar</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mx-0">
          <Footer></Footer>
        </div>
      </div>
    </div>
    );
  }
}

export default AddCar;