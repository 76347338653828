import React, { Component } from 'react';
import DropdownArrow from '../../../Image/svg/dropdown-arrow.svg';
import SearchBar from '../../SearchBar/SearchBar';
import NumberFormat from 'react-number-format';



class MobileFilter extends Component {

  constructor(props) {
    super(props)

    this.state = {
      price_filter: '',
      brand_filter: '',
      model_filter: '',
      color_filter: '',
      year_filger: '',
      doors_filter: '',
      checked_price: -1,
      min_price: '',
      max_price: '',
      location_filter: ''
    }
  }
  clean = () => {
    this.props.setState({
      brand: '',
      model: '',
      color: '',
      year: '',
      max_price: 99999999,
      min_price: 0,
      checked_price: -1,
      checked_brand: -1,
      checked_model: -1,
      checked_year: -1,
      location: '',
      checked_location: -1,
    })
    this.setState({
      //price_filter: '',
      //brand_filter: '',
      //model_filter: '',
      color_filter: '',
      year_filger: '',
      doors_filter: '',
      checked_price: -1,
      min_price: '',
      max_price: ''
    })
  }

  hideFilters = ()  => {
    this.props.setState({
      show_filters: "col-12 d-none"
    })
  }

  handlerBrand = (event) => { 
    this.props.setState({
      brand: event.target.id,
      model: '',
      color: '',
      year: '',
      checked_model: -1,
      checked_year: -1,
      checked_brand:event.target.value,
      exclude: 'brand'
    }, true)
  }

  handlerMinPrice = (event) => {
    this.setState({
      min_price: event.floatValue
    })
    this.setDefaults()
    this.props.setState({
      min_price: event.floatValue
    },()=>{
      if(this.props.min_price!==''&&this.props.max_price!==''){
        this.props.searchCars()
        this.props.calculate_filters()
      }
    })
  }
  

  handlerMaxPrice = (event) => {
    this.setState({
      max_price: event.floatValue
    })
    this.setDefaults()
    this.props.setState({
      max_price: event.floatValue
    }, ()=>{
      if(this.props.min_price!==''&&this.props.max_price!==''){
        this.props.searchCars()
        this.props.calculate_filters()
      }
    })
  }

  setDefaults = () =>{
    if(this.state.min_price==="" || this.state.min_price==="undefined"){this.props.setState({min_price:0})}
    if(this.state.max_price==="" || this.state.max_price==="undefined"){this.props.setState({max_price:999999999})}
  }

  handlerSortMin = () =>{
    this.props.setState({
      sort: -1
    })
  }

  handlerSortMax = () =>{
    this.props.setState({
      sort: 1
    })
  }


  handlerYear = (event) => {
    this.props.setState({
      year: event.target.id,
      color: '',
      checked_year:event.target.value, 
      exclude: 'model_year'
    })
  }
    handlerLocation = (event) => {
    this.props.setState({
      location: event.target.id,
      checked_location:event.target.value,
    })
  }

  openBrandFilter = () => {

    let temp = ''

    if (this.state.brand_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ brand_filter: temp })
  }


  openPriceFilter = () => {

    let temp = ''

    if (this.state.price_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ price_filter: temp })
  }

  openyearFilter = () => {

    let temp = ''

    if (this.state.year_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ year_filter: temp })
  }
  openLocationFilter = () => {

    let temp = ''

    if (this.state.location_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ location_filter: temp })
  }


  render() {
    return (
      <div className="MobileFilters mt-top min-height">
        <div className="row mx-0">
          <div className="col-12 d-flex justify-content-between align-items-center mt-xs">
            <p className="h3-b mb-0">Filtrar</p>
            <button className="button -text d-flex" onClick={() => this.hideFilters()}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
{/* 
        <div className="row mx-0 mt-xs">
          <div className="col-12">
            <p className="body-b">Ordenar por</p>
          </div>
           <div className="col-12 mb-xxs">
            <div className="form-group">
              <input className="checkbox" type="radio"></input>
              <label className="checkbox">Destacados</label>
            </div>
          </div>
          <div className="col-12 mb-xxs">
            <div className="form-group">
              <input className="checkbox" type="radio"></input>
              <label className="checkbox">Más reciente</label>
            </div>
          </div> 
            <div className="col-12 mb-xxs">
              <div className="form-group">
                <input className="checkbox" type="radio" onClick={()=>this.handlerSortMax()}></input>
                <label className="checkbox">Precio de mayor a menor</label>
              </div>
            </div>
            <div className="col-12 mb-xxs">
              <div className="form-group">
                <input className="checkbox" type="radio" onClick={()=>this.handlerSortMin()}></input>
                <label className="checkbox">Precio de menor a mayor</label>
              </div>
            </div>
            </div> */}

        <hr/>
        {/*
        <div className="row mx-0 mt-xs">
          <div className="col-12 mb-xs"> 
          </div>
          <button className={`dropdown ${this.state.price_filter}`} >
          <div className="dropdown-title" onClick={() => this.openPriceFilter()}>
            <p className="h3-m mb-0">Precio</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>
          <div className="dropdown-content" >
          {(this.props.data) &&
            (this.props.data.price_brackets.map((item,i) => {
                return (
                  <div className="form-group">
                    <input className="checkbox" type="radio" id={item.value} value={i} name={"price"+i} onChange={this.handlerPrice} checked={this.props.data.checked_price == i}   ></input>
                    <label className="checkbox" for={item.value}>{item.value}</label>
                  </div>
                )
              }))
          }
          </div>
        </button>
        </div> */}
        
        <div className="row mx-0 mt-xs">
          <div className="col-12 mb-xs">
          </div>

          <button className={`dropdown ${this.state.price_filter}`}>
          <div className="dropdown-title" onClick={() => this.openPriceFilter()}>
          <p className="h3-m mb-0">Precio</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>

          <div className="dropdown-content">      

            <div className="col-12 mt-s mb-xxs">
              <div className="form-group ">

                <NumberFormat
                          name="min_price"
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          placeholder="Ej. $50,000"
                          value={this.state.min_price}
                          onValueChange={this.handlerMinPrice} />

                <label htmlFor="">Mínimo</label>
              </div>
            </div>

          <div className="col-12 mb-0">
            <div className="form-group">
            <NumberFormat
                      name="max_price"
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      placeholder="Ej. $1,000,000"
                      value = {this.state.max_price}
                      onValueChange={this.handlerMaxPrice} />
              <label htmlFor="">Máximo</label>
            </div>
          </div> 

          </div>
          </button>
        </div>
        <hr/>

        <div className="row mx-0 mt-xs">
          <div className="col-12">
            </div>

          <button className={`dropdown ${this.state.brand_filter}`}>
          <div className="dropdown-title" onClick={() => this.openBrandFilter()}>
            <p className="h3-m mb-0">Marca</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>
          <div className="dropdown-content">              

          {(this.props.data) &&
          (this.props.data.brand_options.map((item,i) =>{
            return(
            <div className="form-group">
                <input className="checkbox" type="radio" id={item._id} value={i} name={"brand"+i} onChange={this.handlerBrand} checked={this.props.data.checked_brand == i}></input>
                  <label className="checkbox" for={item._id}>{item._id}</label>
            </div>
          )}
          ))}
          </div>
        </button>
        </div>


{/* 
        <div className="row mx-0 mt-xs">
          <div className="col-12">
            <p className="body-b">Colores</p>
          </div>
          <div className="col-12">
            <div className="form-group mb-0 mt-xs">
              <select name="" id="">
                <option value="" selected disabled>Seleccionar</option>
                <option value="">Rojo</option>
                <option value="">Azul</option>
                <option value="">Negro</option>
                <option value="">Blanco</option>
              </select>
              <label htmlFor="">Color</label>
            </div>
          </div>
        </div>
         */}
        <hr/>


        <div className="row mx-0 mt-xs">
          <div className="col-12">
          </div>

          <button className={`dropdown ${this.state.year_filter}`}>
          <div className="dropdown-title" onClick={() => this.openyearFilter()}>
            <p className="h3-m mb-0">Año</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>
          <div className="dropdown-content">
          {(this.props.data) &&
            (this.props.data.year_options.map((item,i) => {
                return (
                  <div className="form-group">
                    <input className="checkbox" type="radio" id={item._id} value={i} name={"year"+i} onChange={this.handlerYear} checked={this.props.data.checked_year == i}></input>
                    <label className="checkbox" for={item._id}>{item._id}</label>
                  </div>
                )
              }))
          }
          </div>
        </button>


{/* 
          <div className="col-12 mb-xxs">
            <div className="form-group">
              <input className="checkbox" type="radio"></input>
              <label className="checkbox">2017</label>
            </div>
          </div>
          <div className="col-12 mb-xxs">
            <div className="form-group">
              <input className="checkbox" type="radio"></input>
              <label className="checkbox">2018</label>
            </div>
          </div>
          <div className="col-12 mb-xxs">
            <div className="form-group">
              <input className="checkbox" type="radio"></input>
              <label className="checkbox">2019</label>
            </div>
          </div>
          <div className="col-12 mb-xxs">
            <div className="form-group">
              <input className="checkbox" type="radio"></input>
              <label className="checkbox">2020</label>
            </div>
          </div> */}


        </div>
        <hr/>


        <div className="row mx-0 mt-xs">
          <div className="col-12">
          </div>

          <button className={`dropdown ${this.state.location_filter}`}>
            <div className="dropdown-title" onClick={() => this.openLocationFilter()}>
              <p className="h3-m mb-0">Ubicación</p>
              <img className="dropdown-arrow" src={DropdownArrow} alt=""/>
            </div>
            <div className="dropdown-content">
              {(this.props.data) &&
                  (this.props.data.location_options.map((item, i) => {
                    return (
                        <div className="form-group">
                          <input className="checkbox" type="radio" id={item._id} value={i} name={"year" + i}
                                 onChange={this.handlerLocation} checked={this.props.data.checked_location == i}></input>
                          <label className="checkbox" htmlFor={item._id}>{item._id}</label>
                        </div>
                    )
                  }))
              }
            </div>
          </button>
        </div>

        <div className="row mx-0 mt-xs">
           <div className="col-12 mb-1">
            <a target="_blank" href="http://35.222.144.183/api/v1/cars/pdf" download><button className="button -tertiary w-100">Descargar Catálogo</button></a>
            </div>
          <div className="col-12">
            <button className="button -tertiary w-100"onClick={()=>this.clean()}>Limpiar filtros</button>
            </div>
        </div>
      </div>
    );
  }
}

export default MobileFilter;
