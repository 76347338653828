import React, { Component } from 'react';

import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { CustomInput} from 'reactstrap';


const API_URL = process.env.REACT_APP_API_URL

class SellerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      name:'',
      last_name:'',
      email:'',
      redirect:false,
      password:false,
      user_id:''
    }
  }

  togglePassword = (e) => {
    this.setState({password: !this.state.password})
  }

  componentDidMount = () => {
    let user_id = this.props.location[0].match.params.user_id
    let url = `${API_URL}/api/v1/users/get?user_id=${user_id}`
    console.log(user_id)
    axios.get(url).then(res => {
      let user = res.data
      console.log(res)
      this.setState({
        name: user.first_name,
        last_name: user.last_name,
        user_id,
        email:user.email
      })
    })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  generatePassword() {
    var length = 4;
    var charset = "abcdefghijklmnopqrstuvwxyz";
    var retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    charset = "0123456789";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  createUser = () => {
    let url = `${API_URL}/api/v1/users/update`
    let user_info = {
      update_values:{
        first_name:this.state.name,
        last_name:this.state.last_name
      },
      password:this.state.password,
      user_id:this.state.user_id,
      email:this.state.email
    }
    axios.post(url, {
      profile:user_info
    }).then((res) => {
      this.setState({redirect:true})
    })
      
  }

  render() {
    return (
      <div className="add-car">
        {this.state.redirect &&
          <Redirect to={'/seller-admin'}></Redirect>
        }
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="container-fluid mt-top min-height d-flex px-0">
            <SideNav></SideNav>

            <div className="container">

              <div className="row mb-l">
                <div className="col-12 col-lg-8">
                  <div className="row  mt-xl">
                    <div className="col-12">
                      <p className="h1-b">Nuevo vendedor</p>
                    </div>
                  </div>

                  <form action="">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Nombre</label>
                          <input 
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Apellido</label>
                          <input 
                            type="text"
                            name="last_name"
                            value={this.state.last_name}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      {/* <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Correo electrónico</label>
                          <input 
                            type="email"
                            name="email"
                            value={this.state.email}
                            disable={'disabled'}
                            onChange={this.onChange}
                            placeholder="correo@ejemplo.com"
                          />
                        </div>
                      </div> */}

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Cambiar Contraseña</label>
                          <CustomInput className="toggle" type="switch" name="password" id="password" onChange={this.togglePassword}/>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <button onClick={this.createUser} className="button -primary w-100 mb-m">Editar vendedor</button>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <a href="seller-admin"><button className="button -tertiary w-100 mb-m">Cancelar</button></a>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer></Footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SellerEdit;