import React, { Component } from 'react'
import {
  FaCheckCircle,
  FaTrash,
} from 'react-icons/fa'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { Collapse, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ButtonPrimary } from '../Button/Button';
import InputForm from '../InputForm/InputForm';
import DropdownSelect from './../DropdownSelect/DropdownSelect'

const API_URL = process.env.REACT_APP_API_URL
const DOCUMENTS_URL = process.env.REACT_APP_PERSON_DOC_URL;

const checkedCopy = [false, false, false, false, false]

const dropdown_gender_items = [
  {
    value: "H",
    text: "Hombre"
  },
  {
    value: "M",
    text: "Mujer"
  }
]

class DocumentCardLicense extends Component {
  constructor(props) {
    super(props)

    this.inputRef = React.createRef();

    this.state = {
      openCollapse: false,
      doc_card_disp: '',
      spinner: 'd-none',
      doc_up: false,
      valid_curp: true,
      info: false,
      image_open: false,
      modal_override: false, //Modal to aprove an invalid document manually
      error_msg: '',
      filename: {},
      description: '',
      // Data for physical person
      user: {
        name: '',
        first_surname: '',
        second_surname: '',
        gender: 'Seleccionar sexo',
        clave: '',
        curp: '',
        birthdate: '',
        moral_rfc: '',
        img: '',
        signature: '',
        nationality: '',
      },
      description_id: 'Sube tu archivo a reconocer, debe ser INE, IFE, Pasaporte, FM2 o FM3.\n',
      description_license: 'Sube tu licencia para manejar.\n',
      description: '',
      image_event: '',
      display_form: 'd-none',
      documentsList: [{
        image: '-ife',
        documentType: 'INE',
      }, {
        image: '-license',
        documentType: 'Licencia',
      }, {
        image: '-passport',
        documentType: 'Pasaporte'
      }, {
        image: '-acta',
        documentType: 'Acta',
      }, {
        image: '-rfc',
        documentType: 'RFC'
      }, {
        image: '-video',
        documentType: 'video'
      }, {
        image: '-fm2',
        documentType: 'FM2'
      }, {
        image: '-fm3',
        documentType: 'FM3'
      }]
    }
  }

  getDescription = () => {
    if (this.props.title == 'Licencia de manejo') {
      return this.state.description_license
    }
    return this.state.description_id
  }

  toggleCollapseID = () => {
    this.setState({
      openCollapse: !this.state.openCollapse
    })
  }

  deleteDoc = () => { // Delete data and document from Document card and dashboard

    // Set imput value to empty
    this.inputRef.current.value = "";
    this.setState({
      new_Date: '',
      doc_up: false,
      modal_override: false
    });
    // this.props.deleteDoc(this.props.id);
    // this.deleteForm()
  }

  deleteForm = () => {
    console.log('Implement delete')
  }

  handlerPerName = (event) => {

    let temp_person = this.props.person
    temp_person['name'] = event.target.value

    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_name: true, comp: true })
    } else {
      this.props.setState({ person2: temp_person, comp_name: true, comp: true })
    }

  }

  handlerFisrSurname = (event) => {
    let temp_person = this.props.person
    temp_person['first_surname'] = event.target.value

    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_surname1: true, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp_surname1: true, comp: true })
    }

  }

  handlerSecondSurname = (event) => {
    let temp_person = this.props.person
    temp_person['second_surname'] = event.target.value

    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_surname2: true, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp_surname2: true, comp: true })
    }
  }

  reverseDate = (date) => {
    let old_date = date.split('-').reverse();
    let new_date = old_date.join('-');

    return new_date;
  }

  handlerBirth = (event) => {

    let temp_person = this.props.person
    temp_person['birthdate'] = this.reverseDate(event.target.value)

    this.setState({ new_Date: event.target.value })
    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_birth: true, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp_birth: true, comp: true })
    }
  }

  handlerMoralRFC = (event) => {
    let temp_person = this.props.person
    temp_person['moral_rfc'] = event.target.value
    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp: true })
    }
  }

  handlerGender = (event) => {
    let temp_person = this.props.person

    if (event.value !== undefined) {
      temp_person['gender'] = event.value
    }
    else {
      temp_person['gender'] = event
    }
    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_gender: true, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp_gender: true, comp: true })
    }
  }

  handlerClave = (event) => {
    let temp_person = this.props.person
    temp_person['clave'] = event.target.value
    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_clave: true, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp_clave: true, comp: true })
    }
  }

  handlerSignature = (event) => {
    let temp_person = this.props.person
    temp_person['signature'] = event.target.value
    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_signature: true, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp_signature: true, comp: true })
    }
  }

  handlerNationality = (event) => {
    let temp_person = this.props.person
    temp_person['nationality'] = event.target.value
    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_nationality: true, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp_nationality: true, comp: true })
    }
  }

  handlerCURP = (event) => {
    let temp_person = this.props.person
    if (event.target.value !== "") {
      if (this.validate_CURP(event.target.value)) {
        this.setState({
          valid_curp: true
        })
      }
      else {
        this.setState({
          valid_curp: false
        })
      }
    }
    else {
      this.setState({
        valid_curp: true
      })
    }
    temp_person['curp'] = event.target.value
    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_curp: true, comp: true })
    }
    else {
      this.props.setState({ person2: temp_person, comp_curp: true, comp: true })
    }

  }

  handlerPersonChange = (event, param) => {
    let temp_person = this.props.person
    temp_person[param] = event.target.value

    if (this.props.id === 0) {
      this.props.setState({ person1: temp_person, comp_name: true, comp: true })
    } else {
      this.props.setState({ person2: temp_person, comp_name: true, comp: true })
    }
  }

  loadFileToBase64 = (event) => {
    console.log('Change detected')
    let file;
    let pdf;
    try {
      file = event.target.files[0];
    } catch {
      file = event
    }
    if (file) {
      pdf = file.type === 'application/pdf';
      this.setState({
        image_event: file,
        display_form: ''
      })
      console.log(this.props.person.doc_up)
      if (file.size < 14400 && !pdf) {
        // alert('Por favor suba una imagen con dimensiones mayores a 120 x 120')
        this.props.setState({
          modal: !this.props.params.modal,
          active: this.state.comp_id
        })
      }
      else if (this.props.person.doc_up) {
        // alert('Por favor borre el documento antes de subir otro')

        this.props.setState({
          modal_up: !this.props.params.modal_up,
          active: this.state.comp_id
        })
      }
      else {
        this.reader = new FileReader();
        this.reader.readAsDataURL(file);
        this.reader.onloadend = () => {
          var image = new FormData()

          var mail_img = this.props.params.mail_img

          image.append('image', file)
          image.append('pdf', pdf)

          mail_img.append('img2', file)

          this.setState({
            spinner: '',
            image: this.reader.result,
            file: image

          }, () => {
            this.props.setState({
              img2: this.reader.result,
              mail_img
            })

            this.uploadFile();
          });
        }
      }
    }
  }

  onClick = () => {
    this.setState({
      info: true,
      spinner: 'd-none',
      doc_card_disp: ''
    });
  }

  validate_CURP = (value) => {

    if (value === "") {
      return false
    };

    value = value.replace(/-/g, "");
    value = value.replace(/\s/g, "").toUpperCase();
    var curp_regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      curp_validate = value.match(curp_regex);

    // Validate general format
    if (!curp_validate) {
      return false;
    }

    //Validate check digit
    function checkDigit(curp17) {
      var dictionary = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSum = 0.0,
        lngDigit = 0.0;
      // Separate the first 17 digits (gruop 1) and last digit(group 2)
      for (var i = 0; i < 17; i++)
        lngSum = lngSum + dictionary.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigit = 10 - lngSum % 10;
      if (lngDigit === 10) return 0;
      return lngDigit;
    }

    if (parseInt(curp_validate[2]) !== checkDigit(curp_validate[1])) {
      return false;
    }
    // Correct CURP
    return true;
  }

  uploadFile = () => {

    let url = API_URL + '/api/v1/verify_license'
    let doc = this.state.file
    doc.append('first_name', this.props.params.first_name)
    doc.append('first_surname', this.props.params.last_name)
    axios({
      method: 'POST',
      url,
      data: this.state.file,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then((res) => {

      this.setState({
        spinner: 'd-none',
        filename: res.data.img_name,
      })

      let data = res['data']      

      if ('error' in data) {
        this.inputRef.current.value = ""
        this.props.setState({
          modal_override: !this.state.modal_override,
          error_msg: data['error'],
          img_temp: this.props.params.img2
        });
      }
      else if (data['result'] === false) {

        this.inputRef.current.value = ""

        this.props.setState({
          modal_override: !this.props.params.modal_override,
          error_msg: 'Los datos del documento no concuerdan con el formulario, favor de revisar.',
          img_temp: this.props.params.img2
        })
      }

    })
      .catch((err) => {
        this.deleteDoc();
        this.setState({
          spinner: 'd-none'
        })
        this.props.setState({
          modal_api: true
        })
      });
  }

  toggle = (state) => {
    var stateObj = { [state]: !this.state[state] };
    this.setState(stateObj);
  }

  uploadFileManual = () => {
    var body = new FormData();
    body.append('file', this.state.image_event);
    body.append('filename', this.state.filename);
    body.append('container', 'fisicas');

    // axios({
    //   method: 'POST',
    //   url: API_URL + '/api/v1/upload_document',
    //   data: body,
    //   config: { headers: { 'Content-Type': 'multipart/form-data' } }
    // })
    // .then( (res) => {
    //   if (res.data !== 'OK') {
    //     this.props.setState({
    //       modal: !this.state.modal_text,
    //       modal_text: 'Ocurrión un error. No se pudo guardar el archivo.'
    //     });
    //   } else {
    //     this.toggle('modal_override');
    //   }
    // })
    // .catch( error => {
    //   console.log(error);
    //   this.props.setState({
    //     modal: !this.state.modal_text,
    //     modal_text: 'Ocurrión un error. No se pudo guardar el archivo.'
    //   });
    // })
  }


  render() {

    const { person } = this.props

    return (
      <div>
        <Modal centered={true} isOpen={this.modal_override} toggle={() => this.toggle('modal_override')}>
          <ModalHeader>Advertencia</ModalHeader>
          <ModalBody>
            Se detectó el siguiente problema con el documento: {<b>{this.state.error_msg}</b>}
            {<br></br>}
            Verifica que esta información sea correcta y aprueba o descarta el documento.
            {<br></br>}
            {!this.state.pdf &&
              <img size='xl' src={this.props.person.img} height="100%" width="100%" alt=""></img>
            }
            {/* {this.state.pdf &&
              <img size='xl' src={DOCUMENTS_URL + this.props.person.img_name} height="100%" width="100%"alt=""></img>
            } */}
            <ModalFooter>
              <ButtonPrimary onClick={() => this.uploadFileManual()} title="Aprobar"></ButtonPrimary>
              <ButtonPrimary onClick={() => this.deleteDoc()} title="Descartar"></ButtonPrimary>
            </ModalFooter>
          </ModalBody>
        </Modal>

        <Modal centered={true} isOpen={this.state.image_open} toggle={() => this.toggle('image_open')}>
          {!this.state.pdf &&
            <img size='xl' src={this.props.person.img} height="100%" width="100%" onClick={() => this.toggle('image_open')} alt=""></img>
          }
          {/* {this.state.pdf &&
            <img size='xl' src={DOCUMENTS_URL + this.props.person.img_name} height="100%" width="100%" onClick={() => this.toggle('image_open')} alt=""></img>
          } */}
        </Modal>
        <div className={`cm-card -primary ${this.props.customClasses}`}>
          <div className={"spinnermodal " + this.state.spinner}>
            <Spinner style={{ width: '2rem', height: '2rem' }} />{' '}
          </div>
          <p className="h1-b">{this.props.description}</p>
          <p className="h3-b mb-10">{this.props.title}</p>
          {this.props.required &&
            <div className="chip -warning mb-10">Obligatorio</div>
          }
          {this.state.image !== '' &&
            <div className="mt-xxs">

            </div>
          }
          <p>{this.getDescription()}</p>

          <div>
            <input onChange={this.loadFileToBase64} ref={this.inputRef} type="file" accept=".jpg, .jpeg, .png, .pdf" className="select-file" />
            <form action="" className={`${this.state.display_form}`} style={{ display: this.state.doc_card_disp }}>
              <div className="select-document-form flex-column flex-md-row mt-30">
                {/* {this.state.documentsList.map((type, index) => {
                  return (
                    <React.Fragment>
                      {this.props.person.checked[index] &&
                        <div key={`document-card-${index}-${this.props.id}`} className={`documentwrapper ${this.props.person.checked[index] ? '-selected' : ''}`}>

                          <input checked={this.props.person.checked[index]} onChange={() => this.checkCard(index)} className="radio" type="checkbox" disabled />
                          <div className="checked">
                            <FaCheckCircle />
                          </div>

                          <div className={`documenttype ${type.image}`}></div>
                          <p className="text-center">{type.documentType}</p>
                        </div>
                      }
                    </React.Fragment>
                  )
                })
                } */}

                {this.props.person.doc_up &&
                  <React.Fragment>
                    {!this.state.pdf &&
                      <div style={{ marginRight: "80px" }}>
                        <p><b>Abrir vista previa</b></p>
                        <img src={this.props.person.img} position="center" className="datawrapper flex-column" height="100px" onClick={() => this.toggle('image_open')} alt="" />
                      </div>
                    }
                    {/* {this.state.pdf &&
                              <div style={{ marginRight: "80px" }}>
                                <p><b>Abrir vista previa</b></p>
                                <img src={DOCUMENTS_URL + this.props.person.img_name} position="center" className="datawrapper flex-column" height="100px" onClick={() => this.toggle('image_open')}  alt=""/>
                              </div>
                            } */}
                  </React.Fragment>
                }
              </div>
            </form>
          </div>


          {/* <button className="button -text mt-20" onClick={() => this.toggleCollapseID()}>Llenar de forma manual <i class="fas fa-angle-down"></i></button>
          <Collapse isOpen={this.state.openCollapse}>

            <>
              <div className="datawrapper flex-column">
                <InputForm inputColor="-secondary" borderColor={this.props.params.name_color} label="Nombre(s)" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" onChange={this.handlerPerName} type="text" value={person.name} />
                <InputForm inputColor="-secondary" borderColor={this.props.params.surname1_color} label="Apellido Paterno" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" onChange={this.handlerFisrSurname} type="text" value={person.first_surname} />
                <InputForm inputColor="-secondary" borderColor={this.props.params.surname2_color} label="Apellido Materno" customClasses="mt-50" warning="Datos no válidos" requiredText="Requerido" onChange={this.handlerSecondSurname} type="text" value={person.second_surname} />
              </div>

            </>
          </Collapse> */}
        </div>
      </div>
    )
  }
}

export default DocumentCardLicense