import React, { Component } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL

class PayMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  delete_card = () => {
    if(this.props.type){
      this.props._delete(this.props.index)
      return
    }
    let url = `${API_URL}/api/v1/users/remove_cards?group_id=${localStorage.getItem("group_id")}&card_id=${this.props.card_id}`

    axios.get(url)
      .then(res => {
        window.location.reload() 
        // this.paginate(res.data)
      }, error => {
        return Promise.reject(error)
      })
  }

  render() {
    return (
      <div className={`pay-method ${this.props.cardColor}`}>
        <p className="mb-0">{this.props.cardName}</p>
        <button className="button -icon ml-auto" onClick={this.delete_card}><i class="fas fa-trash-alt m-0"></i></button>
      </div>
    );
  }
}

export default PayMethod;