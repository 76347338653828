import React, { Component } from 'react';

class SideNav extends Component {
  render() {
    return (
      <div className="sidenav">
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="/seller">
              <i className="fas fa-home"></i>
              <p>Inicio</p>
            </a>
          </li>
          {(localStorage.getItem("role") == "admin" || localStorage.getItem("role") == "agency") && <li className="nav-item">
            <a className="nav-link" href="/add-car">
              <i className="fas fa-plus"></i>
              <p>Agregar</p>
            </a>
          </li> }
          <li className="nav-item">
            <a className="nav-link" href="/seller-catalog">
              <i className="fas fa-search"></i>
              <p>Cátalogo</p>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/seller-admin">
              <i className="fas fa-users"></i>
              <p>Vendedores</p>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default SideNav;