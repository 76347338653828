import React, { Component } from 'react';

class SimpleCard extends Component {
  render() {
    return (
      <div className={`SimpleCard ${this.props.className}`}>
        <img className="image-card" src={this.props.image} alt=""/>
        <div className="info-wrapper">
          <p className="h3-b text-center">{this.props.title}</p>
          <p className="body-r text-center">{this.props.text}</p>
        </div>
      </div>
    );
  }
}

export default SimpleCard;