import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {NavLink} from 'reactstrap';

class CarCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      full_name:''
    }
  }

  redirectToDetail = () =>{
    this.setState({redirect: true})
  }
  getShortName = (full_name) =>{
    return  full_name
  }

  render() {
    return (
      <div className="car-card">
        <input type="hidden" value={this.props.vin}/>
        {this.state.redirect &&
          <Redirect to={'/car-detail/'+this.props.name+"/"+this.props.model+"/"+this.props.version}></Redirect>
        }
        <div className="row mx-0">
          <div className="col-12 px-0">
            <div className="image-wrapper">
              <img src={this.props.image} alt=""/>
            </div>
            <p className="h4-b car-name my-xs">{this.getShortName(this.props.version)}</p>
            <div className={`car-info ${this.props.className}`}>
              <i class="fas fa-money-bill"></i>
              <p className="body-r price">{this.props.price}</p>
              <p className="body-r">{this.props.offer}</p>
            </div>
            {/* <div className="car-info">
              <i class="fas fa-users"></i>
              <p className="body-r">{this.props.passengers}</p>
            </div> */}
            <div className="car-info">
              <i class="fas fa-road"></i>
              <p className="body-r">{this.props.kilometers}</p>
            </div>
            {/* <div className="car-info -line">
              <i class="fas fa-car pr-4"></i>
              <p className="body-r">{this.props.units}</p>
            </div> */}
            <NavLink href={'/car-detail/'+this.props.name+"/"+this.props.model+"/"+this.props.version}>
            <button className="button -tertiary" >Ver auto</button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default CarCard;