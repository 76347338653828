import React, { Component } from 'react';

class CarRecap extends Component {
  render() {
    return (
      <div className="car-recap">
        <div className="row">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <p className="h3-b">{`${this.props.brand} ${this.props.model} ${this.props.version} - ${this.props.model_year}`}</p>
            <ul>
              {/* <li className="car-features">Motor: {this.props.motor}</li>
              <li className="car-features">Rendimiento: {this.props.performance}</li>
              <li className="car-features">Transmisión: {this.props.transmission}</li>
              <li className="car-features">Potencia: {this.props.power}</li>
              <li className="car-features">Capacidad: {this.props.capacity}</li> */}
              <li className="car-features"><h4>Precio: {"$" + this.props.characteristics.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4></li>
              <li className="car-features">Color: {this.props.color}</li> 
            </ul>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center">
            <img className="w-100" src={this.props.img} alt=""/>
          </div>
        </div>
      </div>
    );
  }
}

export default CarRecap;