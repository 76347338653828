import React, { Component } from 'react';

import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import Footer from '../../Components/Footer/Footer';
import Paginator from '../../Components/Paginator/Paginator';
import ModalDeleteSeller from '../../Components/Modals/ModalDeleteSeller';
import axios from 'axios';
import { Redirect } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal_delete_seller: false,
      users: [],
      redirect:false,
      user_id:''
    }
  }

  editUser = (user_id) => {
    console.log(user_id)
    this.setState({'user_id':user_id, redirect:true})
  }

  componentDidMount = () => {
    let url = `${API_URL}/api/v1/users/get`

    axios.get(url)
      .then(res => {
        this.setState({
          users: res.data
        })
      }, error => {
        return Promise.reject(error)
      })
  }
  toggle= () => {
    this.setState({modal_delete_seller: !this.state.modal_delete_seller})
  }

  render() {
    return (
      <div className="add-car">
        {this.state.redirect &&
          <Redirect to={'/seller-edit/'+this.state.user_id}></Redirect>
        }
        <ModalDeleteSeller
          toggle={this.toggle}
          modal={this.state.modal_delete_seller}
          title="Eliminar vendedor"
          seller="Evert Orn Kali Schimmel"
        />
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="container-fluid mt-top min-height d-flex px-0">
            <SideNav></SideNav>

            <div className="container">
              <div className="row mx-0 mt-xl">
                <div className="col-12 px-0">
                  <p className="h1-b">Administrar vendedores</p>
                  <p className="body-r">Aquí podrás dar de alta y eliminar vendedores para el uso de la plataforma</p>
                </div>
              </div>

              {(localStorage.getItem("role") == "admin" || localStorage.getItem("role") == "agency") &&
                <div className="row">
                <div className="col-12">
                  <a href="seller-create"><button className="button -primary"><i class="fas fa-user-plus"></i> Agregar vendedor</button></a>
                </div>
              </div>}

              <div className="row mt-xl">
                <div className="col-12">
                  <table>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Correo electrónico</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.users.map((user) => {
                        return(
                          <tr>
                            <td>{user.first_name} {user.last_name}</td>
                            <td>{user.email}</td>
                            <td>
                              <button onClick={() => this.editUser(user.user_id)} className="button -icon">
                                <i className="fas fa-pencil-alt mx-0"></i>
                              </button>
                              <button onClick={this.toggle} className="button -icon">
                                <i className="fas fa-trash-alt mx-0 delete"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mt-xl">
                <div className="col-12">
                  <Paginator/> 
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer></Footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateUser;