import React, { Component } from 'react';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent';
import HomeBubbles from '../Components/HomeBubbles/HomeBubbles';
import SimpleCard from '../Components/SimpleCard/SimpleCard';
import Footer from '../Components/Footer/Footer';
import NumberFormat from 'react-number-format';

import Step1 from '../Image/Home/Step1.png';
import Step2 from '../Image/Home/Step2.png';
import Step3 from '../Image/Home/Step3.png';
import Step4 from '../Image/Home/Step4.png';
import Card1 from '../Image/Home/Card1.jpg';
import Card2 from '../Image/Home/Card2.jpg';
import Card3 from '../Image/Home/Card3.jpg';
import Card4 from '../Image/Home/Card4.jpg';
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

class HomePage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      months: '',
      cost: '',
      initial_payment: '',
      monthly_payment: ''
    }
  }

  handlerCost = (event) => {
    this.setState({ cost: event.floatValue }, () => { if (this.state.cost != '' && this.state.months != '' && this.state.initial_payment != '') this.handlerPayment() })
  }
  handlerMonths = (event) => {
    this.setState({ months: event.target.value }, () => { if (this.state.cost != '' && this.state.months != '' && this.state.initial_payment != '') this.handlerPayment() })
  }
  handlerInitial = (event) => {
    this.setState({ initial_payment: event.floatValue }, () => { if (this.state.cost != '' && this.state.months != '' && this.state.initial_payment != '') this.handlerPayment() })
  }

  handlerPayment() {
    let url = `${API_URL}/api/v1/cars/monthly_payments?cost=${this.state.cost}&months=${this.state.months}&initial_payment=${this.state.initial_payment}`
    axios.get(url)
      .then(res => {
        this.setState({ monthly_payment: res.data })
      }, error => {
        return Promise.reject(error)
      })
  }

  render() {
    return (
      <div className="home-page">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="row mx-0">
            <div className="col-12 px-0">
              <div className="homeHero mt-top d-flex justify-content-end">
                <div className="col-12 col-md-6 col-lg-4 px-0 d-flex justify-content-center flex-column">
                  <p className="title -white text-center text-md-right">LA MEJOR PLATAFORMA EN LÍNEA PARA COMPRAR TU AUTO.</p>
                  <p className="h3-r -white text-center text-md-right">Encuentra, cotiza y compra tu próximo auto en cualquier lugar, asegúrate de tener siempre los precios y las promociones más recientes.</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mx-0 bg-black">
            <div className="col-12">
              <p className="body-r text-center c-white my-xxs">Información <b>COVID-19:</b> Para la seguridad y el bienestar de todos, hicimos nuestros servicios <b>100% de forma remota</b>. Aprende más sobre nuestras medidas de seguridad.</p>
            </div>
          </div> */}
        </div>

        <div className="container-fluid px-0">
          <div className="home-price my-xxxl">
            <div className="container">
              <div className="row mx-0" id="oferta">
                <div className="col-12 col-lg-5">
                  <p className="h1-b">Haz una oferta con pagos acomodados a tu medida.</p>
                  <p className="h3-r">Con Grupo Continental tú puedes hacer una oferta por el tiempo que quieres pagar, con el dinero que puedes pagar.</p>
                </div>
              </div>
              <div className="row mx-0 mt-l">
                <div className="col-12">
                  <p className="h2-r">Calcula pagos a mensualidades</p>
                </div>
              </div>

              <div className="home-recommendation">
                <div className="row mx-0 mt-xs">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group mb-0 mt-m">
                      <label htmlFor="">Precio del automóvil</label>
                      <NumberFormat
                        name="price"
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        placeholder="Ej. $200,000"
                        onValueChange={this.handlerCost} />
                      {/* <input type="number"
                              name="cost"
                              onChange={this.handlerCost}
                              placeholder="Ej. 50,000" /> */}
                      <div className="required">Mín $110,000</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group mb-0 mt-m">
                      <label htmlFor="">Enganche</label>
                      <NumberFormat
                        name="initial payment"
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        placeholder="Ej. $50,000"
                        onValueChange={this.handlerInitial} />
                      {/* <input type="number"
                            name="initial_payment"
                            onChange={this.handlerInitial}
                            placeholder="Ej. 50,000" /> */}
                      <div className="required">Mín $30,000</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group mb-0 mt-m">
                      <label htmlFor="">Meses a pagar:</label>
                      <select name="months"
                        id=""
                        value={this.state.months}
                        onChange={this.handlerMonths}
                      >
                        <option value="" selected disabled>Seleccionar</option>
                        <option value="12">12 meses</option>
                        <option value="18">18 meses</option>
                        <option value="24">24 meses</option>
                        <option value="36">36 meses</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group mb-0 mt-m">
                      <label htmlFor="">Mensualidades de:</label>
                      <p>${this.state.monthly_payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center mt-m">
                <div className="col-12 d-flex justify-content-center">
                  <a href={`/catalog/${this.state.cost}`} className="detailoption"><button className="button -primary">Ver recomendaciones</button></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col-12 col-md-6 px-0 d-flex">
              <div className="button catalog-btn -catalog w-100 d-flex flex-column align-items-center justify-content-center">
                <p className="h2-b -white">CATÁLOGO DE AUTOS</p>
                <p className="h3-r -white text-center">Ya se que auto quiero! Ir directo al catálogo!</p>
                <a href="/catalog" className="detailoption"><button className="button -secondary -white">Ir a catálogo</button></a>
              </div>
            </div>
            <div className="col-12 col-md-6 px-0 d-flex">
              <div className="button catalog-btn -recommend w-100 d-flex flex-column align-items-center justify-content-center">
                <p className="h2-b -white">RECOMENDACIONES</p>
                <p className="h3-r -white text-center">No sabes qué auto comprar? Nosotros te recomendamos!</p>
                <a href="/recommendations" className="detailoption"><button className="button -secondary -white">Ver recomendaciones</button></a>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-xxxl">
            <div className="col-12">
              <p className="h1-b text-center mb-l">¿Cómo funciona la plataforma de Autos Seminuevos de Grupo Continental?</p>
            </div>
          </div>
          <div className="row d-flex flex-md-row">
            <div className="col-12 col-md-6 col-lg-4">
              <a href="/recommendations">
                <HomeBubbles
                  image={Step1}
                  text="Encuentra tu auto ideal."
                />
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <a href="/catalog">
                <HomeBubbles
                  image={Step2}
                  text="Cotiza tu auto con enganches y mensualidades."
                />
              </a>
            </div>
            {/* <div className="col-12 col-md-6 col-lg-3">
              <HomeBubbles
                image={Step3}
                text="Sube y revisa tus documentos en un solo lugar."
              />
            </div> */}
            <div className="col-12 col-md-6 col-lg-4">
              <a href="#oferta">
                <HomeBubbles
                  image={Step4}
                  text="Recibe o recoge tu automóvil y estrena en menos tiempo que nunca."
                />
              </a>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-xxxl">
            <div className="col-12">
              <p className="h1-b text-center mb-l">Nuestros beneficios</p>
            </div>
          </div>
          <div className="row d-flex flex-md-row">
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <a href="/recommendations">
                <SimpleCard
                  image={Card1}
                  title="Recomendación de Autos"
                  text="Si aún no sabes que auto comprar, nosotros te ayudamos dándote recomendaciones que se ajusten a tus necesidades."
                />
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <a href="/catalog">
                <SimpleCard
                  image={Card2}
                  title="Consulta en inventario"
                  text="Conoce la disponibilidad de tu auto, y no pierdas tiempo a la espera de la entrega."
                />
              </a>
            </div>
            {/* <div className="col-12 col-md-6 col-lg-3 d-flex">
              <SimpleCard
                image={Card3}
                title="Gestor de Documentos"
                text="Llevaremos tu proceso 100% en linea, esto garantiza mayor velocidad."
              />
            </div> */}
            <div className="col-12 col-md-6 col-lg-4 d-flex">
              <a href="mailto:seminuevos@autopolanco.com.mx">
                <SimpleCard
                  image={Card4}
                  title="Asesores especializados"
                  text="Estaremos en contacto en todo momento de tu proceso."
                />
              </a>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">

          <Footer
            className="mt-l"
          />
        </div>
      </div>
    );
  }
}

export default HomePage;