import React, { Component } from 'react';

import CreatableSelect from "react-select";
// import { colourOptions } from '../data';

export default class CreatableMulti extends Component<*, State> {

  constructor(props){
    super(props);
  }

  handleChange = (newValue: any, actionMeta: any) => {
    if (this.props.type === "organizations") {
      if (newValue === null) {
        newValue = [];
      }
      this.props.setState({
        current_organizations: newValue
      })
    }
    else if (this.props.type === "roles") {
      if (newValue === null) {
        newValue = [];
      }
      this.props.setState({
        current_roles: newValue
      })
    }
    else if (this.props.type === "agent"){
      if (newValue === null) {
        newValue = [];
      }
      this.props.setState({
        current_person: newValue
      })
    }
    else {
      this.props.setState({
        person_categories: newValue
      })
    }
  };

  render() {
    return (
      <CreatableSelect
        isMulti
        value = {this.props.selected}
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        options={this.props.categories}
      />
    );
  }
}