import React, { Component } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

class SearchBar extends Component {
  // handlerGeneralFilter = (event) => {
  //   let url = `${API_URL}/api/v1/cars/general_filter?text=${event.target.value}`

  //   this.props.setState({
  //     current_page: 1
  //   }, () => {

  //     axios.get(url).then(res => {
  //       this.props.setState2({
  //         cars: res.data.cars,
  //         model_options: res.data.model_filters,
  //         year_options: res.data.year_filters,
  //         color_options: res.data.color_filters
  //       })
  //     })
  //   })
  // }

  render() {
    return (
      <div className={`search-bar ${this.props.className}`}>
        <div className="row mx-0 w-100">
          <div className="col-12 col-lg-8 d-flex">
            <div className="form-group m-0 w-100">
              <input className="search" placeholder="Buscar por marca, modelo" type="text" onChange={this.props.handlerGeneralFilter} />
            </div>
            <button className="button -primary">
              <i class="fas fa-search"></i>
            </button>
          </div>
          <div className="col-2 d-none d-lg-flex">
            <a target="_blank" href="http://35.222.144.183/api/v1/cars/pdf" download>
               <button className="button -tertiary d-flex">
              <i className="fas fa-file"></i>Descargar Catálogo
            </button>
            </a>

          </div>
          <div className="col-2 d-none d-lg-flex">
            <button className="button -tertiary d-flex" onClick={() => this.props.clean()}>
              <i class="fas fa-eraser"></i>Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBar;
