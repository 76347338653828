import React, { Component } from 'react';
import DropdownArrow from '../../Image/svg/dropdown-arrow.svg';
import NumberFormat from 'react-number-format';


class FilterBar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      price_filter: '-open',
      brand_filter: '-open',
      model_filter: '-open',
      location_filter: '-open',
      color_filter: '',
      year_filger: '',
      doors_filter: '',
      checked_price: -1,
      min_price: this.props.min_price,
      max_price: this.props.min_price
    }
  }

  openPriceFilter = () => {

    let temp = ''

    if (this.state.price_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ price_filter: temp })
  }
  openBrandFilter = () => {

    let temp = ''

    if (this.state.brand_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ brand_filter: temp })
  }
  openLocationFilter = () => {

    let temp = ''

    if (this.state.location_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ location_filter: temp })
  }
  openmodelFilter = () => {

    let temp = ''

    if (this.state.model_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ model_filter: temp })
  }
  opencolorFilter = () => {

    let temp = ''

    if (this.state.color_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ color_filter: temp })
  }
  openyearFilter = () => {

    let temp = ''

    if (this.state.year_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ year_filter: temp })
  }
  opendoorsFilter = () => {

    let temp = ''

    if (this.state.doors_filter == '') { temp = '-open' }
    else { temp = '' }

    this.setState({ 
      doors_filter: temp,
    })
  }

  /* handlerPrice = (event) => {
    let current = this.props.data.price_brackets[(event.target.value)]

    this.props.setState({
      min_price: current.min,
      max_price: current.max,
      checked_price:event.target.value
    })
  } */
  handlerMaxPrice = (event) => {
    this.setState({
      max_price: event.floatValue
    })
    this.setDefaults()
    this.props.setState({
      max_price: event.floatValue
    }, ()=>{
      if(this.props.min_price!==''&&this.props.max_price!==''){
        this.props.searchCars()
        this.props.calculate_filters()
      }
    })
  }

  handlerMinPrice = (event) => {
    this.setState({
      min_price: event.floatValue
    })
    this.setDefaults()
    this.props.setState({
      min_price: event.floatValue
    },()=>{
      if(this.props.min_price!==''&&this.props.max_price!==''){
        this.props.searchCars()
        this.props.calculate_filters()
      }
    })
  }

  setDefaults = () =>{
    if(this.state.min_price==="" || this.state.min_price==="undefined"){this.state.setState({min_price:0})}
    if(this.state.max_price==="" || this.state.max_price==="undefined"){this.state.setState({max_price:999999999})}

  }

  handlerBrand = (event) => { 
    this.props.setState({
      brand: event.target.id,
      model: '',
      color: '',
      year: '',
      checked_model: -1,
      checked_year: -1,
      checked_brand:event.target.value,
      exclude: 'brand'
    }, true)
  }
  handlerLocation = (event) => {
    this.props.setState({
      location: event.target.id,
      color: '',
      year: '',
      checked_year: -1,
      checked_location:event.target.value,
    }, true)
  }
  handlerModel = (event) => { 
    this.props.setState({
      model: event.target.id,
      color: '',
      year: '',
      checked_year: -1,
      checked_model:event.target.value, 
      exclude: 'model'
    })
  }

  handlerColor = (event) => { 
    this.props.setState({
      color: event.target.value, 
      year: '',
      checked_year: -1,
      exclude: 'color'
    })
  }

  handlerYear = (event) => {
    this.props.setState({
      year: event.target.id,
      color: '',
      checked_year:event.target.value, 
      exclude: 'model_year'
    })
  }

  render() {
    return (
      <div className="filter-bar">
        <button className={`dropdown ${this.state.price_filter}`} >
          <div className="dropdown-title" onClick={() => this.openPriceFilter()}>
            <p className="h3-m mb-0">Precio</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>

          <div className="dropdown-content">
          <hr className="mt-0"></hr>
              <div className="form-group d-flex flex-column align-items-start">
                <label htmlFor="">Mínimo</label>
                <NumberFormat
                          name="min_price"
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          placeholder="Ej. $50,000"
                          value={this.props.data.min_price}
                          onValueChange={this.handlerMinPrice} />
              </div>
                        
            <div className="form-group d-flex flex-column align-items-start">
              <label htmlFor="">Máximo</label>
              <NumberFormat
                      name="max_price"
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      placeholder="Ej. $1,000,000"
                      value = {this.props.data.max_price}
                      onValueChange={this.handlerMaxPrice} />
              {/* <input type="text mb-0" placeholder="Ej. 1,000,000" onChange={this.handlerMaxPrice} /> */}
            </div>
        </div>
        </button>

        <hr className="mt-0"></hr>

        <button className={`dropdown ${this.state.brand_filter}`}>
          <div className="dropdown-title" onClick={() => this.openBrandFilter()}>
            <p className="h3-m mb-0">Marca</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>
          <div className="dropdown-content">
          {(this.props.data) &&
            (this.props.data.brand_options.map((item,i) => {
                return (
                  <div className="form-group">
                    <label className="checkbox" for={item._id}>{item._id ? item._id: 'Todos'}</label>
                    <input className="checkbox" type="checkbox" id={item._id} value={i} name={"brand"+i} onChange={this.handlerBrand} checked={this.props.data.checked_brand == i}></input>
                  </div>
                )
              }))
          }
          </div>
        </button>

        <hr className="mt-0"></hr>

        <button className={`dropdown ${this.state.model_filter}`} >
          <div className="dropdown-title" onClick={() => this.openmodelFilter()}>
            <p className="h3-m mb-0">Modelo</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>
          <div className="dropdown-content">
          {(this.props.data) &&
            (this.props.data.model_options.map((item,i) => {
                return (
                  <div className="form-group">
                    <label className="checkbox" for={item._id}>{item._id ? item._id: 'Todos'}</label>
                    <input className="checkbox" type="checkbox" id={item._id} value={i} name={"model"+i} onChange={this.handlerModel} checked={this.props.data.checked_model == i}></input>
                  </div>
                )
              }))
          }
          </div>
        </button>
        <button className={`dropdown ${this.state.location_filter}`}>
          <div className="dropdown-title" onClick={() => this.openLocationFilter()}>
            <p className="h3-m mb-0">Ubicación</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt=""/>
          </div>
          <div className="dropdown-content">
            {(this.props.data) &&
            (this.props.data.location_options.map((item,i) => {
                return (
                  <div className="form-group">
                    <label className="checkbox" for={item._id}>{item._id ? item._id: 'Todos'}</label>
                    <input className="checkbox" type="checkbox" id={item._id} value={i} name={"brand"+i} onChange={this.handlerLocation} checked={this.props.data.checked_location == i}></input>
                  </div>
                )
              }))
          }
          </div>
        </button>
        {/* <hr className="mt-0"></hr> */}
        {/* <button className={`dropdown ${this.state.color_filter}`}>
          <div className="dropdown-title" onClick={() => this.opencolorFilter()}>
            <p className="h3-m mb-0">Color</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>
          <div className="dropdown-content">
            <div className="form-group">
              <input className="checkbox" type="radio" id="rojo" value="rojo" name="color" onClick={this.handlerColor}></input>
              <label className="checkbox" htmlFor="">Rojo</label>
            </div>
            <div className="form-group">
              <input className="checkbox" type="radio" id="blanco" value="blanco" name="color" onClick={this.handlerColor}></input>
              <label className="checkbox" htmlFor="">Blanco</label>
            </div>
            <div className="form-group">
              <input className="checkbox" type="radio" id="negro" value="negro" name="color" onClick={this.handlerColor}></input>
              <label className="checkbox" htmlFor="">Negro</label>
            </div>
          </div>
        </button> */}

        <hr className="mt-0"></hr>

        {/* <button className={`dropdown ${this.state.year_filter}`}>
          <div className="dropdown-title" onClick={() => this.openyearFilter()}>
            <p className="h3-m mb-0">Año</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt="" />
          </div>
          <div className="dropdown-content">
          {(this.props.data) &&
            (this.props.data.year_options.map((item,i) => {
                return (
                  <div className="form-group">
                    <input className="checkbox" type="radio" id={item._id} value={i} name={"year"+i} onChange={this.handlerYear} checked={this.props.data.checked_year == i}></input>
                    <label className="checkbox" for={item._id}>{item._id}</label>
                  </div>
                )
              }))
          }
          </div>
        </button>

        <hr className="mt-0"></hr> */}

        {/* <button className={`dropdown ${this.state.doors_filter}`} >
          <div className="dropdown-title" onClick={()=>this.opendoorsFilter()}>
            <p className="h3-m mb-0">Puertas</p>
            <img className="dropdown-arrow" src={DropdownArrow} alt=""/>
          </div>
          <div className="dropdown-content">
            <div className="form-group">
              <input className="checkbox" type="checkbox"></input>
              <label className="checkbox" htmlFor="">2 puertas</label>
            </div>
            <div className="form-group">
              <input className="checkbox" type="checkbox"></input>
              <label className="checkbox" htmlFor="">4 puertas</label>
            </div>
          </div>
        </button> */}

        {/* <hr className="mt-0"></hr> */}

      </div>
    );
  }
}

export default FilterBar;
