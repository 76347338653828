import React, { Component } from 'react';
import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';

import { Redirect } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL
class SellerPayRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  componentDidMount = () =>{
    let url = `${API_URL}/api/v1/users/payment_history?group_id=${localStorage.getItem("group_id")}`
    let agencies = []
    axios.get(url)
    .then(res => {
      this.setState({history:res.data})
    })
  }

  render() {
    return (
      <div className="seller-profile">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="container-fluid mt-top min-height d-flex px-0">
          {this.state.redirect &&
            <Redirect to={'/seller-admin'}></Redirect>
          }
            <SideNav></SideNav>

            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="save-changes-responsive">
                    <button className="button -primary">Guardar cambios</button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                    <p className="h1-b mt-xl">Historial de pagos</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <table>
                      <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Autos registrados</th>
                        <th>Agencias registradss</th>
                        <th>Total</th>
                      </tr>
                      </thead>
                    {this.state.history &&
                      this.state.history.map((history) =>{
                        return(
                        <tbody>
                          <tr>
                            <td>{history.operation_date}</td>
                            <td>{history.cars} autos registrados</td>
                            <td>{history.agencies} agencias registradas</td>
                            <td>${history.amount}</td>
                          </tr>
                        </tbody>)
                      })
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-0">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default SellerPayRecord;