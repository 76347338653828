import React, { Component } from 'react';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent';
import Footer from '../Components/Footer/Footer';
import { Redirect } from 'react-router-dom';
import {NavLink} from 'reactstrap';

class SearchSelect extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      redirect_catalog: false,
      redirect_recom: false
    }
  }

  redirect = (page) =>{

    if (page == "catalog" ){

      this.setState({redirect_catalog: true})
    }
    else {
      this.setState({redirect_recom: true})
    }
  }

  render() {
    return (
      <div className="search-select">
        {this.state.redirect_catalog &&
          <Redirect to="/catalog"></Redirect>
        }
        {this.state.redirect_recom &&
          <Redirect to="/recommendations"></Redirect>
        }
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="selector">
            <div className="row mx-0 mt-top min-height h-100">
              <div className="col-12 col-md-6 px-0">
                <div className="select-btn -catalog">
                  <p className="h1-b -white">CATÁLOGO</p>
                  <p className="h2-r -white" >Visita nuestro catálogo de autos.</p>
                  <NavLink href="/catalog">
                  <button className="button -secondary -white">VER CATÁLOGO</button>
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-md-6 px-0">
                <div className="select-btn -recommend">
                  <p className="h1-b -white">RECOMENDACIONES</p>
                  <p className="h2-r -white">Si aún no sabes que auto comprar, nosotros te ayudamos a tomar una decisión.</p>
                   <NavLink href="/recommendations">
                  <button className="button -secondary -white" onClick={()=>this.redirect("recom")} >VER RECOMENDACIONES</button>
                   </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <Footer></Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchSelect;