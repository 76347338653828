import React, { Component } from 'react';
import CarImg  from '../../../Image/car-detail/car.png';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'reactstrap';

const API_URL = process.env.REACT_APP_API_URL

class SellerCarCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle:'none',
      edit_redirect:false,
      publish_text:'Dar de baja'
    }
  }
  componentDidMount = () =>{
    let publish = this.props.publish
    if(!publish){
      this.setState({publish_text:'Dar de alta'})
    }
  }
  collapse = () =>{
    this.setState({toggle:'none'})
  }
  use_toggle = () => {
    let toggle = 'none'
    if(this.state.toggle=='none'){
      toggle = 'show'
    }
    this.setState({toggle})
  }
  edit = () => {this.setState({edit_redirect:true})}
  redirectToDetail = () =>{
    this.setState({redirect: true})
  }

  depublish = () => {
    let url = API_URL + '/api/v1/cars/publish'
    let data = new FormData()
    data.append('car_id',this.props.car_id)
    data.append('publish',this.props.publish)
    axios({
      method: 'POST',
      url,
      data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then((res) => {
      console.log(res)
      this.setState({toggle:'none'})
      window.location.reload();
    })
  }

  delete = () =>{
    let url = API_URL + '/api/v1/cars/delete'
    let data = new FormData()
    data.append('car_id',this.props.car_id)
    axios({
      method: 'POST',
      url,
      data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then((res) => {
      window.location.reload();
    })
  }

  render() {
    return (
      <div className="seller-car-card">
        {this.state.redirect &&
          <Redirect to={'/car-detail/'+this.props.brand+"/"+this.props.model+"/"+this.props.version}></Redirect>
        }
        {this.state.edit_redirect &&
          <Redirect to={'/edit-car/'+this.props.brand+"/"+this.props.model+"/"+this.props.version}></Redirect>
        }
        
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button className="button -dropdown" onClick={this.use_toggle} >
              <i class="fas fa-ellipsis-h"></i>
            </button>
          </div>
          <div className="col-12 col-lg-3 d-flex align-items-center">
            <img className="car-image" src={this.props.image} alt=""/>
          </div>
          <div className="col-12 col-lg-9">
            <p className="name">{`${this.props.brand} ${this.props.model} ${this.props.model_year} ${this.props.version}`}</p>
            <div className="info-wrapper">
              <div className="car-info">
                <i class="fas fa-money-bill"></i>
                <p className="price">{this.props.cost}</p>
              </div>
              <div className="car-info">
                <i class="fas fa-users"></i>
                <p className="pasengers">{this.state.capacity} pasajeros</p>
              </div>
              <div className="car-info">
                <i class="fas fa-road"></i>
                <p className="body-r">{this.props.kilometers}</p>
              </div>
              {/* <div className="car-info">
                <i class="fas fa-car"></i>
                <p className="units">{this.props.stock} disponibles</p>
              </div> */}
              {/* <div className="car-info">
                <p className="published">Publicado el: {this.props.entry_date}</p>
              </div> */}
              {/* <div className="car-info">
                <p className="visits">Visitas en el último mes: 123</p>
              </div>
              <div className="car-info">
                <p className="quotation">Solicitudes de cotización: 12</p>
              </div> */}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <button className="button -tertiary mt-xs car-card-btn" onClick={()=>this.redirectToDetail()} >Ver auto</button>
          </div>
        </div>


        {localStorage.getItem("role") == "admin" &&
        <div className={`dropdown-actions -ellipsis d-${this.state.toggle}`}>
          <a className="-line" onClick={this.delete}>
            Eliminar
          </a>
          <a className="-line" onClick={this.depublish}>
            {this.state.publish_text}
          </a>
          <a href={'/edit-car/'+this.props.brand+"/"+this.props.model+"/"+this.props.version}>
            Editar
          </a>
        </div>
}      </div>
    );
  }
}

export default SellerCarCard;