import React, { Component } from 'react';
import Gal1 from '../../Image/car-detail/Gallery-1.jpg'
import Gal2 from '../../Image/car-detail/gallery-2.jpg'
import Gal3 from '../../Image/car-detail/gallery-3.jpg'
import Gal4 from '../../Image/car-detail/gallery-4.jpg'
import Gal5 from '../../Image/car-detail/gallery-5.jpg'
import Gal6 from '../../Image/car-detail/gallery-6.jpg'


class CarDetailGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0
    }
  }

  selecImage = (index) =>{
    this.setState({
      current: index
    })
  }

  render() {
    return (
      <div className="car-detail-gallery">
        {this.props.pictures.length > 0 &&
          <>
            <img className="mainimage" src={this.props.pictures[this.state.current]} alt="" />
            <div className="imagewrapper">

              {this.props.pictures.map((picture, index) => {
                return (
                  <img className="imagedetail" src={picture} alt="" onClick={()=>this.selecImage(index)}/>
                )
              })}
            </div>
          </>
        }
      </div>
    );
  }
}

export default CarDetailGallery;