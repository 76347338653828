import React, { Component } from 'react';
import Footer from '../../Components/Footer/Footer';
import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import PayMethod from '../../Components/SellerComponents/PayMethod/PayMethod';
import RegisterImg from '../../Image/Register/register.png'
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ButtonPrimary } from '../../Components/Button/Button';

const re_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const re_phone = /^\d+$/;
const API_URL = process.env.REACT_APP_API_URL

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      validEmail:false,
      agencies:[],
      agency_name:'',
      agency_phone:'',
      address:'',
      modal:false    
    }
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  generatePassword() {
    var length = 4;
    var charset = "abcdefghijklmnopqrstuvwxyz";
    var retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    charset = "0123456789";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  create_user = () =>{
    let s = this.state
    s.password = this.generatePassword()
    let url = API_URL + '/api/v1/organization/create'
    axios({
      method: 'POST',
      url,
      data: s,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then((res) => {
      console.log(res.data)
      if(res.data.error){
        this.setState({'modal_text':res.data.error})
        this.toggleModal()
      }
    })
  }

  agencyCount = () =>{
    let agencies = this.state.agencies
    let size = agencies.length
    let agency_size = this.state.agency_size
    if(size<agency_size){
      let difference = agency_size - size
      for(let i=0;i<difference;i++){
        agencies.push({'name':'','phone':'','address':''})
      }
    } else if(size>agency_size){
      agencies = agencies.splice(agency_size)
    }
    this.setState({agencies})
  }

  addAgency = () => {
    let agencies = this.state.agencies
    agencies.push(this.state.agency_name)
    this.setState({
      agencies,
      agency_name:'',
      agency_phone:'',
      address:''
    })
    
  }

  validateEmail = () => {
    if (!re_email.test(this.state.email)) {
      this.setState({
        warningEmail: " -warning",
        messageEmail: "Correo no válido.",
        validEmail: false,
        invalid: true
      })
    }
    else {
      this.setState({
        warningEmail: "",
        messageEmail: "Correo no válido.",
        validEmail: true
      })
    }
  }

  onChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }  

  validatePhone = () => {
    if (!re_phone.test(this.state.phone)) {
      this.setState({
        warningPhone: " -warning",
        validPhone: false,
        invalid: true
      })
    }
    else {
      this.setState({
        warningPhone: "",
        validPhone: true
      })
    }
  }

  _delete = (index) => {
    let agencies = this.state.agencies
    agencies.splice(index, 1)
    this.setState({agencies})
  }

  render() {
    return (
      <div className="register-page">
        <Modal centered={true} isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}>
          <ModalBody>
            {this.state.modal_text}
          </ModalBody>
          <ModalFooter>
            <ButtonPrimary onClick={() => this.toggleModal()} title="Regresar"></ButtonPrimary>
          </ModalFooter>
        </Modal>
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="row mx-0">
            <div className="col-12 px-0">
              <div className="container mt-top min-height">
                <div className="row">
                  <div className="col-12">
                    <p className="h2-b mt-xl">Registro</p>
                    <p className="h3-r">Regístrate para poder empezar a vender tus autos</p>
                  </div>
                </div>

                <div className="row mt-l">
                  <div className="col-12 col-lg-7">
                    <div className="row">
                      <div className="col-12">
                        <p className="h4-b">Datos del representante</p>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Nombre</label>
                            <input 
                              type="text"
                              name="name"
                              onChange={this.onChange}
                            />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Apellidos</label>
                            <input 
                              type="text"
                              name="last_name"
                              onChange={this.onChange}
                            />
                          </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Teléfono</label>
                            <input 
                              type="phone"
                              name="phone"
                              onChange={this.onChange}
                              onBlur={this.validatePhone}
                            />
                          </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Correo electrónico</label>
                          <input 
                            type="email"
                            name="email"
                            onChange={this.onChange}
                            onBlur={this.validateEmail}
                            warning='Datos no validos'
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-l">
                  <div className="col-12 col-lg-12">
                    <div className="row">
                      <div className="col-12">
                        <p className="h4-b">Dirección</p>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Ciudad</label>
                            <input 
                              type="text"
                              name="city"
                              onChange={this.onChange}
                            />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Estado</label>
                            <input 
                              type="text"
                              name="state"
                              onChange={this.onChange}
                            />
                          </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Código Postal</label>
                            <input 
                              type="text"
                              name="zip"
                              onChange={this.onChange}
                              onBlur={this.validatePhone}
                            />
                          </div>
                      </div>

                      <div className="col-12 col-lg-12">
                        <div className="form-group">
                          <label htmlFor="">Dirección</label>
                          <input 
                            type="text"
                            name="line1"
                            onChange={this.onChange}
                            maxLength={50}
                            warning='Datos no validos'
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="form-group">
                          <input 
                            type="text"
                            name="line2"
                            onChange={this.onChange}
                            maxLength={50}
                            warning='Datos no validos'
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="form-group">
                          <input 
                            type="text"
                            name="line3"
                            onChange={this.onChange}
                            maxLength={50}
                            warning='Datos no validos'
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>

                    {/* <div className="row mt-l">
                      <div className="col-12">
                        <p className="h4-b">Datos de la agencia</p>
                      </div>
                    </div> */}
                    <div className="row mt-l">
                      <div className="col-12">
                        <p className="h4-b">Agencias</p>
                      </div>
                      {this.state.agencies.length>0 && this.state.agencies.map((agency, index) => {
                      return(
                        <PayMethod
                              index={index}
                              _delete={this._delete}
                              type='agency'
                              cardColor='-first'
                              cardName={agency}
                            />
                      )
                    })}
                        <div className="row mt-l">
                          <div className="col-12 col-lg-6">
                            <div className="form-group">
                              <label htmlFor="">Nombre de la agencia</label>
                              <input 
                                id='agency_name'
                                type="text"
                                name="agency_name"
                                value={this.state.agency_name}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                        <button className="button -primary w-50" disabled={!(this.state.agency_name)} onClick={this.addAgency}>Agregar Agencia</button>
                      </div>
                        </div>
                    </div>



                    <div className="row mt-l">
                      <div className="col-12">
                        <button className="button -primary w-100" disabled={!(this.state.validEmail && this.state.name.length > 0 &&
                          this.state.last_name.length > 0 && this.state.validPhone) } onClick={this.create_user}>Registrarme</button>
                      </div>
                    </div>

                  </div>

                  <div className="col-12 col-lg-5 align-items-center d-none d-lg-flex">
                    <img src={RegisterImg} alt="imagen de autos estacionados" className="w-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <Footer
              className="mt-xxxl"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Register;