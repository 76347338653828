import React, { Component } from 'react';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent';
import Footer from '../Components/Footer/Footer';

class QuoteCar extends Component {
  render() {
    return (
      <div className="home-page">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>
        </div>

        <div className="row mx-0 mt-top">
          <div className="col-12 px-20 mt-20px mb-20px">
            <intelimotor-valuation

              style={{"--primary": "rgb(54, 182, 255)", "--primary-light": "rgb(54, 182, 255)", "--primary-dark": "rgb(54, 182, 255)", "--primary-contrast": "rgb(255, 255, 255)", "--pricing": "rgb(222, 103, 36)", "--disabled": "rgb(156, 163, 175)", "--error": "rgb(225, 28, 28)" }}

              business-units='[{"businessUnit":"5f08cd8d072bc3001a2351be","channel":"Cotizador"},{"businessUnit":"5f2af8af0334fe00137dce63","channel":"Cotizador"},{"businessUnit":"5f2af8e15388bf001361ac8a","channel":"Cotizador"},{"businessUnit":"5f2af990ed71470013da6bd3","channel":"Cotizador"},{"businessUnit":"5f2af9c085dfda0013a47afc","channel":"Cotizador"},{"businessUnit":"5f2af9ead4c6ed001336157d","channel":"Cotizador"},{"businessUnit":"5f2afa1fd4c6ed00133616e9","channel":"Cotizador"},{"businessUnit":"5f2afa51ed71470013da75d4","channel":"Cotizador"},{"businessUnit":"5f2afa7f85dfda0013a47f03","channel":"Cotizador"},{"businessUnit":"5f2afaaed4c6ed001336345f","channel":"Cotizador"}]'
              full-width
              md-cols='2'
              lg-cols='4'
            ></intelimotor-valuation>

          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row mx-0">
            <Footer></Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default QuoteCar;