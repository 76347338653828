import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import axios from 'axios'
import jwtDecode from 'jwt-decode';
import Logo from '../../Image/svg/continental.svg';
import Power from '../../Image/svg/drivy_Logo.svg';
import DropdownArrow from '../../Image/svg/dropdown-arrow.svg';

const NavBarComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drop, setDrop] = useState(false);
  const toggle_drop = () => setDrop(!drop);
  const toggle = () => setIsOpen(!isOpen);
  const collapse = () => setIsOpen(false);

  const login = () => {
    props.auth.login();
  }
  
  const logout = () => {
    props.auth.logout();
  }

  return (
    <div>
      <Navbar color="light" light expand="lg">
        <div className="container">
          <NavbarBrand href={props.auth.isAuthenticated() ? "/seller" :"/home/" } >
            <img src={Logo} alt="" className="logo" />
            <div className="wrp">
              <p>Powered by:</p>
              <img src={Power} alt="" className="powered" />
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <div className={`profile user-options d-${!props.auth.isAuthenticated() ? 'flex flex-column flex-lg-row': 'none'}`}>
                <NavItem>
                  <NavLink href="/search-select/">Comprar un auto</NavLink>
                </NavItem>
                <NavLink href="/quote-car">
                  <button className="button -primary">Vender autos</button>
                </NavLink>
                <NavItem>
                    {props.auth.isAuthenticated() ? (
                      <NavLink href="#" onClick={logout}>Cerrar sesión</NavLink>
                      ):
                      (
                        <NavLink href="#" onClick={login}>Iniciar sesión</NavLink>
                    )
                    }
                </NavItem>
              </div>
              
              {/* Seller menu */}
              <div className={`profile seller-options d-${props.auth.isAuthenticated() ? 'flex flex-column flex-lg-row': 'none'}`}>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <i class="far fa-user-circle user mr-xxs"></i>
                    {localStorage.getItem('name')}
                    <i id="option-angle" class="fas fa-angle-down ml-xxs"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <a href="/seller-edit-profile" className="-line">Editar perfil</a>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <a href="/seller-pay-methods" className="-line">Métodos de pago</a>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <a href="/seller-pay-record" className="-line">Historial de pagos</a>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <a onClick={props.auth.logout}>Cerrar sesión</a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem className="seller d-block d-lg-none">
                  <NavLink href={props.auth.isAuthenticated() ? "/seller" :"/home/"}><i class="fas fa-home"></i> Inicio</NavLink>
                </NavItem>
                <NavItem className="seller d-block d-lg-none">
                  <NavLink href="/add-car/"><i class="fas fa-plus"></i> Agregar auto</NavLink>
                </NavItem>
                <NavItem className="seller d-block d-lg-none">
                  <NavLink href="/seller-catalog/"><i class="fas fa-search"></i> Catálogo</NavLink>
                </NavItem>
                <NavItem className="seller d-block d-lg-none">
                  <NavLink href="/seller"><i class="fas fa-users"></i> Vendedores</NavLink>
                </NavItem>
                {/* <NavItem className="seller d-block d-lg-none">
                  <NavLink href="/components/"><i class="fas fa-sign-out-alt"></i> Cerrar seción</NavLink>
                </NavItem> */}
              </div>
              {/* Seller menu */}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  );
}

export default NavBarComponent;