import React, { Component } from 'react';
import Logo from '../../Image/svg/continental.svg'
import Drivy from '../../Image/svg/drivy_Logo.svg'

class Footer extends Component {
  render() {
    return (
      <div className={`footer ${this.props.className}`}>
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-3">
              <a href="/" className="logo-wrp">
                <img className="logo-img" src={Logo} alt=""/>
                <div className="powered">
                  <p className="text mb-0">Powered by:</p>
                  <img src={Drivy} alt="" className="power" />
                </div>
              </a>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-12 col-lg-4 d-flex flex-column">
              <p class="footer-subtitle mt-xs">Grupo Continental</p>
              <a href="/search-select">Comprar autos</a>
              {/* <a href="/notice-of-privacy">Vender autos</a>
              <a href="/notice-of-privacy">Preguntas frecuentes</a>
              <a href="/notice-of-privacy">Registrarme</a> */}
              {/* <a href="/notice-of-privacy">Iniciar sesión</a> */}
              <a href="/notice-of-privacy">Aviso de privacidad</a>
            </div>
            <div class="col-12 col-lg-4 d-flex flex-column">
              <p class="footer-subtitle mt-xs">Podría interesarte</p>
              <a class="m-0" target="blank" href="http://grupocontinentalautomotriz.com.mx/conocenos/">Grupo Continental</a>
              <a target="blank" href="https://ndscognitivelabs.com/">NDS Cognitive Labs</a>
              {/* <a target="blank" href="https://gsuite.ndscognitivelabs.com/">G Suite para tu empresa</a>
              <a target="blank" href="https://innovacion.ndscognitivelabs.com/">Centro de innovación</a>
              <a target="blank" href="https://chat.ndscognitivelabs.com/">Whatsapp para tu empresa</a> */}
            </div>
            <div className="col-12 col-lg-4 d-flex flex-column">
              <p className="footer-subtitle mt-xs">Contáctanos</p>
              <p className="m-0">Tel: +52 (55) 5128 5180 </p>
              <p className="mb-0">Whatsapp: +52 55 8006 7847</p>
              <p >seminuevos@autopolanco.com.mx</p>
              <p class="footer-subtitle">Síguenos</p>
              <div class="social-wrapper">
                <a target="blank" href="https://www.facebook.com/SeminuevosContinental"><i class="fab fa-facebook-f"></i></a>
                <a target="blank" href="https://www.instagram.com/seminuevosgrupocontinental/"><i class="fab fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-0">
            <div className="col-12 px-0">
              <div className="rights text-center">
                <p className="mb-0">Grupo Continental. © Todos los derechos reservados 2021.</p>
              </div>
            </div>
          </div>
      </div>

    );
  }
}

export default Footer;