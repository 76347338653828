import React, { Component, version } from 'react';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent';
import Footer from '../Components/Footer/Footer';
import NumberFormat from 'react-number-format';
import axios from 'axios'
import { Redirect } from 'react-router-dom';

import DocumentCardPerson from '../Components/DocumentCard/DocumentCardPerson';
import DocumentCardLicense from '../Components/DocumentCard/DocumentCardLicense'
import CarRecap from '../Components/CarRecap/CarRecap';
import { ButtonPrimary } from '../Components/Button/Button';

import CarIMG from '../Image/car-detail/car.png';
import UploadCard from '../Components/UploadCard/UploadCard';
import { Collapse, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const API_URL = process.env.REACT_APP_API_URL


class SendForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      color: '',
      version: '',
      initial_payment: '',
      months: '',
      monthly_payment: '',
      car: '',
      cars: [],
      car_options: [],
      color_options: [],
      cost: '',
      min_payments: 0,
      show_test: '',
      licence: {},
      modal_override: false,
      img1: '',
      img2: '',
      img_temp: '',
      aceptar: false,
      mail_img: new FormData(),
      image_disp_1: '',
      person2: {
        birthdate: '',
        checked: [false, false, false, false, false],
        doc_up: false,
        license_up: false,
        pdf: false,
        file_name: '',
        clave: '',
        gender: 'Seleccionar sexo',
        curp: '',
        name: '',
        first_surname: '',
        second_surname: '',
        img: '',
        license: '',
        img_name: '',
        moral_rfc: ''
      }
    }
  }

  componentDidMount = () => {
    document.addEventListener("DOMContentLoaded", function (event) {
      window.docupass({
        flow: '60ede97b0fcb26bb6172beed',
        company: '60edc33f0fcb26bb61611bb5',
      })
        .render('#docupass-button')
    });

    // let url = `${API_URL}/api/v1/cars/get_all`
    // axios.get(url)
    //   .then(res => {
    //     let names = []
    //     res.data.forEach((element, index) => {
    //       names.push({
    //         label: `${element.brand} - ${element.model} - ${element.versions.version}`,
    //         value: index
    //       })
    //     });
    this.setState({
      // cars: res.data,
      // car_options: names,
      months: sessionStorage.getItem("months"),
      initial_payment: sessionStorage.getItem("initial_payment"),
      cost: sessionStorage.getItem("cost"),
      color: sessionStorage.getItem("color")
    })
    this.getSpecific(sessionStorage.getItem("brand"), sessionStorage.getItem("model"), sessionStorage.getItem("version"))
    this.getColors(JSON.parse(sessionStorage.getItem("car")))
    // })
  }

  handlerFirstName = (event) => {
    this.setState({
      first_name: event.target.value
    }, () => { sessionStorage.setItem("first_name", this.state.first_name) })
  }

  _setState = (state) => {
    this.setState(state);
  }

  toggle = (state) => {
    var stateObj = { [state]: !this.state[state] };
    this.setState(stateObj);
  }

  personEmpty = (person) => {
    if (!person) return false
    if (person.name === '' ||
      person.first_surname === '' ||
      person.second_surname === '' ||
      person.birthdate === '' ||
      person.curp === ''
    ) {
      return true
    } else { return false }
  }

  handlerLastName = (event) => {
    this.setState({
      last_name: event.target.value
    })
  }

  handlerEmail = (event) => {
    this.setState({
      email: event.target.value
    })
  }

  handlerPhone = (event) => {
    this.setState({
      phone: event.target.value
    })
  }

  handlerColor = (event) => {
    this.setState({
      color: event.target.value
    })
  }

  handlerMonths = (event) => {
    this.setState({ months: event.target.value }, () => {
      if (this.state.cost != '' && this.state.months != '' && this.state.initial_payment != '') this.handlerPayment()
      sessionStorage.setItem("months", this.state.months)
    })
  }

  handlerInitial = (event) => {
    this.setState({ initial_payment: event.floatValue }, () => {
      if (this.state.cost != '' && this.state.months != '' && this.state.initial_payment != '') this.handlerPayment()
      sessionStorage.setItem("initial_payment", this.state.initial_payment)
    })
  }

  handlerPayment() {
    let url = `${API_URL}/api/v1/cars/monthly_payments?cost=${this.state.cost}&months=${this.state.months}&initial_payment=${this.state.initial_payment}`
    axios.get(url)
      .then(res => {
        this.setState({ monthly_payment: res.data }, () => { sessionStorage.setItem("monthly_payment", this.state.monthly_payment) })
      }, error => {
        return Promise.reject(error)
      })
  }

  handlerVersion = (event) => {
    let value = this.state.cars[event.target.value]
    this.setState({
      version: value,
      cost: value.versions.price
    }, () => {
      sessionStorage.setItem("car", JSON.stringify(this.state.car))
      sessionStorage.setItem("cost", this.state.cost)
    })
    this.getSpecific(value.brand, value.model, value.versions.version)
    this.getColors(value)
  }

  toggle_trial = () => {
    if (this.state.show_test == '-show') {
      this.setState({ show_test: '' })
    } else {
      this.setState({ show_test: '-show' })
    }
  }
  idHandler = (doc) => {
    this.setState({ id_doc: doc.target.files[0] })
  }
  getColors = (current) => {
    let color_options = []
    current.versions.colors.forEach(element => {
      color_options.push(element.label)
    })
    this.setState({ color_options })
  }

  getSpecific = (brand, model, version) => {

    let url = `${API_URL}/api/v1/cars/get_single?brand=${brand}&model=${model}&version=${version}`

    axios.get(url).then(res => {
      this.setState(
        {
          car: res.data[0],
          cost: res.data[0].versions.price
        }, () => { this.handlerPayment() })
      this.minPayment(res.data[0].versions.price)
    })
  }

  minPayment(price) {
    let url = `${API_URL}/api/v1/cars/monthly_payments?cost=${price}&months=12&initial_payment=${price * 0.15}`
    axios.get(url).then(res => {
      this.setState({ min_payments: res.data })
    }, error => {
      return Promise.reject(error)
    })
  }

  handerSendMail = () => {

    let data = this.state.mail_img

    if (this.state.first_name === '' || this.state.last_name === '' || this.state.email === '' || this.state.phone === '') {
      this.setState({
        modal_override: !this.modal_override,
        error_msg: 'Es necesario llenar todos los datos del formulario.',
        img_temp: '',
        aceptar: true
      })
      return;
    }

    if (this.state.img1 !== '') {
      data.append('img1', this.state.img1)
    }

    if (this.state.show_test !== '' && this.state.img2 !== '') {
      data.append('img2', this.state.img2)
    }
    else if (this.state.show_test !== '' && this.state.img2 === '') {
      this.setState({
        modal_override: !this.modal_override,
        error_msg: 'Al pedir una prueba de manejo es necesaria una licencia vigente.',
        img_temp: this.img2
      })

      return;
    }

    data.append('name', this.state.first_name)
    data.append('last_name', this.state.last_name)
    data.append('email', this.state.email)
    data.append('phone', this.state.phone)
    data.append('brand', this.state.car.brand)
    data.append('version', this.state.car.versions.version)
    data.append('image', this.state.car.versions.pictures[0])
    data.append('model_year', this.state.car.model_year)
    data.append('model', this.state.car.model)
    data.append('months', this.state.months)
    data.append('initial_payment', this.state.initial_payment)
    data.append('monthly_payment', this.state.monthly_payment)
    data.append('color', this.state.color)
    data.append('user_id', this.state.car.user_id)
    data.append('vin', this.state.car.vin)
    data.append('region', this.state.car.region)

    var response = axios({
      method: 'post',
      url: `${API_URL}/api/v1/seller-mail`,
      data,
    }).then(() => this.setState({redirect: true}))
    
    // let url_buyer = `${API_URL}/api/v1/buyer-mail?name=${this.state.first_name}&lastname=${this.state.last_name}&email=${this.state.email}&phone=${this.state.phone}&brand=${this.state.car.brand}
    // &version=${this.state.car.versions.version}&model_year=${this.state.car.model_year}&model=${this.state.car.model}&months=${this.state.months}&initial_payment=${this.state.initial_payment}
    // &monthly_payment=${this.state.monthly_payment}&color=${this.state.color}`
    // axios.get(url_buyer)
    //   .then(
    //     console.log("Mensaje enviado")
    //   )
  }

  deleteDoc = () => {
    this.toggle('modal_override')

    if (this.state.img_temp === this.state.img1) {
      this.setState({
        img1: '',
        img_temp: ''
      })
    }
    else if (this.state.img_temp === this.state.img2) {
      this.setState({
        img2: '',
        img_temp: ''
      })
    }

  }

  aceptarErrorForm = () => {
    this.toggle('modal_override')
    this.setState({
      aceptar: false
    })
  }

  render() {
    return (
      <div className="container-fluid px-0">
        {this.state.redirect &&
          <Redirect to={'/home'}></Redirect>
        }
        <NavBarComponent {...this.props}></NavBarComponent>
        <Modal centered={true} isOpen={this.state.modal_override} toggle={() => this.toggle('modal_override')}>
          <ModalHeader>Advertencia</ModalHeader>
          <ModalBody>
            Se detectó el siguiente problema con el documento o el formularuio:
            <br></br>
            {this.state.error_msg}
            <br></br>
            {this.state.img_temp != undefined &&
              <img size='xl' src={this.state.img_temp} height="100%" width="100%" alt=""></img>
            }
            {/* {this.state.pdf &&
              <img size='xl' src={DOCUMENTS_URL + this.props.person.img_name} height="100%" width="100%"alt=""></img>
            } */}
            <ModalFooter>
              {!this.state.aceptar &&
                <>
                  <ButtonPrimary onClick={() => this.toggle('modal_override')} title="Aprobar"></ButtonPrimary>
                  <ButtonPrimary onClick={() => this.deleteDoc()} title="Descartar"></ButtonPrimary>
                </>
              }
              {this.state.aceptar &&
                <>
                  <ButtonPrimary onClick={() => this.aceptarErrorForm()} title="Aceptar"></ButtonPrimary>
                </>
              }
            </ModalFooter>
          </ModalBody>
        </Modal>

        <div className="container">
          <div className="row mx-0 mt-top">
            <div className="col-12">
              <p className="h1-b  mt-l text-center">¡ Lo quiero !</p>
              <p className="h3-r text-center">Al completar este formulario uno de nuestros asesores se pondrá en contacto contigo</p>
              <p className="h3-r text-center">Para subir sus documentos debe escribir su nombre</p>
            </div>
          </div>
          <div className="row mt-s">
            <div className="col-12 col-md-8">
              <CarRecap
                brand={this.state.car.brand ? this.state.car.brand : ''}
                model={this.state.car.model ? this.state.car.model : ''}
                version={this.state.car.versions ? this.state.car.versions.version : ''}
                model_year={this.state.car.model_year ? this.state.car.model_year : ''}
                // motor={this.state.car.versions ? this.state.car.versions.motor.type : ''}
                // performance={`${this.state.car.versions.performance.city}  km/litro`}
                // transmission={this.state.car.versions.transmission ? this.state.car.versions.transmission : ''}
                // power={`${this.state.car.versions.motor.power} hp`}
                // capacity={`${this.state.car.versions.passengers} pasajeros`}
                color={this.state.color_options[0]}
                characteristics={this.state.car.versions ? this.state.car.versions.price : 0}
                img={this.state.car.versions ? (this.state.car.versions.pictures[0] ? this.state.car.versions.pictures[0] : this.state.car.versions.colors[0].photo) : CarIMG}
              />
            </div>
            <div className="col-12 col-md-4 d-flex">
              <div className="cm-card -primary d-flex justify-content-center flex-column align-items-center w-100">
                <p className="h3-r text-center">Mensualidades de:</p>
                <p className="h2-b mb-0 text-center">${this.state.monthly_payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group mb-0 mt-s">
                <input type="text"
                  name="brand"
                  onChange={this.handlerFirstName} />
                <label htmlFor="">Nombre</label>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group mb-0 mt-s">
                <input type="text"
                  name="brand"
                  onChange={this.handlerLastName} />
                <label htmlFor="">Apellidos</label>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group mb-0 mt-s">
                <input type="text"
                  name="brand"
                  onChange={this.handlerEmail} />
                <label htmlFor="">Correo electrónico</label>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group mb-0 mt-s">
                <input type="number"
                  name="brand"
                  onChange={this.handlerPhone} />
                <label htmlFor="">Teléfono</label>
              </div>
            </div>
            {/* <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group mb-0 mt-s">
                <select name="colors"
                  id=""
                  //defaultValue={this.sessionVersion}
                  onChange={this.handlerVersion}
                  closeMenuOnSelect={true}
                  options={this.state.car_options}
                >
                  <option value="" selected disabled>Seleccionar</option>
                  {this.state.car_options.map((car, i) => {
                    let selected_car = ""
                    if (car.label.includes(sessionStorage.getItem("model")) && car.label.includes(sessionStorage.getItem("version"))) { selected_car = "selected" }
                    return (<option value={car.value}
                      selected={selected_car}
                    >{car.label}</option>)
                  })
                  }
                </select>
                <label htmlFor="">Selecciona una versión</label>
              </div>
            </div> */}
            {/* <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group mb-0 mt-s">
                <select name="colors" id="" onChange={this.handlerColor}>
                  {this.state.color_options.length > 0 && (
                    <option value="" selected disabled>Seleccionar</option>
                  )}
                  {!(this.state.color_options.length > 0) && (
                    <option value="" selected disabled>Seleccionar una versión</option>
                  )}
                  {this.state.color_options.map((color, i) => {
                    let selected_color = ""
                    if (color.includes(sessionStorage.getItem("color"))) { selected_color = "selected" }
                    return (<option value={color}
                      selected={selected_color}
                    >{color}</option>)
                  })}
                </select>
                <label htmlFor="">Color</label>
              </div>
            </div> */}
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group mb-0 mt-s">
                <NumberFormat
                  defaultValue={sessionStorage.getItem("initial_payment")}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  placeholder="$1000.50"
                  onValueChange={this.handlerInitial} />
                <label htmlFor="">Enganche</label>
                <div className="required">Mín 30,000  Max 180,000</div>

              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group mb-0 mt-s">
                <select name="colors"
                  id=""
                  defaultValue={sessionStorage.getItem("months")}
                  onChange={this.handlerMonths}
                >
                  <option value="" selected disabled>Seleccionar</option>
                  <option value="12">12</option>
                  <option value="18">18</option>
                  <option value="24">24</option>
                  <option value="36">36</option>
                </select>
                <label htmlFor="">Meses a pagar</label>
              </div>
            </div>
          </div>

          {/* <p className="mt-l">Inicia tu trámite de crédito enviando tus documentos</p>
          <div id="docupass-button"></div> */}

          {this.state.first_name !== '' && this.state.last_name !== '' && (
            <>
              <div className={"trial-requirements -show"}>
                <div className="row mt-m">
                  <div className="col-12 col-lg-6 mb-30">
                    <DocumentCardPerson
                      description='Documentos'
                      required='true'
                      title="Identificación oficial"
                      deleteDoc={this.deleteDoc}
                      setState={this._setState}
                      params={this.state}
                      checkContinue={this.checkContinue}
                      role={this.props.role}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-s">
                <div className="col-12 col-lg-6">
                  <div className="cm-card -primary">
                    <p className="h1-b">Prueba de manejo</p>
                    <div className='custom-control custom-switch'>
                      <input
                        type='checkbox'
                        className='custom-control-input'
                        id='customSwitches'
                        readOnly
                        onClick={this.toggle_trial}
                      />
                      <label className='custom-control-label' htmlFor='customSwitches'>Quiero prueba de manejo</label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className={"trial-requirements " + this.state.show_test}>
            <div className="row">
              <div className="col-12 col-lg-6 mb-30">
                <DocumentCardLicense
                  licence={this.state.licence}
                  required='true'
                  title="Licencia de manejo"
                  customClasses="-light"
                  deleteDoc={this.deleteDoc}
                  person={this.state.person2}
                  setState={this._setState}
                  params={this.state} id={1}
                  checkContinue={this.checkContinue}
                  role={this.props.role}
                />
              </div>
              {/* <div className="col-12 col-lg-6 mb-30">
                  <UploadCard
                    required='true'
                    cardTitle="Comprobante de domicilio"
                    setState={this._setState}
                    tag='Comprobante'
                    cardInstructions="Puede ser comprobante de luz, agua, gas ó teléfono, si no cuentas con uno de estos documentos, puedes llenar los siguientes datos de forma manual"
                    address={this.state.address}
                    checkContinue={this.checkContinue}
                    params={this.state}
                    role={this.props.role}
                  />
                </div> */}

              {/* <div className="col-3 d-flex align-items-end">
                <div className="form-group mb-0 mt-xs">
                  <input type="file" className="file" onChange={this.idHandler}/>
                  <label htmlFor="">Cargar identificación oficial</label>
                </div>
              </div>
              <div className="col-3 d-flex align-items-end">
                <div className="form-group mb-0 mt-xs">
                  <input type="file" className="file"/>
                  <label htmlFor="">Cargar comprobante de domicilio</label>
                </div>
              </div>
              <div className="col-3 d-flex align-items-end">
                <div className="form-group mb-0 mt-xs">
                  <input type="file" className="file"/>
                  <label htmlFor="">Cargar carátula bancaria</label>
                </div>
              </div> */}
              <br></br>
            </div>
            {/* <div className="col-3 d-flex align-items-end ">
              <div className="form-group mb-0 mt-m w-100">
                <select name="colors"
                  id=""
                  onChange={this.handlerDate}
                >
                  <option value="" selected disabled>Seleccionar</option>
                  <option value="12">Blanco</option>
                  <option value="18">Negro</option>
                  <option value="24">Rojo</option>
                  <option value="36">Azul</option>
                </select>
                <label htmlFor="">Fecha de la prueba</label>
              </div>
            </div> */}
          </div>

          <div className="row mt-m">
            <div className="col-12 col-lg-6">
              <button className="button -primary w-100" onClick={this.handerSendMail} disabled={this.state.first_name === '' || this.state.last_name === '' || this.state.email === '' || this.state.phone === ''}>Enviar</button>
            </div>
          </div>



        </div>

        <div className="container-fluid px-0 mt-xxxl">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default SendForm;