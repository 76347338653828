import React, { Component } from 'react'

class InputForm extends Component {

  render() {
    return(
      <div className={`group ${this.props.customClasses}`}>
        {this.props.label &&
        <label htmlFor="">{this.props.label}</label>
        }
        <input className={` ${this.props.inputColor} ${this.props.warningMessage}`} name={this.props.name} pattern={this.props.pattern} style={{ borderColor: this.props.borderColor, borderWidth: 2}} type={this.props.type} placeholder={this.props.placeholder} onChange={this.props.onChange} value={this.props.value} onBlur={this.props.onBlur} required={this.props.required} maxLength={this.props.maxLength} max={this.props.max} min={this.props.min} disabled={this.props.disabled} />
        {this.props.warn && <span className="warning-message">{this.props.warning}</span>
}        <span className="required">{ this.props.requiredText }</span>
      </div>
    )
  }
}

export default InputForm