import React, { Component } from 'react';
import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import Footer from '../../Components/Footer/Footer';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import ProfileCard from '../../Components/SellerComponents/ProfileCard/ProfileCard';
import SellerCarCard from '../../Components/SellerComponents/SellerCarCard/SellerCarCard';
import axios from 'axios';
import Paginator from '../../Components/Paginator/Paginator';

import Welcome from '../../Image/SellerComponents/Dashboard/Welcome.png';

const API_URL = process.env.REACT_APP_API_URL
class SellerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: [],
      paginated_cars:[],
      current_page:1,
    }
  }
  paginate = (cars) => {
    let page_size = 5
    let paginated_cars = []
    let current_page = 0
    let count = -1
    cars.forEach(car => {
      count++
      if(count== 0 && current_page == 0){
        paginated_cars.push([car])
      }
      else if(count==page_size){
        count = 0
        current_page++
        paginated_cars.push([car])
      } else {
        paginated_cars[current_page].push(car)
      }
    })
    console.log(paginated_cars)
    this.setState({paginated_cars})
  }
  forward = () =>{
    this.setState({current_page:this.state.current_page + 1})
  }
  previous = () =>{
    this.setState({current_page:this.state.current_page - 1})
  }
  first = () =>{
    this.setState({current_page:0})
  }
  last = () =>{
    this.setState({current_page:this.state.paginated_cars.length - 1})
  }

  setpage = (page) => {
    this.setState({current_page:page})
  }
  componentDidMount = () => {
    let params = this.props.location
    if(!localStorage.getItem("card")) return
    let url = `${API_URL}/api/v1/cars/get_all?user_id=${localStorage.getItem("group_id")}`
    if(localStorage.getItem("role")!='admin'){
      url = `${API_URL}/api/v1/cars/get_all?user_id=${localStorage.getItem("group_id")}&agency=${localStorage.getItem('agency')}`
    }
    axios.get(url)
    .then(res => {
      console.log(res.data)
      this.setState({
          cars: res.data
        })
        this.paginate(res.data)
      }, error => {
        return Promise.reject(error)
      })
  }
  render() {
    return (
      <div className="seller-page">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="container-fluid mt-top min-height d-flex px-0">
            <SideNav></SideNav>
            <div className="container mt-s">
              <div className="row">
                {localStorage.getItem("card") == 'false' &&
                <div className="col-12">
                  <div className="cm-card -primary -no-padding">
                    <div className="row">
                      <div className="col-12 col-md-8">
                        <div className="cardcontent h-100">
                          <div className="row h-100">
                            <div className="col-12 d-flex flex-column">
                              <p className="h2-b">¡Bienvenido!</p>
                              <div> 
                                <p className="body-r">Para poder empezar a vender tus vehículos, debes agregar un método de pago.</p>
                                <p className="body-r">Si tienes dudadas de nuestras tarifas de cobro, puedes consultarlas en el siguiente enlace. <a href="">consultar tarifas</a></p>
                                <a className="mt-auto" href="/seller-pay-methods" target="blank" ><button className="button -primary"><i class="far fa-credit-card"></i> Agregar método de pago</button></a>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <img className="w-100" src={Welcome} alt="imagen de autos estacionados" />
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 d-flex">
                  <div className="cm-card -primary w-100 mt-s">
                    <div className="row h-100">
                      <div className="col-12 d-flex flex-column">
                        <p className="h2-b">TUTORIAL</p>
                        <p className="body-r">¿Necesitas más información de cómo funciona nuestra plataforma? puedes consultar en cualquier momento nuestro tutorial</p>
                        <a className="mt-auto" href="" target="blank" ><button className="button -primary"><i class="fas fa-play-circle"></i> Ver tutorial</button></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6 d-flex">
                  <div className="cm-card -primary w-100 mt-s">
                    <div className="row h-100">
                      <div className="col-12 d-flex flex-column">
                        <p className="h2-b">¿Preguntas?</p>
                        <p className="body-r">Si aún tienes dudas consulta nuestra sección de preguntas frecuentes.</p>
                        <a className="mt-auto" href="" target="blank" ><button className="button -primary"><i class="fas fa-question-circle"></i> Ver preguntas frecuentes</button></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 mt-xs">
                  <ProfileCard></ProfileCard>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mt-xs">
                  <div className="stats-card -red">
                    <p className="card-title">Mis autos subidos en los últimos 3 meses</p>
                    <p className="statistics mb-0">{this.state.cars.length}</p>
                  </div>
                </div>
                {/* <div className="col-12 col-md-6 col-lg-3 mt-xs">
                  <div className="stats-card -red">
                    <p className="card-title">Pruebas de manejo solicitadas</p>
                    <p className="statistics mb-0">43</p>
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-12">
                  <p className="h2-b mt-xl">Los últimos autos que subiste</p>
                </div>
                {((localStorage.getItem("role") == "admin" || localStorage.getItem("role") == "agency")) &&
                <div className="col-12 col-md-6 col-lg-4 mt-s mb-s">
                  <a href="/add-car" className="button -primary">
                    <i class="fas fa-plus"></i> Agregar un auto
                  </a>
                </div> }
                
                {this.state.paginated_cars.length>0 && this.state.paginated_cars.length>0 && this.state.paginated_cars[this.state.current_page-1].map((car) => {
                  return(
                    <div className="col-12 mt-xxs">
                      <SellerCarCard
                        image={car.versions ? (car.versions.pictures ? car.versions.pictures[0] :car.versions.colors[0].photo) : ''}
                        brand={car.brand}
                        model={car.model}
                        version={car.versions.version}
                        model_year={car.model_year}
                        car_id={car._id}
                        publish={car.publish}
                        // className="-offer"
                        cost={car.versions.price ?("$" + car.versions.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')): 'No encontrado'}
                        // offer="$180,000"
                        capacity={car.versions.passengers + " pasajeros"}
                        stock={car.versions.stock + " unidades disponibles"}
                        kilometers={car.km? (car.km.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+ " kms"):'N/A'}
                      ></SellerCarCard>
                    </div>
                  )
                })}
              </div>
              <Paginator
                current_page={this.state.current_page}
                pages={this.state.paginated_cars.length -1}
                setPage={this.setpage}
                >
              </Paginator>
            </div>
          </div>

          <div className="row mx-0">
            <Footer></Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default SellerProfile;