import React, { Component } from 'react';
import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import Footer from '../../Components/Footer/Footer';

import Step1 from '../../Image/SellerHome/seller_step_1.jpg'
import Step2 from '../../Image/SellerHome/seller_step_2.jpg'
import Step3 from '../../Image/SellerHome/seller_step_3.jpg'
import SellerAdmin from '../../Image/SellerHome/Seller_admin.png'

class SellerHome extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <div className="seller-landing">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col-12 px-0">
              <NavBarComponent {...this.props}></NavBarComponent>
            </div>
          </div>
          <div className="row mx-0 mt-top">
            <div className="col-12 px-0">
              <div className="seller-hero">
                <div className="container h-100">
                  <div className="row h-100">
                    <div className="col-12 h-100 d-flex flex-column justify-content-center">
                      <h1 className="h1-b -white">Grupo Continental</h1>
                      <p className="h2-r -white">La mejor plataforma en línea para vender autos</p>
                      <a href="/register" target="blank"><button className="button -primary -red">Registrarme</button></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid bg-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="h2-b text-center mt-xxxl">¿Cómo puedo vender los autos usados de mis agencias?</p>
                </div>
              </div>

              <div className="row mt-s pb-l">
                <div className="col-12 col-md-4 d-flex">
                  <div className="cm-card -dark -no-padding mb-l">
                    <img src={Step1} className="w-100 bb-primary" alt="Imagen de una persona usando una computadora" />
                    <div className="cardcontent">
                      <p className="h2-b -white text-center">1</p>
                      <p className="h3-r -white text-center">Regístrate en nuestra plataforma</p>
                      <p className="body-l -white text-center">Al registrarte en Grupo Continental estarás muy cerca de poder vender tus autos.</p>
                      <a href="/register" target="blank"><button className="button -outline mx-auto">Registrarme</button></a>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 d-flex">
                  <div className="cm-card -dark -no-padding mb-l">
                    <img src={Step2} className="w-100 bb-primary" alt="Imagen de una persona usando una computadora" />
                    <div className="cardcontent">
                      <p className="h2-b -white text-center">2</p>
                      <p className="h3-r -white text-center">Registra tus vendedores</p>
                      <p className="body-l -white text-center">Dinos que vendedores forman parte de tu equipo.</p>
                      <a href="/register" target="blank"><button className="button -outline mx-auto">Registrarme</button></a>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 d-flex">
                  <div className="cm-card -dark -no-padding mb-l">
                    <img src={Step3} className="w-100 bb-primary" alt="Imagen de una persona usando una computadora" />
                    <div className="cardcontent">
                      <p className="h2-b -white text-center">3</p>
                      <p className="h3-r -white text-center">Administra y vende tus autos</p>
                      <p className="body-l -white text-center">Registra y vende tus autos usados, tu proceso es 100% en linea,  esto garantiza mayor velocidad.</p>
                      <a href="/register" target="blank"><button className="button -outline mx-auto">Registrarme</button></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="seller-doubts mt-xxxl">
                  <div className="container px-0">
                    <div className="row mx-0">
                      <div className="col-12 col-lg-6 px-0">
                        <div className="doubtscontent">
                          <p className="h3-b -white">Resuelve todas tus dudas y conoce más sobre Grupo Continental</p>
                          <p className="h4-l -white">Grupo Continental creó una serie de tutoriales propios para que puedas sacar todo el provecho de la plataforma, consultadas aquí!</p>
                          <a href="" target="blank"><button className="button -outline">Conocer más</button></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-0 mt-xxxl">
            <div className="col-12 px-0">
              <div className="seller-admin">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                      <img src={SellerAdmin} className="w-100" alt="imágen de una auto negro" />
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                      <div className="admincontent">
                        <p className="h3-b -white">Administra tu inventario de autos y a tus vendedores</p>
                        <p className="h4-l -white">Con Grupo Continental puedes agregar y administrar los autos que deseas mostrar a los posibles compradores</p>
                        <p className="h4-l -white">Agrega, consulta, y da de baja todos los autos que registres de manera fácil.</p>
                        <p className="h4-l -white">Registra a todos los vendedores que formen parte de tu equipo.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer
                className="mt-xxxl"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SellerHome;