import React, { Component } from 'react';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent'
import RecommendationsProgress from '../Components/RecommendationsProgress/RecommendationsProgress';
import ReactDOM from 'react-dom';
import CarCard from '../Components/CarCard/CarCard';
import CarImg from '../Image/car-detail/car.png';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import Paginator from '../Components/Paginator/Paginator'
import Footer from '../Components/Footer/Footer';

const API_URL = process.env.REACT_APP_API_URL

class Recommendations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: { min: 5, max: 9 },
      stages: ['-active', '', '', ''],
      active_stage: 0,
      clasification: '',
      passengers: '',
      cars: [],
      formData: { min_amount: '100000', max_amount: '' },
      paginated_cars:[],
      current_page:1
    }
  }

  setpage = (page) => {
    this.setState({current_page:page})
  }

  paginate = (cars) => {
    let page_size = 6
    let paginated_cars = []
    let current_page = 0
    let count = -1
    cars.forEach(car => {
      count++
      if(count== 0 && current_page == 0){
        paginated_cars.push([car])
      }
      else if(count==page_size){
        count = 0
        current_page++
        paginated_cars.push([car])
      } else {
        paginated_cars[current_page].push(car)
      }
    })
    this.setState({paginated_cars})
  }

  activeStage = () => {

    // Set change to next stage 

    let temp_stages = this.state.stages
    temp_stages[this.state.active_stage + 1] = '-active'

    return temp_stages
  }

  prevStage = () => {

    // Return to prev stage

    let temp_stages = this.state.stages
    temp_stages[this.state.active_stage] = ''

    this.setState({
      stages: temp_stages,
      active_stage: this.state.active_stage - 1
    })
  }

  handlerStage1 = (value) => {

    let temp_stages = this.activeStage()
    this.setState({
      clasification: value,
      stages: temp_stages,
      active_stage: this.state.active_stage + 1
    })
  }

  handlerStage2 = (value) => {
    let temp_stages = this.activeStage()

    this.setState({
      passengers: value,
      stages: temp_stages,
      active_stage: this.state.active_stage + 1
    })
  }

  handlerStage3Min = (values) => {

    const { floatValue } = values;
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.min_amount = floatValue;
      return { formData };
    });
  }

  handlerStage3Max = (values) => {

    const { floatValue } = values;
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData.max_amount = floatValue;
      return { formData };
    });
  }

  searchRecommendation = () => {

    if (this.state.formData.min_amount === '' || this.state.formData.max_amount === '') {
      alert('Los campos no pueden estar vacios')
    } else {
      if(this.state.formData.max_amount < this.state.formData.min_amount){
        alert('El precio máximo debe ser mayor a al precio mínimo')
      }
      else {
  
        let url = `${API_URL}/api/v1/cars/get_all?min_price=${this.state.formData.min_amount}&max_price=${this.state.formData.max_amount}`
  
        axios.get(url).then(res => {
  
          let temp_stages = this.activeStage()
          this.paginate(res.data)
          this.setState({
            stages: temp_stages,
            active_stage: this.state.active_stage + 1,
            cars: res.data
          })
  
        })
      }
    }


  }

  render() {
    return (
      <div className="container-fluid px-0">
        <NavBarComponent {...this.props}></NavBarComponent>
        <div className="container mt-top min-height">
          <div className="row">
            <div className="col-12">
              <p className="h1-r  mt-l">Recomendaciones</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <RecommendationsProgress
                className="mt-s"
                stages={this.state.stages}
              />
            </div>
          </div>

          {this.state.active_stage === 0 &&

            <React.Fragment>

              <div className="row mt-xl">
                <div className="col-12">
                  <p className="h3-r">1. ¿Qué uso le darás a tu auto?</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                  <button className="button -dynamic mt-xs" onClick={() => this.handlerStage1('carga')} ><i class="fas fa-briefcase"></i> Carga</button>
                </div>
                <div className="col-12 col-md-5 col-lg-4">
                  <button className="button -dynamic mt-xs" onClick={() => this.handlerStage1('deportivo')}><i class="fas fa-taxi"></i> Deportivo</button>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                  <button className="button -dynamic mt-xs" onClick={() => this.handlerStage1('familiar')}><i class="fas fa-graduation-cap"></i> Familiar</button>
                </div>

              </div>

            </React.Fragment>
          }

          {this.state.active_stage === 1 &&

            <React.Fragment>

              <div className="row mt-xl">
                <div className="col-12">
                  <p className="h3-r">2. ¿Cuánto espacio necesitas?</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                  <button className="button -dynamic mt-xs" onClick={() => this.handlerStage2(2)} >Para mi y un acompañante</button>
                </div>
                <div className="col-12 col-md-5 col-lg-4">
                  <button className="button -dynamic mt-xs" onClick={() => this.handlerStage2(5)} >Para 5 personas</button>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                  <button className="button -dynamic mt-xs" onClick={() => this.handlerStage2(7)} >Para 7 personas</button>
                </div>
              </div>

              <div className="row mt-l">
                <div className="col-12">
                  <button className="button -tertiary" onClick={() => this.prevStage()} >Regresar</button>
                </div>
              </div>

            </React.Fragment>
          }


          {this.state.active_stage === 2 &&

            <React.Fragment>

              <div className="row mt-xl">
                <div className="col-12">
                  <p className="h3-r">3. ¿Cuál es tu rango de presupuesto?</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group mb-0 mt-m">
                    {/* <input type="text" placeholder="Ej. $50,000" name="min" onChange={this.handlerStage3} /> */}

                    <label htmlFor="">Mínimo</label>
                    <NumberFormat
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      placeholder="$1000.50"
                      onValueChange={this.handlerStage3Min} 
                      defaultValue={'$100,000'}
                      />
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group mb-0 mt-m">
                    {/* <input type="text" placeholder="Ej. $900,000" name="max" onChange={this.handlerStage3} /> */}
                    <label htmlFor="">Máximo</label>
                    <NumberFormat
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      placeholder="$1000.50"
                      onValueChange={this.handlerStage3Max} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">

                </div>
              </div>

              <div className="row mt-l">
                <div className="col-6">
                  <button className="button -tertiary" onClick={() => this.prevStage()} >Regresar</button>
                </div>
                <div className="col-6">
                  <button className="button -tertiary" onClick={() => this.searchRecommendation()} >Continuar</button>
                </div>
              </div>
            </React.Fragment>
          }

          {this.state.active_stage === 3 &&
            <React.Fragment>
              <div className="row mx-0 mt-xl">
                {this.state.paginated_cars.length > 0 && this.state.paginated_cars[this.state.current_page].map((car) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-4">
                      <CarCard
                        image={car.versions ? (car.versions.pictures ? car.versions.pictures[0] :car.versions.colors[0].photo) : CarImg}
                        name={car.brand}
                        model={car.model}
                        version={car.versions.version}
                        // className="-offer"
                        price={"$" + car.versions.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        // offer="$180,000"
                        passengers={car.versions.passengers + " pasajeros"}
                        units={car.versions.stock + " unidades disponibles"}
                        kilometers={"km"}
                      />
                    </div>
                  )}
                )}
              </div>
              <div className="row mt-l">
                <div className="col-6">
                  <button className="button -tertiary" onClick={() => this.prevStage()} >Regresar</button>
                </div>
              </div>
            </React.Fragment>
          }
          <br></br>
              {(this.state.active_stage == 3 || this.state.active_stage == 2) && <Paginator
                      current_page={this.state.current_page}
                      pages={this.state.paginated_cars.length -1}
                      setPage={this.setpage}
                    >
                  </Paginator>}
        </div>
        <div className="row mx-0">
          <Footer
            className="mt-xxxl"
          />
        </div>
      </div>
    );
  }
}

export default Recommendations;