import React, { Component } from 'react';
import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import Footer from '../../Components/Footer/Footer';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import SellerCarCard from '../../Components/SellerComponents/SellerCarCard/SellerCarCard';
import SearchBar from '../../Components/SearchBar/SearchBar';
import FilterBar from '../../Components/FilterBar/FilterBar';
import MobileFilter from '../../Components/Mobile/MobileFilter/MobileFilter';
import axios from 'axios';
import jwtDecode from 'jwt-decode'
import Paginator from '../../Components/Paginator/Paginator'

const API_URL = process.env.REACT_APP_API_URL

class SellerCatalog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cars: [],
      brand: '',
      model: '',
      color: '',
      year: '',
      max_price: '',
      min_price: '',
      price_brackets: [],
      brand_options: [],
      model_options: [],
      year_options: [],
      // Filter applied
      exclude: '',
      checked_price: -1,
      checked_brand: -1,
      checked_model: -1,
      checked_year: -1,
      show_filters: "col-12 d-none",
      sort: '',
      paginated_cars:[],
      current_page:1
    }
  }

  setpage = (page) => {
    this.setState({current_page:page})
  }

  paginate = (cars) => {
    let page_size = 10
    let paginated_cars = []
    let current_page = 0
    let count = -1
    cars.forEach(car => {
      count++
      if(count== 0 && current_page == 0){
        paginated_cars.push([car])
      }
      else if(count==page_size){
        count = 0
        current_page++
        paginated_cars.push([car])
      } else {
        paginated_cars[current_page].push(car)
      }
    })
    this.setState({paginated_cars})
  }

  componentDidMount = () => {
    let user_id = jwtDecode(localStorage.getItem("id_token")).sub
    let url = `${API_URL}/api/v1/cars/get_all?user_id=${localStorage.getItem("group_id")}`
    this.setState({user_id})
    if(!localStorage.getItem("card")) return

    if(localStorage.getItem("role")!='admin'){
      url = `${API_URL}/api/v1/cars/get_all?user_id=${localStorage.getItem("group_id")}&agency=${localStorage.getItem('agency')}`
    }

    axios.get(url)
      .then(res => {
        this.setState({
          cars: res.data
        })
        this.paginate(res.data)
      }, error => {
        return Promise.reject(error)
      })

    url = `${API_URL}/api/v1/cars/get_costs?bracket_size=250000`

    axios.get(url).then(res => {
      this.setState(
        { 
          price_brackets: res.data.cost_brackets,
      })
    }, error => {
      return Promise.reject(error)
    })

    this.calculate_filters(user_id)
  }

  calculate_filters = (user_id = this.state.user_id) =>{

    let url = `${API_URL}/api/v1/cars/calculate_filters?brand=${this.state.brand}&min_price=${this.state.min_price}&=max_price${this.state.max_price}
    &model=${this.state.model}&model_year=${this.state.year}&current_filter=${this.state.exclude}&user_id=${(localStorage.getItem("group_id"))}`

    axios.get(url).then(res => {

      // Check if filters are in response
      if(res.data.brands_filters){
        this.setState({
          brand_options: res.data.brands_filters
        })
      }
      if(res.data.model_filters){
        this.setState({
          model_options: res.data.model_filters
        })
      }
      if(res.data.year_filters){
        this.setState({
          year_options: res.data.year_filters
        })
      }
      if(res.data.color_filters){
        this.setState({
          color_options: res.data.color_filters
        })
      }

    }, error => {
      return Promise.reject(error)
    })

  }


  handlerGeneralFilter = (event) => {    
    let url = `${API_URL}/api/v1/cars/general_filter?text=${event.target.value}`

    axios.get(url).then(res=>{
      this.paginate(res.data.cars)
      this.setState({
        cars: res.data.cars,
        model_options: res.data.model_filters,
        year_options: res.data.year_filters,
        color_options: res.data.color_filters
      })
    })
  }

  _setState = (state, filters = false) => {

    this.setState(state, ()=>{
      this.searchCars()
      this.calculate_filters()
    })
  }

  _setState2 = (state) =>{
    console.log(state)
    if(state.cars){
      this.paginate(state.cars)
    }
    this.setState(state)
  }

  searchCars = () => {
    let url = `${API_URL}/api/v1/cars/get_all?brand=${this.state.brand}&model=${this.state.model}&color=${this.state.color}&year=${this.state.year}&max_price=${this.state.max_price}&min_price=${this.state.min_price}`

    axios.get(url)
      .then(res => {
        this.paginate(res.data)
        this.setState({
          cars: res.data
        })
      }, error => {
        return Promise.reject(error)
      })
  }

  showFilters = ()  => {
    this.setState({
      show_filters: "col-12"
    })
  }



  render() {
    return (
      <div className="seller-catalog">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>
          <div className="row mx-0 mt-top min-height d-flex">
            <SideNav></SideNav>

            <div className="seller-catalog-container px-0">
              <div className="row mx-0 d-none">
                <MobileFilter 
                setState={this._setState}
                setState2={this._setState2}
                data={this.state}
                searchCars={this.searchCars}    
                calculate_filters={this.calculate_filters} 
                ></MobileFilter>
              </div>
            

              <div className="row mx-0 mt-m d-md-none">
                <div className="col-12 d-flex px-0">
                  <div className="form-group m-0 w-100">
                    <input className="search" placeholder="Buscar por marca, modelo" type="text" onChange={this.handlerGeneralFilter}/>
                  </div>
                  <button className="button -primary d-flex justify-content-center align-items-center">
                    <i class="fas fa-search m-0"></i>
                  </button>
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center mt-xs">
                  <p className="h3-b mb-0">Catálogo (12)</p>
                  <button className="button -text d-flex" onClick={()=>this.showFilters()}>
                    <i class="fas fa-filter"></i>
                    <p className="mb-0">Filtros</p>  
                  </button>
                </div>
              </div>

              <div className="row mx-0 d-none d-md-flex mt-s">
                <div className="col-12 px-0">
                  <SearchBar 
                    class="mt-s"
                    setState={this._setState}
                    setState2={this._setState2}
                    data = {this.state}>
                </SearchBar>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-12 col-md-4 col-lg-3 d-none d-md-flex px-0">
                  <div className="filter-container w-100 mt-s">
                    <FilterBar
                      setState={this._setState}
                      data={this.state}
                      searchCars={this.searchCars}    
                      calculate_filters={this.calculate_filters}         
                    ></FilterBar>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9 px-0">
                  <div className="seller-catalog-container w-100 mt-m">
                    <div className="col-12 pb-1 px-0">
                    {this.state.paginated_cars.length>0 && this.state.paginated_cars[this.state.current_page-1].map((car) => {
                      return(
                        <div className="col-12 mt-xxs">
                          <SellerCarCard
                            image={car.versions ? (car.versions.pictures ? car.versions.pictures[0] :car.versions.colors[0].photo) : ''}
                            brand={car.brand}
                            model={car.model}
                            version={car.versions.version}
                            model_year={car.model_year}
                            car_id={car._id}
                            publish={car.publish}
                            // className="-offer"
                            cost={"$" + car.versions.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            // offer="$180,000"
                            capacity={car.versions.passengers + " pasajeros"}
                            stock={car.versions.stock + " unidades disponibles"}
                            kilometers={car.km.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " kms"}
                          ></SellerCarCard>
                        </div>
                      )
                    })}
                    </div>
                    <Paginator
                      current_page={this.state.current_page}
                      pages={this.state.paginated_cars.length -1}
                      setPage={this.setpage}
                    >
                  </Paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mx-0">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default SellerCatalog;