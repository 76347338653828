import React, { Component } from 'react';
import ProfileImg from '../../../Image/SellerComponents/profile-pic.svg';
import axios from 'axios';
import jwtDecode from 'jwt-decode'
import { Redirect } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL
class ProfileCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name:'',
      reditect:false
    }
  }
  edit = () =>{this.setState({redirect:true})}
  componentDidMount = () => {
    let user_id = jwtDecode(localStorage.getItem("id_token")).sub
    let url = `${API_URL}/api/v1/users/get?user_id=${user_id}`
    

    axios.get(url)
      .then(res => {
        this.setState({
          name: res.data.first_name + ' ' + res.data.last_name,
          email: res.data.email,
          user_id: res.data.user_id,
          agency: localStorage.getItem('agency'),
          role: localStorage.getItem('role')
        })
      }, error => {
        return Promise.reject(error)
      })

  }
  render() {
    return (
      <div className="profile-card">
        {this.state.redirect &&
          <Redirect to={'/seller-edit/'+jwtDecode(localStorage.getItem("id_token")).sub}></Redirect>
        }
        <div className="info-profile-card">
          <div className="row">
            <div className="col-3 d-flex align-items-center">
              <img className="w-100" src={ProfileImg} alt=""/>
            </div>
            <div className="col-8 d-md-none">
              <p className="name">{this.state.name}</p>
            </div>
            <div className="col-12 col-md-8">
              <p className="name d-none d-md-block">{this.state.name}</p>
              {localStorage.getItem('role')!='admin' &&
                <div className="profile-info">
                  <p className="label">Concesionaria:</p>
                  <p className="info">{this.state.agency}</p>
                </div>
              }
              <div className="profile-info">
                <p className="label">Cargo:</p>
                <p className="info">{this.state.role}</p>
              </div>
              <div className="profile-info">
                <p className="label">Email:</p>
                <p className="info">{this.state.email}</p>
              </div>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-end d-md-block">
              <a className="button -text" href={'/seller-edit/'+jwtDecode(localStorage.getItem("id_token")).sub}>
                <i class="fas fa-edit"></i>
              </a>
            </div>
          </div>
        </div>
        
        <div className="edit-profile-card d-none">
          <div className="row">
            <div className="col-3 d-flex align-items-center">
              <img className="w-100" src={ProfileImg} alt=""/>
            </div>
            <div className="col-12 mt-s">
              <div className="form-group">
                <label htmlFor="">Nombre Completo</label>
                <input type="text"/>
              </div>
            </div>
            <div className="col-12">
              <div className="profile-info">
                <div className="form-group mt-xxs w-100">
                  <label htmlFor="">Consecionaria:</label>
                  <input type="text"/>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="profile-info">
                <div className="form-group mt-xxs w-100">
                  <label htmlFor="">Cargo:</label>
                  <input type="text"/>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="profile-info">
                <div className="form-group mt-xxs w-100">
                  <label htmlFor="">ID de empleado:</label>
                  <input type="text"/>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <a href="" className="button -primary mt-xxs">
                Guardar
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileCard;