import React, { Component } from 'react';

import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { FaThemeisle } from 'react-icons/fa';

const API_URL = process.env.REACT_APP_API_URL
const re_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class SellerCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      name:'',
      last_name:'',
      email:'',
      redirect:false,
      role_options:[],
      role:'',
      agency:'',
    }
  }

  componentDidMount = () =>{
    let role = (localStorage.getItem("role"))
    let role_options = []
    if(role == "admin") role_options = ['seleccionar','admin', 'agency', 'seller']
    if(role == "seller") role_options = ['seleccionar','seller']
    let url = `${API_URL}/api/v1/users/get_agencies?group_id=${localStorage.getItem("group_id")}`
    let agencies = []
    axios.get(url)
    .then(res => {
      console.log(res.data.agencies)
      agencies = ['Seleccionar'].concat(res.data.agencies) 
      this.setState({agencies})
    })
    this.setState({role_options})
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  generatePassword() {
    var length = 4;
    var charset = "abcdefghijklmnopqrstuvwxyz";
    var retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    charset = "0123456789";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  createUser = () => {
    let url = `${API_URL}/api/v1/users/create`
    let password = this.generatePassword()
    let user_info = {
      name: this.state.name,
      last_name: this.state.last_name,
      email: this.state.email,
      password,
      role: this.state.role,
      agency: this.state.agency,
      group_id: (localStorage.getItem("group_id"))
    }
    axios.post(url, {
      profile:user_info
    }).then((res) => {
      this.setState({redirect:true})
    })
      
  }

  render() {
    return (
      <div className="add-car">
        {this.state.redirect &&
          <Redirect to={'/seller-admin'}></Redirect>
        }
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="container-fluid mt-top min-height d-flex px-0">
            <SideNav></SideNav>

            <div className="container">

              <div className="row mb-l">
                <div className="col-12 col-lg-8">
                  <div className="row  mt-xl">
                    <div className="col-12">
                      <p className="h1-b">Nuevo vendedor</p>
                    </div>
                  </div>

                  <form action="">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Nombre</label>
                          <input 
                            type="text"
                            name="name"
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Apellido</label>
                          <input 
                            type="text"
                            name="last_name"
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Correo electrónico</label>
                          <input 
                            type="email"
                            name="email"
                            onChange={this.onChange}
                            placeholder="correo@ejemplo.com"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Tipo de usuario</label>
                          <select name="role" id="" onChange={this.onChange}>
                            {this.state.role_options && this.state.role_options.map((role, i) => {
                                return (<option value={role}
                                >{role}</option>)
                              })}
                          </select>
                        </div>
                      </div>
                      {this.state.role && this.state.role != 'admin' &&
                        <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="">Agencia</label>
                          <select name="agency" id="" onChange={this.onChange}>
                            {this.state.agencies && this.state.agencies.map((role, i) => {
                                return (<option value={role}
                                >{role}</option>)
                              })}
                          </select>
                        </div>
                      </div>
                    }
                    </div>
                  </form>
                </div>
              </div>

              <div className="row">
                {(localStorage.getItem("role") == "admin" || localStorage.getItem("role") == "agency") &&
                  <div className="col-12 col-md-6 col-lg-4">
                  <button onClick={this.createUser} className="button -primary w-100 mb-m">Agregar vendedor</button>
                </div>}

                <div className="col-12 col-md-6 col-lg-4">
                  <a href="seller-admin"><button className="button -tertiary w-100 mb-m">Cancelar</button></a>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer></Footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SellerCreate;