import React, { Component } from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import CarDetail from '../src/Pages/CarDetail'
import Recommendations from './Pages/Recommendations';
import Home from './Pages/HomePage';
import Callback from './auth/Callback';
import SearchSelect from './Pages/SearchSelect';
import Catalog from './Pages/Catalog';
import AboutUs from './Pages/AboutUs';
import AddCar from './Pages/Seller/AddCar';
import SendForm from './Pages/SendForm';
import SellerProfile from './Pages/Seller/SellerProfile';
import SellerCatalog from './Pages/Seller/SellerCatalog';
import SellerAdmin from './Pages/Seller/SellerAdmin';
import SellerCreate from './Pages/Seller/SellerCreate';
import EditCar from './Pages/Seller/EditCar';
import SellerEdit from './Pages/Seller/SellerEdit';
import Pay from './Pages/pay';
import SellerEditProfile from './Pages/Seller/SellerEditProfile';
import SellerPayMethods from './Pages/Seller/SellerPayMethods';
import SellerPayRecord from './Pages/Seller/SellerPayRecord';
import Register from './Pages/Seller/Register';
import SellerHome from './Pages/Seller/SellerHome';
import Privacy from './Pages/Privacy';
import QuoteCar from './Pages/QuoteCar';

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      updating: false
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/home" render={(...props) => (
            <Home {...this.props} />
          )} />
          <Route path="/seller-landing" render={(...props) => (
            <SellerHome {...this.props} />
          )} />
          <Route path="/register" render={(...props) => (
            <Register {...this.props} />
          )} />
          <Route path="/about-us" render={(...props) => (
            <AboutUs {...this.props} />
          )} />
          <Route path="/quote-car" render={(...props) => (
            <QuoteCar {...this.props} />
          )} />
          <Route path="/car-detail/:name/:model/:version" name="car-detail" component={(...props) => (
            <CarDetail location={props} {...this.props} />
          )} />
          <Route path="/recommendations" render={(...props) => (
            <Recommendations {...this.props} />
          )} />
          <Route path="/search-select" render={(...props) => (
            <SearchSelect {...this.props} />
          )} />
          <Route path="/catalog/:price" name="catalog" component={(...props) => (
            <Catalog location={props} {...this.props} />
          )} />
          <Route path="/catalog" render={(...props) => (
            <Catalog {...this.props} />
          )} />
          <Route path="/send-form" render={(...props) => (
            <SendForm {...this.props} />
          )} />
          <Route path="/callback" render={(...props) => (
            <Callback {...this.props} />
          )} />
          <Route path="/notice-of-privacy" render={(...props) => (
            <Privacy {...this.props} />
          )} />
          <Route path="/seller" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <SellerProfile {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/pay" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <Pay {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/add-car" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <AddCar {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/edit-car/:name/:model/:version" name="car-edit" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <EditCar location={props} {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/seller-catalog" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <SellerCatalog {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/seller-admin" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <SellerAdmin {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/seller-create" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <SellerCreate {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/seller-edit-profile" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <SellerEditProfile {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/seller-pay-methods" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <SellerPayMethods {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/seller-pay-record" render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <SellerPayRecord {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Route path="/seller-edit/:user_id" name='user-edit' render={(...props) => (
            this.props.auth.isAuthenticated() ? (
              <SellerEdit location={props} {...this.props} />
            ) : (
              <Redirect to="/home" />
            )
          )} />
          <Redirect from="/" to="/home" />
        </Switch>
      </Router>
    );
  }
}

export default App;
