import React, { Component } from 'react';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent';
import Footer from '../Components/Footer/Footer';
import FilterBar from '../Components/FilterBar/FilterBar';
import SearchBar from '../Components/SearchBar/SearchBar';
import CarCard from '../Components/CarCard/CarCard';
import CarImg from '../Image/car-detail/car.png';
import Paginator from '../Components/Paginator/Paginator';

import MobileFilter from '../Components/Mobile/MobileFilter/MobileFilter';
import MobileCarCard from '../Components/Mobile/MobileCarCard/MobileCarCard';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

class Catalog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cars: [],
      brand: '',
      model: '',
      color: '',
      year: '',
      max_price: '',
      min_price: '',
      price_brackets: [],
      brand_options: [],
      model_options: [],
      location_options: [],
      year_options: [],
      // Filter applied
      exclude: '',
      checked_price: -1,
      checked_brand: -1,
      checked_model: -1,
      checked_year: -1,
      checked_location: -1,
      show_filters: "col-12 d-none",
      sort: '',
      paginated_cars: [],
      current_page: 1
    }
  }

  setpage = (page) => {
    this.setState({ current_page: page })
  }

  clean = () => {
    var state = {
      brand: '',
      model: '',
      color: '',
      year: '',
      max_price: '',
      min_price: '',
      checked_price: -1,
      checked_brand: -1,
      checked_model: -1,
      checked_year: -1,
      location: "",
      checked_location: -1,
    }

    this.setState(state, () => {
      this.searchCars()
      this.calculate_filters()
    })
  }

  paginate = (cars) => {
    let page_size = 20
    let paginated_cars = []
    let current_page = 0
    let count = -1
    cars.forEach(car => {
      count++
      if (count == 0 && current_page == 0) {
        paginated_cars.push([car])
      }
      else if (count == page_size) {
        count = 0
        current_page++
        paginated_cars.push([car])
      } else {
        paginated_cars[current_page].push(car)
      }
    })
    this.setState({ paginated_cars })
  }


  componentDidMount = () => {

    let url = "";

    try {
      let price = this.props.location[0].match.params.price
      url = `${API_URL}/api/v1/cars/get_all?max_price=${price}`
    }
    catch {
      url = `${API_URL}/api/v1/cars/get_all`
    }



    axios.get(url)
      .then(res => {
        this.setState({
          cars: res.data
        })
        this.paginate(res.data)
      }, error => {
        return Promise.reject(error)
      })

    url = `${API_URL}/api/v1/cars/get_costs?bracket_size=250000`

    axios.get(url).then(res => {
      this.setState(
        {
          price_brackets: res.data.cost_brackets,
        })
    }, error => {
      return Promise.reject(error)
    })

    this.calculate_filters()
  }

  calculate_filters = () => {

    let url = `${API_URL}/api/v1/cars/calculate_filters?brand=${this.state.brand}&min_price=${this.state.min_price}&=max_price${this.state.max_price}
    &model=${this.state.model}&model_year=${this.state.year}&current_filter=${this.state.exclude}&location_filter=${this.state.location}`

    axios.get(url).then(res => {

      // Check if filters are in response
      if (res.data.brands_filters) {
        this.setState({
          brand_options: res.data.brands_filters
        })
      }
      if (res.data.model_filters) {
        this.setState({
          model_options: res.data.model_filters
        })
      }
      if (res.data.year_filters) {
        this.setState({
          year_options: res.data.year_filters
        })
      }
      if (res.data.color_filters) {
        this.setState({
          color_options: res.data.color_filters
        })
      }
      if (res.data.locations_filters) {
        this.setState({
          location_options: res.data.locations_filters
        })
      }

    }, error => {
      return Promise.reject(error)
    })

  }

  handlerGeneralFilter = (event) => {
    let url = `${API_URL}/api/v1/cars/general_filter?text=${event.target.value}`

    this.setState({
      current_page: 1
    }, () => {
      axios.get(url).then(res => {
        this.paginate(res.data.cars)
        this.setState({
          cars: res.data.cars,
          model_options: res.data.model_filters,
          year_options: res.data.year_filters,
          color_options: res.data.color_filters
        })
      })
    })
  }

  // handlerGeneralFilter = (event) => {
  //   let url = `${API_URL}/api/v1/cars/general_filter?text=${event.target.value}`

  //   axios.get(url).then(res => {
  //     this.paginate(res.data)
  //     this.setState({
  //       cars: res.data.cars,
  //       model_options: res.data.model_filters,
  //       year_options: res.data.year_filters,
  //       color_options: res.data.color_filters
  //     })
  //   })

  // }

  _setState = (state, filters = false) => {

    this.setState(state, () => {
      this.searchCars()
      this.calculate_filters()
    })
  }

  _setState2 = (state) => {
    if (state.cars) {
      this.paginate(state.cars)
    }
    this.setState(state)
  }

  searchCars = () => {
    let url = `${API_URL}/api/v1/cars/get_all?brand=${this.state.brand}&model=${this.state.model}&color=${this.state.color}&year=${this.state.year}&max_price=${this.state.max_price}&min_price=${this.state.min_price}&location_filter=${this.state.location}`

    axios.get(url)
      .then(res => {
        this.paginate(res.data)
        this.setState({
          cars: res.data
        })
      }, error => {
        return Promise.reject(error)
      })
  }

  showFilters = () => {
    this.setState({
      show_filters: "col-12"
    })
  }


  render() {
    return (
      <div className="home-page">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="row mx-0 d-md-none">
            <div className={this.state.show_filters}>
              <MobileFilter
                setState={this._setState}
                setState2={this._setState2}
                data={this.state}
                searchCars={this.searchCars}
                calculate_filters={this.calculate_filters}
              ></MobileFilter>
            </div>
          </div>

          <div className="row mx-0 mt-top min-height d-none d-md-flex">
            <div className="col-12">
              <SearchBar
                className="mt-s"
                clean = {this.clean}
                handlerGeneralFilter={this.handlerGeneralFilter}>
                data = {this.state}
              </SearchBar>
            </div>
            <div className="col-2">
              <div className="filter-container w-100 mt-s">
                <FilterBar
                  setState={this._setState}
                  data={this.state}
                  searchCars={this.searchCars}
                  calculate_filters={this.calculate_filters}
                ></FilterBar>
              </div>
            </div>
            <div className="col-10">
              <div className="catalog-container w-100 mt-s">
                <div className="row mx-0">

                  {this.state.paginated_cars.length > 0 && this.state.paginated_cars[this.state.current_page - 1].map((car) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                        <CarCard
                          image={car.versions ? (car.versions.pictures[0] ? car.versions.pictures[0] : car.versions.colors[0].photo) : CarImg}
                          name={car.brand}
                          model={car.model}
                          version={car.versions.version}
                          // className="-offer"
                          price={car.versions.price ? ("$" + car.versions.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : 'No encontrado'}
                          // offer="$180,000"
                          passengers={car.versions.passengers + " pasajeros"}
                          units={car.versions.stock + " unidades disponibles"}
                          kilometers={car.km ? (car.km.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " kms") : 'N/A'}
                          vin={car.vin}
                        />
                      </div>
                    )
                  })}

                </div>
                <div className="row mx-0 d-flex justify-content-center">
                  <Paginator
                    current_page={this.state.current_page}
                    pages={this.state.paginated_cars.length - 1}
                    setPage={this.setpage}
                  >
                  </Paginator>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-0 mt-top min-height d-md-none">
            <div className="col-12 d-flex">
              <div className="form-group m-0 w-100">
                <input className="search" placeholder="Buscar por marca, modelo" type="text" onChange={this.handlerGeneralFilter} />
              </div>
              <button className="button -primary">
                <i class="fas fa-search"></i>
              </button>
            </div>
            <div className="col-12 d-flex justify-content-between align-items-center mt-xs">
              <p className="h3-b mb-0">Catálogo</p>
              <button className="button -text d-flex" onClick={() => this.showFilters()}>
                <i class="fas fa-filter"></i>
                <p className="mb-0">Filtros</p>
              </button>
            </div>
            <div className="mobile-catalog w-100">
              <div className="row mx-0 mt-s">

                {this.state.cars.map((car, i) => {
                  if (i % 2 == 0) return (
                    <div className="col-6 pr-5p">
                      <MobileCarCard
                        image={car.versions ? (car.versions.pictures[0] ? car.versions.pictures[0] : car.versions.colors[0].photo) : CarImg}
                        name={car.brand}
                        model={car.model}
                        version={car.versions.version}
                        price={"$" + car.versions.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        passengers={car.versions.passengers + " pasajeros"}
                        kilometers={car.km ? (car.km.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " kms") : 'N/A'}
                        units={car.versions.stock}
                        vin={car.vin}
                      />
                    </div>
                  )
                  else return (
                    <div className="col-6 pl-5p">
                      <MobileCarCard
                        image={car.versions ? (car.versions.pictures[0] ? car.versions.pictures[0] : car.versions.colors[0].photo) : CarImg}
                        name={car.brand}
                        model={car.model}
                        version={car.versions.version}
                        price={"$" + car.versions.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        passengers={car.versions.passengers + " pasajeros"}
                        kilometers={car.km ? (car.km.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " kms") : 'N/A'}
                        units={car.versions.stock}
                        vin={car.vin}
                      />
                    </div>
                  )
                }
                )}

              </div>
            </div>
          </div>

          <div className="row mx-0 mt-xl">
            <Footer></Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default Catalog;
