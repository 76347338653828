import React, { Component } from 'react';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent';
import Footer from '../Components/Footer/Footer';

import AboutUs1 from '../Image/AboutUs/about-us.jpg';

class AboutUs extends Component {
  render() {
    return (
      <div className="home-page">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>
          <div className="row mx-0 mt-top min-height">
            <div className="col-12 px-0">
              <div className="hero-wrapper">
                <p className="title text-center -white">Sobre Nosotros</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mx-0 mt-xl">
            <div className="col-12">
              <p className="title text-center">Cupcake ipsum dolor. Sit amet bear claw jelly beans donut. Cake icing gingerbread tart apple pie pastry cotton candy.</p>
            </div>
          </div>

          <div className="row mx-0 my-xl">
            <div className="col-12">
              <p className="h3-r text-center">Cupcake ipsum dolor. Sit amet bear claw jelly beans donut. Cake icing gingerbread tart apple pie pastry cotton candy chocolate bar dragée. Tootsie roll chocolate cake ice cream jujubes apple pie. Cheesecake marzipan cotton candy candy canes danish jelly jelly beans toffee. Gingerbread topping sweet roll tootsie roll ice cream donut fruitcake brownie. Halvah pastry cake jujubes. Dragée topping biscuit tootsie roll pastry toffee sweet. Cookie cookie toffee oat cake brownie muffin tart. Danish cookie ice cream marzipan lemon drops tootsie roll carrot cake. Bonbon gingerbread marshmallow dragée candy croissant sesame snaps powder. Carrot cake sesame snaps jelly bear claw jelly. Cupcake caramels tootsie roll.</p>
              <p className="h3-r text-center">Jelly beans jelly macaroon chocolate cheesecake. Toffee tootsie roll chocolate bar wafer chocolate bar brownie wafer marshmallow. Liquorice powder chocolate cake halvah bonbon soufflé caramels icing. Dessert candy chocolate liquorice gingerbread jujubes gingerbread cookie liquorice. Croissant oat cake muffin halvah powder liquorice apple pie tart. Croissant cake wafer cupcake. Lollipop topping sesame snaps bear claw. Gummies danish soufflé danish sweet roll chocolate bar. Pudding bonbon tootsie roll oat cake tootsie roll powder. Candy canes icing marzipan lemon drops pastry wafer jelly. Donut dragée caramels pudding. Donut lollipop tiramisu soufflé.</p>
            </div>
          </div>

          <div className="row mx-0 my-xl">
            <div className="col-4">
              <img className="w-100" src={AboutUs1} alt=""/>
            </div>
            <div className="col-8 d-flex justify-content-center flex-column">
              <p className="h3-r">Biscuit muffin gummi bears powder jujubes jelly beans apple pie. Wafer chocolate cake jujubes muffin cake lollipop marzipan. Oat cake chocolate cake cotton candy. Toffee marzipan cupcake jelly carrot cake.</p>
              <p className="h3-b">- Lorem Ipsum Dolor.</p>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="row mx-0">
            <Footer></Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;