import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Redirect } from 'react-router-dom';

class ModalAddCar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    }
  }
  
  redirectToDetail = () =>{
    this.setState({redirect: true})
  }

  render() {
    return (
      <div>
        {this.state.redirect &&
          <Redirect to={'/car-detail/'+this.props.name+"/"+this.props.model+"/"+this.props.version}></Redirect>
        }
      <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>
        <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
        <ModalBody>
          <p>{this.props.text}</p>
          <img src={this.props.image} alt=""/>
        </ModalBody>
        <ModalFooter>
          <button className="button -tertiary" disabled={!this.props.valid} onClick={this.props.toggle}>AGREGAR UN NUEVO AUTO</button>
          <button className="button -primary"  disabled={!this.props.valid} onClick={()=>this.redirectToDetail()}>VER AUTO PUBLICADO</button>
        </ModalFooter>
      </Modal>
    </div>
    );
  }
}

export default ModalAddCar;