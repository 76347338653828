import React, { Component } from 'react';
import NavBarComponent from '../../Components/NavBarComponent/NavBarComponent';
import SideNav from '../../Components/SellerComponents/SideNav/SideNav';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';

import { Redirect } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL
const re_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class SellerEditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      changes:false
    }
  }

  validateEmail = () => {
    if (!re_email.test(this.state.email)) {
      this.setState({
        warningEmail: " -warning",
        messageEmail: "Correo no válido",
        validEmail: false,
        invalid: true
      })
    }
    else {
      this.setState({
        warningEmail: "",
        messageEmail: "Correo no válido",
        validEmail: true
      })
    }
  }



  componentDidMount = () =>{
    let role = (localStorage.getItem("role"))
    let url = `${API_URL}/api/v1/users/get_agencies?group_id=${localStorage.getItem("group_id")}`
    let agencies = []
    axios.get(url)
    .then(res => {
      agencies = res.data.agencies
      if(localStorage.getItem("agency")!='undefined'){
        agencies.splice(agencies.indexOf(localStorage.getItem("agency")),1)
        agencies.unshift(localStorage.getItem("agency"))
      } else {
        agencies.unshift('Seleccionar')
      }
      this.setState({agencies})
    })
    let first_name = localStorage.getItem("first_name")
    let last_name = localStorage.getItem("last_name")
    let phone = localStorage.getItem("phone")
    this.setState({role, first_name, last_name, phone})
  }

  onChange = e => {
    let first_name = e.target.name == 'first_name' ? e.target.value: this.state.first_name
    let last_name = e.target.name == 'last_name' ? e.target.value: this.state.last_name
    this.setState(
      { 
        [e.target.name]: e.target.value , 
        changes: (first_name.length > 0 && last_name.length > 0)
    })    
  }

  render() {
    return (
      <div className="seller-profile">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <NavBarComponent {...this.props}></NavBarComponent>
          </div>

          <div className="container-fluid mt-top min-height d-flex px-0">
          {this.state.redirect &&
            <Redirect to={'/seller-admin'}></Redirect>
          }
            <SideNav></SideNav>

            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="save-changes-responsive">
                    <button className="button -primary">Guardar cambios</button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                    <p className="h1-b mt-xl">Editar perfil</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="h2-r mt-l">Datos del representante</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="">Nombre</label>
                      <input 
                        type="text"
                        name="first_name"
                        defaultValue={localStorage.getItem("first_name")}
                        onChange={this.onChange}
                      />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="">Apellidos</label>
                      <input 
                        type="text"
                        name="last_name"
                        defaultValue={localStorage.getItem("last_name")}
                        onChange={this.onChange}
                      />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="">Teléfono</label>
                      <input 
                        type="phone"
                        name="phone"
                        defaultValue={localStorage.getItem("phone")}
                        onChange={this.onChange}
                      />
                  </div>
                </div>

                {/* <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="">Correo electrónico</label>
                      <input 
                        type="email"
                        name="email"
                        onChange={this.onChange}
                      />
                  </div>
                </div>
              */}
              </div>

              {localStorage.getItem("role") == 'seller' ?
              <div>
                <div className="row">
                  <div className="col-12">
                    <p className="h2-r mt-l">Datos de la agencia/distribuidora</p>
                  </div>
                </div>

                <div className="row mb-l">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="">Nombre de la agencia</label>
                      <select name="agency" id="" onChange={this.onChange}>
                              {this.state.agencies && this.state.agencies.map((role, i) => {
                                  return (<option value={role}
                                  >{role}</option>)
                                })}
                            </select>
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="">Número de sucursales</label>
                        <input 
                          type="number"
                          name="brand"
                          onChange={this.onChange}
                        />
                    </div>
                  </div> */}
                </div>
              </div>:null
              }

              <div className="row mb-l d-none d-md-block">
                <div className="col-12 col-md-4 col-lg-3">
                  <button className="button -primary w-100" disabled={!this.state.changes}>Guardar cambios</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-0">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default SellerEditProfile;