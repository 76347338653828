import React, { Component } from 'react';
import Car from '../../Image/car-detail/car.png'

class HeroCarDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <>
        <div className="hero-car-detail -typea">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 col-md-6 h-100 d-none d-lg-block">
                <div className="datawrapper">
                  <p className="h1-b c-white">{this.props.carName}</p>
                  <p className="c-white mb-0">Desde*</p>
                  <p className="h2-b c-white">{'$' + this.props.carPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                  <p className="c-white">Mensualidades desde: {'$' + this.props.carMonthlyPayments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                  <p className="c-white">Enganche desde: {this.props.hook}</p>
                  <a href="#calculator" className="detailoption"><button className="button -secondary -white">Cotizar</button></a>
                </div>
              </div>
              <div className="col-12 col-lg-6 h-100">
                <div className="carwrapper">
                  <input type="hidden" value={this.props.vin} />
                  {this.props.picture && (
                    <img className="car" src={this.props.picture} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="responsivedata d-block d-lg-none">
          <div className="container">
            <p className="h1-b c-white">{this.props.carName}</p>
            <p className="c-white mb-0">Desde*</p>
            <p className="h2-b c-white mb-0">{'$' + this.props.carPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
          </div>
        </div>
      </>
    );
  }
}

export default HeroCarDetail;