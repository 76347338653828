import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalDeleteSeller extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <div>
      <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>
        <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
        <ModalBody>
          <p>Estas seguro de que deseas eliminar a <b>{this.props.seller}</b> una vez que lo elimines, ya no tendrá acceso a la plataforma</p>
        </ModalBody>
        <ModalFooter>
          <button className="button -tertiary" onClick={this.props.toggle}>Cancelar</button>
          <button className="button -primary" onClick={this.props.toggle}>Eliminar vendedor</button>
        </ModalFooter>
      </Modal>
    </div>
    );
  }
}

export default ModalDeleteSeller;