import React, { Component } from 'react';

class HomeBubbles extends Component {
  render() {
    return (
      <div className="HomeBubble mb-xs">
        <img className="step-image" src={this.props.image} alt=""/>
        <p className="text-center my-xs">{this.props.text}</p>
      </div>
    );
  }
}

export default HomeBubbles;