import React, { Component } from 'react';
import axios from 'axios';
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ButtonPrimary } from '../../Button/Button';

const API_URL = process.env.REACT_APP_API_URL

class AddPayMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      card_year:0,
      card_month:0,
      card_error:'',
      date_valid:false,
      card_valid:false,
      name:'',
      cvv:'',
      card_number:'',
      modal:false
    }
  }

  componentDidMount = () =>{
      let d = new Date()
      this.setState({
        'year':d.getFullYear() % 100,
        'month': d.getMonth() +1
      })
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    if((e.target.name == 'card_month' && this.state.year == this.state.card_year && e.target.value <= this.state.month) 
    || (e.target.name == 'card_year' && this.state.year == e.target.value && this.state.card_month <= this.state.month)){
      this.setState({date_valid :false})
    } else if((e.target.name == 'card_month' && (this.state.year<this.state.card_year || (this.state.month<e.target.value)) )|| 
    (e.target.name == 'card_year' && (this.state.year<e.target.value || (this.state.month<this.state.card_month)) )){
      console.log(this.state.year, this.state.card_year)
      this.setState({date_valid :true})
    }
    let cvv = this.state.cvv.length==3
    let card_number = this.state.card_number.length==16
    let name = this.state.name.length>0
    switch(e.target.name){
      case 'cvv':
        cvv = e.target.value.length==3
        break;
      case 'name':
        name = e.target.value.length > 0
        break;
      case 'card_number':
        card_number = e.target.value.length ==16
        break;
    }
    this.setState({card_valid:(cvv && card_number && name) })
  }

  addCard = () => {
    let url = `${API_URL}/api/v1/users/add_card`
    let card = new FormData()
    card.append('group_id', localStorage.getItem("group_id"))
    card.append('card_number', this.state.card_number)
    card.append('name', this.state.name)
    card.append('expiration_year', this.state.card_year)
    card.append('expiration_month', this.state.card_month)
    card.append('cvv', this.state.cvv)

    axios({
      method: 'POST',
      url,
      data: card,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(res => {
      console.log(res.data)
      if(res.data.error){
        this.check_error_code(res.data.error)
      } else {
        this.cancel()
      }
    })
  }

  check_error_code =(error_code) =>{
    switch (error_code) {
      case 3001:
        this.setState({
          modal_text: "Su tarjeta fue declinada."
        })
        break;
      case 3002:
        this.setState({
          modal_text: "Su tarjeta está expirada."
        })
        break;
      case 3003:
        this.setState({
          modal_text: "Su tarjeta no tiene los fondos suficientes."
        })
        break;
      case 3004:
        this.setState({
          modal_text: "Su tarjeta aparece como robada."
        })
        break;
      case 3005:
        this.setState({
          modal_text: "Su tarjeta fue rechazada por el sistema antifraude."
        })
        break;
      case 3006:
        this.setState({
          modal_text: "La operación no es permitida para este usuario o transacción."
        })
        break;
      case 3008:
        this.setState({
          modal_text: "Su tarjeta no permite transacciones en línea."
        })
        break;
      case 3009:
        this.setState({
          modal_text: "Su tarjeta aparece como perdida."
        })
        break;
      case 3010:
        this.setState({
          modal_text: "La tarjeta fue rechazada por su banco."
        })
        break;
      case 3011:
        this.setState({
          modal_text: "El banco pidió retener su tarjeta, por favor comuníquese con su banco."
        })
        break;
      case 3012:
        this.setState({
          modal_text: "Se requiere autorización de su banco para realizar la transacción."
        })
        break;
      default:
        this.setState({
          modal_text: "Hubo un error en su forma de pago."
        })
        break;
    }
    this.toggleModal()
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  cancel = () => window.location.reload() 

  render() {
    return (
      <div className="container">
        <Modal centered={true} isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className}>
          <ModalBody>
            {this.state.modal_text}
          </ModalBody>
          <ModalFooter>
            <ButtonPrimary onClick={() => this.toggleModal()} title="Regresar"></ButtonPrimary>
          </ModalFooter>
        </Modal>
        <div className="row -mx-0">
          <div className="col-12 col-lg-10 px-0">
            <div className="add-pay-method">

              {  this.props.statePayMethod ==  'add' &&
                <button className="button -primary"><i class="fas fa-plus-circle"></i> Nuevo método de pago</button>
              }

              {  this.props.statePayMethod ==  'adding' &&
                <div className="cm-card -primary">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="">Número de la tarjeta</label>
                          <input 
                            type="text"
                            name="card_number"
                            onChange={this.onChange}
                            maxLength={16}
                          />
                      </div>
                    </div>

                    <div className="col-12 col-lg-3">
                      <div className="form-group">
                        <label htmlFor="">CVV</label>
                          <input 
                            type="text"
                            name="cvv"
                            onChange={this.onChange}
                            maxLength={3}
                          />
                      </div>
                    </div>

                    <div className="col-12 col-lg-3">
                      <div className="row">
                        <div className="col-12">
                          <p className="body-r mb-0">Fecha de vencimiento</p>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                  <input 
                                    type="number"
                                    placeholder="1"
                                    name="card_month"
                                    onChange={this.onChange}
                                    min={1}
                                    max={12}
                                  />
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                  <input 
                                    type="number"
                                    placeholder={this.state.year ? this.state.year : '24'}
                                    name="card_year"
                                    onChange={this.onChange}
                                    min={this.state.year}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="">Nombre</label>
                          <input 
                            type="text"
                            name="name"
                            onChange={this.onChange}
                          />
                      </div>
                    </div>

                    {/* <div className="col-12 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="">Asigna un nombre</label>
                          <input 
                            type="text"
                            name="brand"
                            placeholder="Mi tarjeta azul"
                            onChange={this.onChange}
                          />
                      </div>
                    </div> */}
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <button className="button -primary w-100 mt-s" disabled={!(this.state.card_valid && this.state.date_valid)} onClick={this.addCard}>Agregar método de pago</button>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3">
                      <button className="button -tertiary w-100 mt-s" onClick={this.cancel}>Cancelar</button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPayMethod;