import React, { Component } from 'react';

class CarDetailVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <div className={`cm-card -secondary car-detail-version ${this.props.className}`}>
        <p className="h3-r">{this.props.version}</p>
        <p className="h2-b">{this.props.price}</p>
        <img className="carimage mb-xs" src={this.props.image} alt=""/>
        <ul>
          <li className="body-r">Sistema de frenos ABS</li>
          <li className="body-r">Controles de audio al volante</li>
          <li className="body-r">Faros de halógeno</li>
          <li className="body-r">Vidrios eléctricos</li>
        </ul>
      </div>
    );
  }
}

export default CarDetailVersion;